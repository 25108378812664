import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { CardLayout } from 'components/templates'
import { ReactComponent as Delete } from '../../../resources/images/icons/close.svg'
import { Icon, TranslatedText } from 'components/atoms'
import { Document, Page, pdfjs } from 'react-pdf'

interface DefaultAgreementProps {
  className?: string
}

const PAGE_CLASSES = 'h-full flex bg-gray-100 w-full'
const CARD_CLASSES = 'py-16 my-24 lg:mx-32'
const CLOSE_BUTTON_STYLE = 'fixed top-0 right-0 mr-5 lg:mr-36 mt-4 z-50'
const CLOSE_ICON_STYLE = 'bg-gray-900 text-white flex justify-center items-center h-12 w-12 rounded-full cursor-pointer'

export const DefaultAgreement = (props: DefaultAgreementProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(PAGE_CLASSES, classes.component, className)
  const history = useHistory()

  const [numPages, setNumPages] = useState(null)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
  }

  const fixOffsetLayer = () => {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent')
    textLayers.forEach(layer => {
      const { style }: any = layer
      style.display = 'flex'
    })
  }

  return (
    <div className={merged}>
      <div className={cx(CLOSE_BUTTON_STYLE, 'no-print')}>
        <div className={CLOSE_ICON_STYLE} onClick={() => history.goBack()}>
          <Icon svgIcon={<Delete className={classes.icon} />} width={16} height={16} />
        </div>
        <TranslatedText collection="globalEvents" id="close" />
      </div>
      <CardLayout className={CARD_CLASSES}>
        <Document file={require('./agreement.pdf')} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={index}
              onLoadSuccess={fixOffsetLayer}
              className="flex justify-center"
              pageNumber={index + 1}
              scale={2}
            />
          ))}
        </Document>
      </CardLayout>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    @media print {
      @page {
        size: auto;
        padding: 0;
        margin: 0;
      }
      .no-print {
        display: none;
      }
    }
  `,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
