import * as utils from './utils'

const primary = {
  400: '#FF7D23',
  500: '#FF6800',
  600: '#ED6100',
}

const success = {
  400: '#68C049',
  500: '#60AA45',
  600: '#5C9A47',
}

const error = {
  400: '#FC5B5B',
  500: '#F23F3F',
  600: '#D93737',
}

const grey = {
  100: '#F1F1F1',
  200: '#E6E6E6',
  300: '#CCCCCC',
  400: '#B3B3B3',
  500: '#999999',
  600: '#808080',
  700: '#666666',
  800: '#4D4D4D',
  900: '#333333',
  1000: '#1A1A1A',
}

const black = {
  1: '#000000',
}

const white = {
  0: '#FFFFFF',
}

const secondary = {
  500: '#61dafb',
}

const colors = {
  primary,
  secondary,
  success,
  error,
  grey,
  black,
  white,
}

const spacings = {}

const fonts = {
  primary: 'SourceSansPro',
}

export const theme = {
  colors,
  spacings,
  fonts,

  getColor: utils.color,
  getFont: utils.font,
  getSpacing: utils.spacing,
  getProp: utils.prop,
}
