import React from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { SummaryLine } from 'components/atoms/SummaryLine/SummaryLine'
import { Divider } from 'components/atoms/Divider'

interface OrderSummaryProps {
  className?: string
  price: number
  deliveryPrice?: number
  totalPrice?: number
  priceWithDelivery?: number
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const { price, deliveryPrice, totalPrice, priceWithDelivery, className } = props
  const classes = styles()
  const merged = cx('flex flex-row w-full p-4 bg-white justify-between', classes.container, className)
  const { t } = useTranslation(['orderDetailsPage'])

  const total = totalPrice || price
  const grandTotal = priceWithDelivery ? priceWithDelivery : totalPrice || price + (deliveryPrice || 0)
  return (
    <div className={merged}>
      <div className="text-xl font-bold m-4">{t('summaryTitle')}</div>
      <div className="flex flex-col">
        {deliveryPrice && <SummaryLine title={t('orderItemsPrice')} value={total} />}
        {deliveryPrice && <SummaryLine title={t('deliveryPrice')} value={deliveryPrice} />}
        <Divider />
        <SummaryLine title={t('totalPrice')} type={'bold'} value={grandTotal} />
      </div>
    </div>
  )
}

const styles = () => ({
  container: css``,
})
