import React from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'

import { LabelBadge } from 'components/atoms/LabelBadge'

interface GeneralInfoProps {
  className?: string
  paymentType?: string
  deliveryDate?: Date
  orderDate?: Date
  status?: string
}

const TRANSLATION_BLOCK = 'orderDetailsPage'

export const GeneralInfo = (props: GeneralInfoProps) => {
  const { className, paymentType = '', deliveryDate, orderDate, status } = props
  const classes = styles()
  const merged = cx('flex flex-col', classes.container, className)
  const { t } = useTranslation(TRANSLATION_BLOCK)
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const dateTimeOptions = { ...dateOptions, hour: 'numeric', minute: 'numeric' }
  return (
    <div className={merged}>
      <div className="font-bold text-lg">{t('generalInfo')}</div>
      <div className="mt-2 mb-5">{t(`payment:${paymentType}`)}</div>
      {orderDate && (
        <>
          <div>{t('orderDateTime')}</div>
          <div>{orderDate.toLocaleDateString('lt-LT', dateTimeOptions)}</div>
        </>
      )}
      {deliveryDate && (
        <>
          <div className="mt-2">{t('deliveryDate')}</div>
          <div>{deliveryDate.toLocaleDateString('lt-LT', dateOptions)}</div>
        </>
      )}
      {status && (
        <>
          <div className="flex flex-row justify-between mt-5">
            <div className="mr-2">{t('status')}</div>
            <LabelBadge className="h-5 w-20" size="sm" text={t(`status:${status}`)} />
          </div>
        </>
      )}
    </div>
  )
}

const styles = () => ({
  container: css``,
})
