import React from 'react'
import { cx, css } from 'emotion'
import { Icon } from '../Icon'

import { ReactComponent as Check } from '../../../resources/images/icons/check.svg'
import { ReactComponent as Warn } from '../../../resources/images/icons/warning.svg'
import { ReactComponent as Info } from '../../../resources/images/icons/info.svg'
import { ReactComponent as Close } from '../../../resources/images/icons/close.svg'
import { useTheme } from 'emotion-theming'
import { IThemeProps } from '../../../theme/types'

export type NotificationType = 'success' | 'error' | 'info'

interface NotificationProps {
  className?: string
  text: string | JSX.Element
  type: NotificationType
  showCloseBtn?: boolean
  closeEvent?: Function
}

export const Notification = (props: NotificationProps) => {
  const { className, text = '', type, showCloseBtn, closeEvent = () => {} } = props
  const theme = useTheme<IThemeProps>()
  const classes = styles(type, theme)
  const mergedStyle = cx(
    classes.notification,
    css`
      height: auto;
      min-width: 390px;
    `,
    className
  )

  let iconByType = null
  switch (type) {
    case 'success': {
      iconByType = <Check />
      break
    }
    case 'error': {
      iconByType = <Warn />
      break
    }
    case 'info': {
      iconByType = <Info />
      break
    }
  }

  return (
    <div className={mergedStyle}>
      {iconByType && <Icon className={classes.typeIcon} svgIcon={iconByType} height={16} width={16} />}
      <span className={classes.text}>{text}</span>
      {showCloseBtn && (
        <Icon className={classes.closeBtn} svgIcon={<Close />} height={16} width={16} onClick={closeEvent} />
      )}
    </div>
  )
}

const getBgColors = (theme: IThemeProps) =>
  ({
    success: `${theme.colors.success[500]}`,
    error: `${theme.colors.error[500]}`,
    info: `${theme.colors.grey[200]}`,
  } as any)

const getTextColors = (theme: IThemeProps) =>
  ({
    success: `${theme.colors.white[0]}`,
    error: `${theme.colors.white[0]}`,
    info: `${theme.colors.black[1]}`,
  } as any)

const styles = (type: string, theme: IThemeProps) => ({
  notification: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${getBgColors(theme)[type]};

    padding: 12px 17px;
  `,
  typeIcon: css`
    svg {
      path {
        fill: ${getTextColors(theme)[type]};
      }
    }
  `,
  text: css`
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    flex: 1;
    padding: 0 17px;
    color: ${getTextColors(theme)[type]};
  `,
  closeBtn: css`
    flex: 0;
    svg {
      path {
        fill: ${type === 'success' || type === 'error' ? 'rgba(255, 255, 255, 0.36)' : 'rgba(0, 0, 0, 0.36)'};

        &:hover {
          cursor: pointer;
          fill: ${type === 'success' || type === 'error' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)'};
        }
      }
    }
  `,
})
