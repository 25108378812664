import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'store/user'
import { BrandFilterContainer } from '../BrandFilterContainer'
import { MonthPickerFilterContainer } from '../MonthPickerFilterContainer'

interface BalanceFilterContainerProps {
  className?: string
  id: string
}

export const BalanceFilterContainer = (props: BalanceFilterContainerProps) => {
  const { className, id } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const user = useSelector(getCurrentUser)

  return (
    <div className={merged}>
      {user.role === 'admin' && <BrandFilterContainer id={id} className={classes.filter} />}
      <MonthPickerFilterContainer id={id} className={classes.filter} />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: flex;
  `,
  filter: css`
    &:not(:last-child) {
      margin-right: 24px;
    }
  `,
})
