import React, { useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { ActionsLayout } from '../ActionsLayout'
import UserPlaceholder from 'resources/images/user-placeholder.svg'
import userOk from 'resources/images/icons/user_ok.svg'
import userBlocked from 'resources/images/icons/user_blocked.svg'
import { BlockUser, UnBlockUser } from 'components/templates/BlockUser'
import { TranslatedText } from 'components/atoms/TranslatedText'
import { useDispatch } from 'react-redux'
import { setModal } from 'store/modal'
import { RemoveSeller } from 'components/organisms/RemoveSeller'

interface PortfolioPanelProps {
  className?: string
  children?: React.ReactNode
  name?: string
  address: string
  photoUrl?: string
  userId: string
  isCurrentUserAdmin: boolean
  isDisabled?: boolean
}

const PROFILE_PHOTO_CLASSES = 'h-20 w-20 box-content rounded-full overflow-hidden object-cover'
const IMAGE_CLASSES = 'relative h-20 w-20 box-content rounded-full mr-6'
const PROFILE_CLASSES = 'flex flex-row items-center px-8 pt-6 pb-6 bg-white '
const BRAND_TEXT = 'inline-block pr-6 not-italic font-bold text-4xl leading-10'
const ADDRESS_TEXT = 'not-italic font-medium text-sm leading-4 text-gray-400'

export const PortfolioPanel = (props: PortfolioPanelProps) => {
  const {
    className,
    children,
    name,
    address,
    userId,
    isCurrentUserAdmin,
    isDisabled = false,
    photoUrl = UserPlaceholder,
  } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const dispatch = useDispatch()
  //const { t } = useTranslation(['portfolioPanel'])

  useEffect(() => {
    if (!isCurrentUserAdmin && isDisabled) {
      dispatch(setModal('InfoDialog', { collection: 'usersPage' }))
    }
  }, [dispatch, isCurrentUserAdmin, isDisabled])

  return (
    <div className={merged}>
      <div className={cx('profile', PROFILE_CLASSES)}>
        <div className={cx('image', IMAGE_CLASSES)}>
          <picture>
            <img className={PROFILE_PHOTO_CLASSES} src={photoUrl} alt="User" />
            <img
              className="w-5 h-5 top-15 right-1 absolute"
              src={isDisabled ? userBlocked : userOk}
              alt={'UserStatus'}
            />
          </picture>
        </div>

        <div className={'flex flex-col'}>
          <div className={'relative flex items-center'}>
            <div className={BRAND_TEXT}>{name}</div>
            {isCurrentUserAdmin && (
              <div className="action">
                <ActionsLayout right>
                  {!isDisabled && (
                    <BlockUser className="cursor-pointer p-4 hover:bg-gray-200" id={userId}>
                      <TranslatedText collection="profilePage" id="block" />
                    </BlockUser>
                  )}
                  {isDisabled && (
                    <UnBlockUser className="cursor-pointer p-4 hover:bg-gray-200" id={userId}>
                      <TranslatedText collection="profilePage" id="unblock" />
                    </UnBlockUser>
                  )}
                  <RemoveSeller className="cursor-pointer p-4 hover:bg-gray-200" id={userId}>
                    <TranslatedText collection="profilePage" id="remove" />
                  </RemoveSeller>
                </ActionsLayout>
              </div>
            )}
          </div>
          <div className={ADDRESS_TEXT}>{address}</div>
        </div>
        {/* Comment Vacation button
        <SetVacation id={userId} redirectAfterAction={false} refreshPage={true}>
          <button
            type="button"
            className="group relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md transition duration-150 ease-in-out focus:outline-none bg-gray-900 text-white">
            <span className="css-0">{t('vacationMode')}</span>
          </button>
        </SetVacation>*/}
      </div>

      <div className={'tabs'}>{children}</div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: flex;
    flex-direction: column;
  `,
})
