import React, { useMemo } from 'react'
import { LabelBadge } from 'components/atoms/LabelBadge'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { ActionsLayout } from 'components/molecules'
import { TranslatedText } from 'components/atoms'

const TRANSLATION_BLOCK = 'orderDetailsPage'
const PRODUCT_IMAGE_CLASSES = 'w-10 h-10 flex justify-center items-center mr-5'

interface useOrderProductsColumnProps {
  push: any
}

export function useOrderProductsColumn({ push }: useOrderProductsColumnProps) {
  const { t } = useTranslation([TRANSLATION_BLOCK])
  return useMemo(
    () =>
      [
        {
          Header: 'docId',
          accessor: 'docId',
          width: 0,
        },
        {
          Header: 'id',
          accessor: 'id',
          width: 0,
        },
        {
          accessor: 'imageUrl',
          width: 0,
        },
        {
          Header: t('product'),
          accessor: 'title',
          width: 300,
          Cell: ({ row }) => {
            const value = row.values['title']
            const url = row.values['imageUrl']
            const image = url ? <img alt={value} className={PRODUCT_IMAGE_CLASSES} src={url} /> : ''
            return (
              <div className="flex flex-row">
                {image} {value}
              </div>
            )
          },
        },
        {
          Header: t('size'),
          accessor: 'size',
          width: 100,
          Cell: ({ row }) => {
            const value = row.values['size'] as string
            const dispValue = value || t('oneSize')
            return <div>{dispValue}</div>
          },
        },
        {
          Header: t('price'),
          accessor: 'price',
          width: 100,
          Cell: ({ row }) => {
            const value = row.values['price'] as number
            const dispValue = value !== undefined ? `${value.toFixed(2)} €` : ''
            return <div>{dispValue}</div>
          },
        },
        {
          Header: t('amount'),
          accessor: 'amount',
          width: 100,
        },
        {
          Header: t('priceTotal'),
          accessor: 'priceTotal',
          width: 100,
          Cell: ({ row }) => {
            const value = row.values['priceTotal'] as number
            const dispValue = value !== undefined ? `${value.toFixed(2)} €` : ''
            return <div>{dispValue}</div>
          },
        },
        {
          Header: t('status'),
          accessor: 'status',
          width: 160,
          Cell: ({ row }) => {
            const statusCode = row.values['status']
            return <LabelBadge size="sm" text={t(`status:${statusCode}`)} />
          },
        },
        {
          Header: '',
          accessor: 'actions',
          disableSortBy: true,
          width: 40,
          sticky: 'right',
          Cell: ({ row }) => {
            const id = row.values['docId']
            const productUrl = `/product/${id}`
            return (
              <ActionsLayout>
                <div className="cursor-pointer  p-4 hover:bg-gray-200" onClick={() => push(productUrl)}>
                  <TranslatedText collection="orderDetailsPage" id="toProduct" />
                </div>
              </ActionsLayout>
            )
          },
        },
      ] as Array<Column>,
    [t, push]
  )
}
