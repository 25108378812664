import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import Dropzone, { FileRejection } from 'react-dropzone'
import { TranslatedText } from 'components/atoms'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notification'
import { FileErrResponse, IProductImage } from 'components/util/interfaces'
import { DraggableThumbnailContainer } from '../DraggableThumbnailContainer'

interface ImageUploadProps {
  className?: string
  accept: string
  maxSize: number
  maxImageCount: number
  images: IProductImage[]
  onDrop: (accepted: []) => void
  onDelete: (image: IProductImage) => void
  onImageReorder: (image: IProductImage[]) => void
}
const IMAGE_CONTAINER_STYLE =
  'flex justify-center items-center flex-col border border-dashed border-gray-900 w-full h-full pointer focus:outline-none cursor-pointer'
const TITLE_CLASSES = 'block font-bold not-italic font-medium text-xl text-center leading-6 pb-3'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3'
const DESC_SMALL_CLASSES = 'text-xs pt-4'
const THUMBNAILS_CONTAINER_STYLE = 'inline-flex wrap flex-start'
const EMPTY_THUMBNAIL_STYLE = 'w-20 h-28 border border-dashed border-gray-900'

export const ImageUpload = (props: ImageUploadProps) => {
  const { className, accept, maxSize, images, maxImageCount, onDrop, onDelete, onImageReorder } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(className, classes.component)
  const [selectedImage, setSelectedImage] = useState<string>()
  const dispatch = useDispatch()

  function dropRejected(reject: FileRejection[]) {
    reject.forEach(r => {
      r.errors.forEach((err: FileErrResponse) => {
        const msg = (
          <div>
            <div>{r.file.name}</div>
            <div>
              <TranslatedText collection="imageUpload" id={err.code} />
            </div>
          </div>
        )
        dispatch(addNotification('error', msg))
      })
    })
  }

  const topImage = selectedImage || images.find((i, index) => index === 0)?.url

  return (
    <Dropzone accept={accept} maxSize={maxSize} onDrop={onDrop} onDropRejected={dropRejected}>
      {({ getRootProps, getInputProps }) => (
        <div className={merged}>
          <input {...getInputProps()} />
          {images.length < 1 && (
            <div
              {...getRootProps({ className: 'dropzone' })}
              className={cx(IMAGE_CONTAINER_STYLE, classes.imageContainer)}>
              <h2 className={TITLE_CLASSES}>
                <TranslatedText collection="imageUpload" id="title" />
              </h2>
              <h4 className={DESC_CLASSES}>
                <TranslatedText collection="imageUpload" id="desc" />
              </h4>
              <h4 className={DESC_SMALL_CLASSES}>
                <TranslatedText
                  collection="imageUpload"
                  id="restrictions"
                  properties={{
                    maxSize: `${maxSize / 1024 / 1024} MB`,
                    maxItemCount: maxImageCount,
                  }}
                />
              </h4>
            </div>
          )}
          {images.length > 0 && (
            <div className={cx(IMAGE_CONTAINER_STYLE, classes.imageContainer, classes.image)}>
              <img alt="Product" src={topImage} className="w-full h-full object-cover" />
            </div>
          )}
          <div>
            <div className={THUMBNAILS_CONTAINER_STYLE}>
              <DraggableThumbnailContainer
                images={images}
                setSelectedImage={setSelectedImage}
                onDelete={onDelete}
                onImageReorder={onImageReorder}
              />
              {images.length < maxImageCount && (
                <div className={cx(classes.thumbnailWrapper, 'pt-4')}>
                  <div
                    {...getRootProps({ className: 'dropzone' })}
                    className={cx(classes.thumbnail, classes.empty, EMPTY_THUMBNAIL_STYLE)}></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  )
}

const styles = (theme: any) => ({
  component: css``,
  imageContainer: css`
    width: 400px;
    height: 560px;
  `,
  thumbnailWrapper: css`
    position: relative;
    &:hover {
      .hidden {
        display: flex;
      }
    }
  `,
  thumbnail: css`
    overflow: hidden;
    margin: 0 6px;
  `,
  empty: css`
    &:after {
      content: '+';
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 2em;
    }
  `,
  image: css`
    overflow: hidden;
    position: relative;
    border: none;
  `,
})
