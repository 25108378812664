import { CHANGE_BALANCE_STATUS } from './constants'
import { combineEpics, ofType } from 'redux-observable'
import { from, Observable, of } from 'rxjs'

import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { app } from 'hoc/FirebaseProvider'
import { deleteModal } from 'store/modal'
import { go } from 'connected-react-router'
import { addNotification } from '../notification'

const changeBalanceStatusEpic = (action$: Observable<AnyAction>, $state: any, { translations }: any): Observable<any> =>
  action$.pipe(
    ofType(CHANGE_BALANCE_STATUS),
    switchMap(({ id, status, ...rest }: any) => {
      const db = app.firestore()
      // Get a new write batch
      const batch = db.batch()
      const productsCollectionRef = db.collection('balances').doc(id)
      batch.set(productsCollectionRef, { status: status }, { merge: true })
      return of({ response: from(batch.commit()), props: rest })
    }),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          const actions = []
          actions.push(deleteModal(props.modalId))
          if (props.refreshPage) {
            actions.push(go(0))
          }
          actions.push(addNotification('success', translations['balancePage']['statusChanged']))
          return actions
        })
      )
    ),
    catchError(error => {
      return of({ type: 'ERROR', error: error.code })
    })
  )

export const balanceEpics = combineEpics(changeBalanceStatusEpic)
