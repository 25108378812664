import * as actions from './actions'
import * as constants from './constants'
import { ModalModel, IndexModel } from 'models'
import { v4 as uuidv4 } from 'uuid'

export interface ModalState {
  list: string[]
  byId: IndexModel<ModalModel>
}

type State = ModalState

const initialState: State = {
  list: [],
  byId: {},
}

const setModal = (state: State, action: actions.SetModalAction) => {
  const id = uuidv4()
  const modalData: ModalModel = {
    id,
    modalType: action.modalType,
    props: action.modalProps,
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      [id]: modalData,
    },
    list: [...state.list, id],
  }
}

const deleteModal = (state: State, action: actions.DeleteModalAction) => {
  const index = state.list.findIndex(item => item === action.id)
  const { [action.id]: _, ...rest } = state.byId

  return {
    ...state,
    byId: rest,
    list: [...state.list.slice(0, index), ...state.list.slice(index + 1)],
  }
}

const strategies = {
  [constants.SET_MODAL]: setModal,
  [constants.REMOVE_MODAL_BY_ID]: deleteModal,
  __default__: (state: State) => state,
}

export const modalReducer = (state = initialState, action: actions.ModalActions): State => {
  const transformer = strategies[action.type] ?? strategies.__default__
  return transformer(state, action as any)
}
