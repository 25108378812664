import React, { Suspense } from 'react'
import ReactNotification from 'react-notifications-component'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'emotion-theming'

import App from 'App'
import { PageLoading } from './components/organisms'
import { FirebaseProvider, app } from './hoc/FirebaseProvider'
import { theme } from 'theme'
import * as serviceWorker from './serviceWorker'
import configureStore from './store/configureStore'
import { I18nextProvider } from 'react-i18next'
import i18n from './config/i18n'

import './index.scss'
import { ModalHolder } from 'components/templates/ModalHolder'
import { history } from 'store/history'

const store = configureStore({})

const Root: React.FC<{ theme: any; history: any; store: any }> = ({ theme, history }) => (
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <FirebaseProvider app={app}>
            <Suspense fallback={<PageLoading />}>
              <App />
              <ModalHolder />
              <ReactNotification isMobile={true} breakpoint={768} className="fixed top-0" />
            </Suspense>
          </FirebaseProvider>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </I18nextProvider>
)

render(<Root theme={theme} history={history} store={store} />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept('./App', () => {
    render(<Root theme={theme} history={history} store={store} />, document.getElementById('root'))
  })

  module.hot.accept('./theme', () => {
    const theme = require('./theme').theme
    render(<Root theme={theme} history={history} store={store} />, document.getElementById('root'))
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
