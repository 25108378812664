import { css, cx } from 'emotion'
import React from 'react'
import { theme } from 'theme'
import { Link } from 'react-router-dom'

interface LogoProps {
  className?: string
}

export const Logo = (props: LogoProps) => {
  const { className } = props
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  return (
    <Link to={'/login'}>
      <div className={merged}>
        <img className={'pb-2 sm:h-24 h-16 max-w-md'} src={'./logo.svg'} alt={'popup.lt'} />
      </div>
    </Link>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
    width: fit-content;
  `,
})
