import { Row } from 'react-table'
import moment from 'moment'

export const sortByDate = (rowA: Row, rowB: Row, columnId: string) => {
  const valueA = rowA.values[columnId]
  const valueB = rowB.values[columnId]
  const momentA = valueA && moment(valueA)
  const momentB = valueB && moment(valueB)
  if (!momentA) {
    return -1
  }
  if (!momentB) {
    return 1
  }
  return momentB.isBefore(momentA) ? 1 : -1
}
