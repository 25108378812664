import React, { useCallback, useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { FieldArray } from 'formik'
import { ReactComponent as Info } from '../../../resources/images/icons/info.svg'
import { TranslatedText, Icon, Input, Button, SelectInput, Label } from 'components/atoms'
import { useTranslation } from 'react-i18next'

interface ProductAttributesFormProps {
  className?: string
  formik: any
  options: any
}

const FLEX = 'flex flex-row justify-between mt-4'
const HEADER_STYLE =
  'px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex justify-between items-center'
const ERROR_MSG_STYLE = 'text-xs text-red-500'

export const ProductAttributesForm = (props: ProductAttributesFormProps) => {
  const { className, formik, options } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const { t } = useTranslation('newProductForm')

  const onClick = useCallback((push: Function) => {
    push({ size: { label: '', value: '' }, totalAmount: 0, lowStock: 1 })
  }, [])

  const onRemoveBtnClick = useCallback(
    (push: Function, remove: Function) => {
      remove(formik.values.items.length - 1)
      if (formik.values.items.length === 1) {
        push({ size: { label: '', value: '' }, totalAmount: 0, lowStock: 1 })
      }
    },
    [formik.values.items.length]
  )

  let isCategoryTextile = () => {
    let result = false
    formik.values.categoryIds.forEach((item: any, index: number) => {
      console.log(item['_value'])
      if (JSON.stringify(item['_value']).includes('patalyne')) {
        result = true
      }
    })
    return result
  }

  const validateArrayItems = (index: number, key: string) => {
    return !!(formik.errors.items && formik.errors.items[index]?.[key])
  }

  useEffect(() => {
    if (options.length > 0) {
      formik.values.items.forEach((item: any, index: number) => {
        const exists = options.find((o: any) => o.value === item.size.value) || item.size.value === ''
        if (!exists) {
          formik.values.items.splice(index)
          if (index === 0) {
            formik.values.items.push({ size: { label: '', value: '' }, totalAmount: 0, lowStock: 1 })
          }
        }
      })
    } else if (options.lenght === 0 && formik.values.items.lenght > 0) {
      formik.values.items.forEach((item: any, index: number) => {
        formik.setFieldValue(`items[${index}].size`, { label: '', value: '' })
      })
    }
  }, [formik, options])

  return (
    <div className={merged}>
      <FieldArray
        name="items"
        render={({ push, remove }) => (
          <div>
            <div className={cx(HEADER_STYLE, classes.smallInputWrapper)}>
              {options.length > 0 && <TranslatedText collection="newProductForm" id="size" />}
              <TranslatedText collection="newProductForm" id="totalAmount" />
              <div className="flex align-center">
                <TranslatedText collection="newProductForm" id="lowStock" />
                <span className="w-6" data-tip={t('lowStockInfo')}>
                  <Icon
                    className={cx('ml-1', classes.icon)}
                    svgIcon={<Info className={classes.icon} />}
                    height={16}
                    width={16}
                  />
                </span>
              </div>
              {options.length > 0 && isCategoryTextile() && (
                <TranslatedText collection="newProductForm" id="variantPrice" />
              )}
            </div>
            <div>
              {formik.values.items &&
                formik.values.items.length > 0 &&
                formik.values.items.map((item: any, index: number) => (
                  <div className={cx(FLEX, classes.smallInputWrapper)} key={index}>
                    {options.length > 0 && (
                      <div>
                        <SelectInput
                          name="size"
                          options={options}
                          value={formik.values.items[index]?.size}
                          handleChange={event => formik.setFieldValue(`items[${index}].size`, event)}
                          handleBlur={formik.handleBlur}
                        />
                        {validateArrayItems(index, 'size') && (
                          <Label
                            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
                            msg={
                              <TranslatedText collection="newProductForm" id={formik.errors.items[index].size.label} />
                            }
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <Input
                        name={`items[${index}].totalAmount`}
                        type="number"
                        min={0}
                        value={formik.values.items[index]?.totalAmount}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      />
                      {validateArrayItems(index, 'totalAmount') && (
                        <Label
                          className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
                          msg={
                            <TranslatedText collection="newProductForm" id={formik.errors.items[index].totalAmount} />
                          }
                        />
                      )}
                    </div>
                    <div>
                      <Input
                        name={`items[${index}].lowStock`}
                        type="number"
                        min={1}
                        value={formik.values.items[index]?.lowStock}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      />
                      {validateArrayItems(index, 'lowStock') && (
                        <Label
                          className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
                          msg={<TranslatedText collection="newProductForm" id={formik.errors.items[index].lowStock} />}
                        />
                      )}
                    </div>
                    {options.length > 0 && isCategoryTextile() && (
                      <div>
                        <Input
                          name={`items[${index}].variantPrice`}
                          type="number"
                          value={formik.values.items[index]?.variantPrice}
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                        />
                        {validateArrayItems(index, 'variantPrice') && (
                          <Label
                            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
                            msg={
                              <TranslatedText
                                collection="newProductForm"
                                id={formik.errors.items[index].variantPrice}
                              />
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="flex flex-row my-6">
              <Button type="button" onClick={() => onClick(push)}>
                <TranslatedText collection="newProductForm" id="add" />
              </Button>
              {formik.values.items.length > 1 && (
                <Button color="secondary" className="ml-4" onClick={() => onRemoveBtnClick(push, remove)}>
                  <TranslatedText collection="newProductForm" id="removeAttr" />
                </Button>
              )}
            </div>
          </div>
        )}
      />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  smallInputWrapper: css`
    > div,
    > span {
      flex-basis: 20%;
    }
  `,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
  errorMsg: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
})
