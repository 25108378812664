import React, { useState, useCallback, useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import * as _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { InputLabel } from 'components/atoms/InputLabel'
import { TranslatedText } from 'components/atoms/TranslatedText'
import { SelectInput } from 'components/atoms/SelectInput'
import { setStatusFilterField, getStatusField } from 'store/filtering'
import { useFirestore } from 'hoc/FirebaseProvider'
import { getCurrentUser } from 'store/user'
import { IProductStatusDropdown } from 'components/util/interfaces'
import { AppState } from 'store/reducers'

interface StatusFilterInputContainerProps {
  className?: string
  id: string
}
const CONTAINER_CLASSES = 'w-48'
const LABEL_CLASSES = 'pb-1'

export const StatusFilterInputContainer = (props: StatusFilterInputContainerProps) => {
  const { className, id } = props
  const db = useFirestore()
  const [status, setStatus] = useState<IProductStatusDropdown[]>([])
  const dispatcher = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CONTAINER_CLASSES, classes.component, className)
  const authUser = useSelector(getCurrentUser)

  function filterbySellerRole(list: IProductStatusDropdown[], role: string) {
    if (role === 'seller') {
      return list.filter((item: IProductStatusDropdown) => {
        return item.value !== 'deleted'
      })
    }

    return list
  }

  // FETCH product statuses
  useEffect(() => {
    const statusConverter = {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ) {
        const data = snapshot.data(options)
        return { label: data.label, value: data.value } as IProductStatusDropdown
      },
    }

    async function fetchData(converter: any) {
      try {
        const docRef = db.collection('productStatus')
        const querySnapshot = await docRef.withConverter(converter).get()
        const statuses: any[] = []

        querySnapshot.forEach((doc: any) => {
          const status: IProductStatusDropdown = doc.data()
          statuses.push(status)
        })

        setStatus(filterbySellerRole(statuses, authUser.role))
      } catch (error) {
        console.error('Statuses are not fetched', error)
      }
    }

    fetchData(statusConverter)
  }, [authUser.role, db])

  const updateSelect = useCallback(
    _.debounce((data: any) => {
      dispatcher(setStatusFilterField(id, data || []))
    }, 100),
    []
  )

  const handleChange = (selected: [{ label: string; value: string }]) => {
    if (selected) {
      const onlyValues = selected.map(item => item.value)
      updateSelect(onlyValues)
    } else {
      // On last chip removing, value is retured as null
      updateSelect([])
    }
  }

  const statusIds: string[] = useSelector((state: AppState) => getStatusField(state, id))
  const statusSelected = statusIds && status.filter(s => statusIds.find(id => id === s.value))

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES}>
        <TranslatedText collection="productsPage" id="statusLabel" />
      </InputLabel>
      <SelectInput isMulti={true} options={status} handleChange={handleChange} value={statusSelected} />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
