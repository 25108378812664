import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { SideNavigationLayout } from 'components/templates/SideNavigationLayout'
import { PortfolioPanelContainer } from 'components/templates/PortfolioPanelContainer'
import { UsersProfileContainer } from 'components/organisms/UsersProfileCointainer'
import { useUserId } from 'components/util/useUserId'

interface UsersProfilePageProps {
  className?: string
  children?: React.ReactNode
}

export const UsersProfilePage = (props: UsersProfilePageProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const userId = useUserId()

  return (
    <SideNavigationLayout className={merged}>
      <PortfolioPanelContainer userId={userId}>
        <div className="p-6">
          <UsersProfileContainer className="max-w-xl abolute left-0 right-0 mx-auto my-0" />
        </div>
      </PortfolioPanelContainer>
    </SideNavigationLayout>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
