import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { TranslatedText, Label } from 'components/atoms'
import { IUser } from 'components/util'

interface UserDetailsCardProps {
  className?: string
  translation: string
  user?: IUser
}

export const UserDetailsCard = (props: UserDetailsCardProps) => {
  const { className, translation, user } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  return (
    <div className={merged}>
      <Label className="font-bold mb-4" msg={<TranslatedText collection={translation} id={user?.id} />} />
      <div>{user?.brandName}</div>
      {user?.address && <div>{user?.address}</div>}
      {user?.companyCode && (
        <div>
          <TranslatedText collection={translation} id="companyCode" /> {user?.companyCode}
        </div>
      )}
      {user?.VATCode && (
        <div>
          <TranslatedText collection={translation} id="VATCode" /> {user?.VATCode}
        </div>
      )}
      <div>
        <TranslatedText collection={translation} id="bankAccountNumber" /> {user?.bankAccount}
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
