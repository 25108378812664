import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable } from 'rxjs'

import { mergeMap } from 'rxjs/operators'

import { ADD_NOTIFICATION, AddNotificationAction } from './actions'
import { addNotificationImp } from './implementation'

const addNotificationEpic: Epic<any, any> = (action$: Observable<AddNotificationAction>) =>
  action$.pipe(
    ofType(ADD_NOTIFICATION),
    mergeMap(({ notification }) => {
      addNotificationImp(notification)
      return []
    })
  )

export const notificationEpics = combineEpics(addNotificationEpic)
