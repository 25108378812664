import React, { useEffect, useState } from 'react'
import * as firebase from 'firebase'
import { css, cx } from 'emotion'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useFirestore } from 'hoc/FirebaseProvider'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, TranslatedText } from 'components/atoms'
import { AdminDetailsForm } from 'components/organisms'
import { CardLayout } from 'components/templates/CardLayout'
import { DIALOG_CANCEL, setModal } from 'store/modal'
import { push } from 'connected-react-router'
import { addNotification } from 'store/notification'
import { addError } from 'store/error'
import { getCurrentUser } from 'store/user'

interface AdminsGeneralComponentProps {
  className?: string
  children?: React.ReactNode
  push: typeof push
}

const AdminGeneralSchema = Yup.object().shape({
  companyName: Yup.string().required('errorRequired'),
  companyCode: Yup.string().required('errorRequired'),
  bankAccount: Yup.string().required('errorRequired'),
  email: Yup.string().email('errorInvalidEmail'),
  telNumber: Yup.string().required('errorRequired'),
  addressCity: Yup.string().required('errorRequired'),
  addressStreet: Yup.string().required('errorRequired'),
  addressZip: Yup.string()
    .required('errorRequired')
    .matches(/^LT-[0-9]{5}$/, 'errorZipAddress'),
})

const TRANSLATION_BLOCK = 'profile'

const AdminsGeneralComponent = (props: AdminsGeneralComponentProps) => {
  const { className, push } = props

  const classes = styles()
  const merged = cx(classes.component, className)
  const { t } = useTranslation([TRANSLATION_BLOCK])
  const user = useSelector(getCurrentUser)
  const db = useFirestore()

  const [userDetails, setUserDetails] = useState<any>({})
  const userId = 'adminInfo'
  const dispatch = useDispatch()

  //Fetch admin details
  useEffect(() => {
    const fetchData = async () => {
      const docRef = db.collection('common').doc(userId)
      const doc = await docRef.get()
      const data = doc.data()
      setUserDetails(data || {})
    }
    fetchData()
  }, [db, dispatch])

  const getInitialValues = (): any => {
    return {
      companyName: userDetails.companyName || '',
      VATCode: userDetails.VATCode || '',
      companyCode: userDetails.companyCode || '',
      bankAccount: userDetails.bankAccount || '',
      email: userDetails.email || '',
      telNumber: userDetails.telNumber || '',
      addressCity: userDetails.addressCity || '',
      addressStreet: userDetails.addressStreet || '',
      addressNumber: userDetails.addressNumber || '',
      addressZip: userDetails.addressZip || '',
    }
  }

  const handleSubmit = async (values: any) => {
    const docRef = db.collection('common').doc(userId)

    const item = {
      ...userDetails,
      ...values,
      created: userDetails.created || firebase.firestore.Timestamp.now(),
      edited: firebase.firestore.Timestamp.now(),
      editUser: user?.email,
    }

    docRef
      .set(item, { merge: true })
      .then(res => {
        dispatch(addNotification('success', t('profile_saved')))
        push('/seller')
      })
      .catch(error => {
        dispatch(addError(error, 'save_error'))
      })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: AdminGeneralSchema,
    onSubmit: handleSubmit,
  })

  const handleCancel = () => {
    if (formik.dirty) {
      dispatch(
        setModal('ConfirmDialog', {
          collection: TRANSLATION_BLOCK,
          action: {
            type: DIALOG_CANCEL,
            formik,
            successNote: t('changes_cancelled'),
          },
        })
      )
    } else {
      dispatch(addNotification('success', t('changes_cancelled')))
    }
  }

  return (
    <form className={merged} onSubmit={formik.handleSubmit} noValidate>
      <h1 className="pb-2">
        <TranslatedText collection={'adminDetails'} id="adminGeneralTitle" />
      </h1>
      <CardLayout className="px-16 py-10">
        <AdminDetailsForm formik={formik} />
      </CardLayout>
      <div className="mt-6 flex justify-end">
        <Button color="secondary" onClick={handleCancel}>
          <TranslatedText collection={TRANSLATION_BLOCK} id="cancel" />
        </Button>
        <Button type="submit" disabled={formik.dirty && !formik.isValid} className="ml-4">
          <TranslatedText collection={TRANSLATION_BLOCK} id="save" />
        </Button>
      </div>
    </form>
  )
}

const styles = () => ({
  component: css`
    display: block;
  `,
})

export const AdminsGeneralContainer = connect(undefined, { push })(AdminsGeneralComponent)
