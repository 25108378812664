import React, { useMemo } from 'react'
import { Column, Row } from 'react-table'
import { get, intersection } from 'lodash'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Product, ProductsTable } from 'models/Product'
import { DateFormatter } from 'components/organisms/DateFormatter'
import { PriceWithDiscount } from 'components/atoms/PriceWithDiscount'
import { LabelBadge } from 'components/atoms/LabelBadge'
import { ActionsLayout } from 'components/molecules/ActionsLayout'
import { TranslatedText } from 'components/atoms/TranslatedText'
import { RemoveProduct } from 'components/templates/RemoveProduct'
import { UnpublishProduct } from 'components/templates/UnpublishProduct'

interface Status {
  label: string
  value: string
}

interface useProductsColumnProps {
  productStatus: Status[]
  user: any
  sellerColumn?: boolean
}

export function useProductsColumn({ productStatus, user, sellerColumn = true }: useProductsColumnProps) {
  const { t } = useTranslation(['productsPage'])
  const history = useHistory()

  const filterByCategory = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        const rowValue = (row.original as Product).categoryIds

        if (filterValue.length === 0) {
          return true
        }

        const intersect = intersection(rowValue, filterValue)
        return intersect.length > 0
      })
    }
  }, [])

  const filterByStatus = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(({ original }) => {
        const rowValue = (original as Product).convertedStatus

        if (filterValue.length === 0) {
          return true
        }

        const intersect = intersection(rowValue, filterValue)
        return intersect.length > 0
      })
    }
  }, [])

  const filterByBrand = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        const rowValue = (row.original as Product).userId

        if (filterValue.length === 0) {
          return true
        }

        return filterValue.indexOf(rowValue) !== -1
      })
    }
  }, [])

  const filterByDateRange = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        const rowValue = (row.original as Product).created

        if (filterValue.length === 0) {
          return true
        }

        const momentDate = moment(rowValue)
        const from = moment(filterValue[0])
        const to = moment(filterValue[1])

        return momentDate.isBetween(from, to)
      })
    }
  }, [])

  const getBadgeType = (value: string) => {
    switch (value) {
      case 'Unpublished':
        return 'default'
      case 'Active':
        return 'success'
      case 'Deleted':
        return 'error'
      case 'Sold out':
        return 'warning'
      default:
        return 'default'
    }
  }

  return useMemo(() => {
    let defaultColumns = [
      {
        Header: 'ID',
        accessor: 'id',
        width: 50,
      },
      {
        Header: t('prodNameColumn'),
        accessor: 'name',
        width: 200,
      },
      {
        Header: t('categoryColumn'),
        accessor: 'categoryNames',
        filter: filterByCategory,
        disableSortBy: true,
        width: 200,
      },
      {
        Header: t('dateColumn'),
        accessor: 'created',
        filter: filterByDateRange,
        sortType: 'datetime',
        Cell: ({ row }: any) => {
          const data = row.original as ProductsTable
          return <DateFormatter date={data.created} />
        },
      },
      {
        Header: t('quantityColumn'),
        accessor: 'quantity',
        width: 100,
      },
      {
        Header: t('priceColumn'),
        accessor: 'price',
        width: 100,
        Cell: ({ row }: any) => {
          const { price, discountedPrice } = row.original as ProductsTable
          return (
            <PriceWithDiscount
              price={price}
              priceSuffix={'€'}
              priceWithDiscount={discountedPrice === null ? undefined : discountedPrice}
            />
          )
        },
      },
      {
        Header: t('discountColumn'),
        accessor: 'discount',
        width: 100,
        Cell: ({ cell: { value } }: any) => {
          return <span>{value > 0 ? `-${value} %` : `${value}`}</span>
        },
      },
      {
        Header: t('statusColumn'),
        accessor: 'status',
        disableSortBy: true,
        filter: filterByStatus,
        Cell: ({ cell: { value } }: any) => {
          const list = []

          if (value.deleted) {
            const translatedText = productStatus.find(stat => stat.value === 'Deleted')

            const deleteLabel = (
              <LabelBadge
                key={'deleted'}
                className="mb-1"
                type={getBadgeType('Deleted')}
                text={translatedText?.label || 'Deleted'}
                size="sm"
              />
            )

            list.push(deleteLabel)
          }

          if (value.published) {
            const translatedText = productStatus.find(stat => stat.value === 'Published')

            const publishedLabel = (
              <LabelBadge
                key={'active'}
                className="mb-1"
                type={getBadgeType('Active')}
                text={translatedText?.label || 'Active'}
                size="sm"
              />
            )
            list.push(publishedLabel)
          } else {
            const translatedText = productStatus.find(stat => stat.value === 'Unpublished')

            const unpublishedLabel = (
              <LabelBadge
                key={'unpublished'}
                className="mb-1"
                type={getBadgeType('Unpublished')}
                text={translatedText?.label || 'Draft'}
                size="sm"
              />
            )

            list.push(unpublishedLabel)
          }

          return list
        },
      },
      {
        Header: '',
        accessor: 'actions',
        sticky: 'right',
        disableSortBy: true,
        width: 40,
        minWidth: 40,
        Cell: ({ row }: any) => {
          const data = row.original as ProductsTable
          const id = get(data, 'docId', 'new')
          const status = data.status
          if (status.deleted) {
            return <></>
          }
          return (
            <ActionsLayout isTable>
              <div className="cursor-pointer  p-4 hover:bg-gray-200" onClick={() => onActionClick(id)}>
                <TranslatedText collection="productsPage" id="edit" />
              </div>
              <div className="cursor-pointer p-4 hover:bg-gray-200" onClick={() => onDuplicateActionClick(id)}>
                <TranslatedText collection="productsPage" id="duplicate" />
              </div>
              {status.published && (
                <UnpublishProduct className="cursor-pointer p-4 hover:bg-gray-200" id={id}>
                  <TranslatedText collection="productsPage" id="unpublish" />
                </UnpublishProduct>
              )}
              <RemoveProduct className="cursor-pointer p-4 hover:bg-gray-200" id={id}>
                <TranslatedText collection="productsPage" id="remove" />
              </RemoveProduct>
            </ActionsLayout>
          )
        },
      },
    ] as Array<Column>

    if (user.role === 'admin' && sellerColumn) {
      defaultColumns = [
        ...defaultColumns.slice(0, 2),
        // inserted item
        {
          Header: t('brandColumn'),
          accessor: 'brand',
          filter: filterByBrand,
          width: 200,
        },
        // part of the array after the specified index
        ...defaultColumns.slice(2),
      ] as Array<Column>
    }

    const onActionClick = (id: string) => {
      history.push(`/product/${id}`)
    }

    const onDuplicateActionClick = (id: string) => {
      history.push({
        pathname: `/product/new`,
        state: { duplicateProductId: id },
      })
    }

    return defaultColumns
  }, [
    filterByBrand,
    filterByCategory,
    filterByDateRange,
    filterByStatus,
    history,
    productStatus,
    sellerColumn,
    t,
    user.role,
  ])
}
