import React from 'react'
import { css, cx } from 'emotion'

import { Address } from 'models/Order'

export interface AddressProps {
  className?: string
  title: string
  accountTitle?: string
  address: Address
}

export const AddressInfo = (props: AddressProps) => {
  const { className, title, accountTitle, address } = props
  const classes = styles()
  const merged = cx('flex flex-col', classes.address, className)

  return (
    <div className={merged}>
      <div className="font-bold text-lg mb-2">{title}</div>
      <div>{address?.name}</div>
      <div>{address?.street}</div>
      <div>
        {address?.postCode} {address?.city}
      </div>
      <div>{address?.email}</div>
      <div>{address?.phone}</div>

      {address?.accountNumber && accountTitle && (
        <>
          <div className="font-bold mb-2 mt-5">{accountTitle}</div>
          <div>{address.accountNumber}</div>
        </>
      )}
    </div>
  )
}

const styles = () => ({
  address: css``,
})
