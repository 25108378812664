import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { TranslatedText, Input, Label, Icon } from 'components/atoms'
import { SelectInput } from 'components/atoms/SelectInput'
import { Category } from 'models'
import { ReactComponent as Info } from '../../../resources/images/icons/info.svg'
import { IOption } from 'components/util'
import { useParams } from 'react-router-dom'

export interface IOptions extends IOption {
  label: string
  options: IOption[]
}

interface AddProductFormProps {
  className?: string
  children?: React.ReactNode
  categories: Category[]
  country: string
  colors: IOption[]
  formik: any
  categoriesDisabled?: boolean
  maxCategoriesMessage?: string
  setCategories: (event: Category[]) => void
}

const INPUT_WRAPPER = 'h-24 relative'
const FLEX = 'flex justify-between'
const INPUT_STYLE =
  'px-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ERROR_MSG_STYLE = 'text-xs text-red-500'
const FORM_STYLE = 'w-full '

export const AddProductForm = (props: AddProductFormProps) => {
  const {
    className,
    categories,
    categoriesDisabled = false,
    colors,
    country,
    formik,
    maxCategoriesMessage,
    setCategories,
  } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(FORM_STYLE, classes.component, className)
  let { id } = useParams()
  const virtual = 'new-coupon' === id || formik.values.virtual
  const lithuanian = !country || 'LT' === country

  const setFieldValue = (type: string, event: any) => {
    const value = event ? event : []
    formik.setFieldValue(type, value)
  }

  const onCategoriesChange = (event: any) => {
    if (!event) {
      formik.values.items.forEach((item: any, index: number) => {
        setFieldValue(`items[${index}].size`, { label: '', value: '' })
      })
    }
    setCategories(event || [])
    setFieldValue('categoryIds', event)
  }

  const { t } = useTranslation(['newProductForm'])

  const placeholderMap: { [key: string]: string } = {
    name: t('namePlaceholder'),
    desc: t('descPlaceholder'),
    material: t('materialPlaceholder'),
    category: t('categoryPlaceholder'),
    color: t('colorPlaceholder'),
    price: t('pricePlaceholder'),
    discount: t('discountPlaceholder'),
  }

  const handlePriceBlur = (event: any) => {
    if (event) {
      event.target.value = parseFloat(event.target.value).toFixed(2)
    }
    formik.handleChange(event)
    formik.handleBlur(event)
  }

  return (
    <div className={merged}>
      <div className={INPUT_WRAPPER}>
        <label htmlFor="agreementNumber">
          <TranslatedText collection="newProductForm" id="name" />
        </label>
        <Input
          name="name"
          type="text"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.name}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['name']}
        />
        {!formik.values.name ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newProductForm" id={'errorRequired'} />}
          />
        ) : null}
      </div>
      <div className={cx(INPUT_WRAPPER, 'h-32')}>
        <label htmlFor="desc">
          <TranslatedText collection="newProductForm" id="desc" />
        </label>
        <textarea
          name="desc"
          onChange={formik.handleChange}
          value={formik.values.desc}
          required
          className={cx(INPUT_STYLE, classes.textarea, 'h-24')}
          placeholder={placeholderMap['desc']}
        />
        {formik.errors.desc ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newProductForm" id={formik.errors.desc} />}
          />
        ) : null}
      </div>
      {!virtual ? (
        <div className={cx(INPUT_WRAPPER, 'h-28')}>
          <label htmlFor="material">
            <TranslatedText collection="newProductForm" id="material" />
          </label>
          <textarea
            name="material"
            onChange={formik.handleChange}
            value={formik.values.material}
            required
            className={cx(INPUT_STYLE, classes.textarea, 'h-20')}
            placeholder={placeholderMap['material']}
          />
          {formik.errors.material ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="newProductForm" id={formik.errors.material} />}
            />
          ) : null}
        </div>
      ) : null}
      {!virtual ? (
        <div className={cx(classes.categoryInput, 'h-auto')}>
          <label htmlFor="categoryIds" className="flex items-center">
            <TranslatedText collection="newProductForm" id="category" />
            <span className="w-6" data-tip={t('categoryInfo', { maxCount: 2 })}>
              <Icon
                className={cx('ml-1', classes.icon)}
                svgIcon={<Info className={classes.icon} />}
                height={16}
                width={16}
              />
            </span>
          </label>
          <SelectInput
            name="cetegories"
            noOptionsMessage={maxCategoriesMessage}
            disabled={categoriesDisabled}
            value={formik.values.categoryIds}
            options={categories}
            isMulti
            closeMenuOnSelect={false}
            handleChange={onCategoriesChange}
            handleBlur={formik.handleBlur}
          />
          {formik.errors.categoryIds ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="newProductForm" id={formik.errors.categoryIds as string} />}
            />
          ) : null}
        </div>
      ) : null}
      {!virtual ? (
        <div className={INPUT_WRAPPER}>
          <label htmlFor="color">
            <TranslatedText collection="newProductForm" id="color" />
          </label>
          <SelectInput
            name="color"
            value={formik.values.color}
            options={colors}
            handleChange={(e: any) => setFieldValue('color', e)}
            handleBlur={formik.handleBlur}
          />
          {formik.errors.color ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="newProductForm" id={formik.errors.color} />}
            />
          ) : null}
        </div>
      ) : null}
      <div className={cx(INPUT_WRAPPER, FLEX, classes.smallInputWrapper)}>
        <div>
          <label htmlFor="price">
            <TranslatedText collection="newProductForm" id="price" />
          </label>
          <Input
            name="price"
            step="0.01"
            type="number"
            max={1000000}
            handleChange={formik.handleChange}
            handleBlur={handlePriceBlur}
            value={formik.values.price}
            required
            className={INPUT_STYLE}
            placeholder={placeholderMap.price}
          />
          {formik.errors.price ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="newProductForm" id={formik.errors.price} />}
            />
          ) : null}
        </div>
        {!virtual ? (
          <div>
            <label htmlFor="discount">
              <TranslatedText collection="newProductForm" id="discount" />
            </label>
            <Input
              name="discount"
              max={100}
              type="number"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              value={formik.values.discount}
              required
              className={INPUT_STYLE}
              placeholder={placeholderMap['discount']}
            />
            {formik.errors.discount ? (
              <Label
                className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
                msg={<TranslatedText collection="newProductForm" id={formik.errors.discount} />}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      {!virtual ? (
        <div className="mb-5 flex items-center">
          <input
            type="checkbox"
            name="eco"
            checked={formik.values.eco}
            onChange={() => formik.setFieldValue('eco', !formik.values.eco)}
          />
          <label htmlFor="eco" className="ml-5 flex items-center">
            <TranslatedText collection="newProductForm" id="ecoLabel" />
            <span className="w-6" data-tip={t('ecoInfo')}>
              <Icon
                className={cx('ml-1', classes.icon)}
                svgIcon={<Info className={classes.icon} />}
                height={16}
                width={16}
              />
            </span>
          </label>
          {formik.errors.eco ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="newProductForm" id={formik.errors.eco} />}
            />
          ) : null}
        </div>
      ) : null}
      {virtual ? <div className="mb-96"></div> : null}

      <div className="mb-10">
        {!virtual && lithuanian ? (
          <div className="flex items-center mb-5">
            <TranslatedText collection="newProductForm" id="shippingMethods" />
            <span className="w-6" data-tip={t('shippingMethodsInfo')}>
              <Icon
                className={cx('ml-1', classes.icon)}
                svgIcon={<Info className={classes.icon} />}
                height={16}
                width={16}
              />
            </span>
          </div>
        ) : null}
        {!virtual && lithuanian ? (
          <div>
            <input
              type="checkbox"
              name="shippingLpTerminal"
              checked={formik.values.shippingLpTerminal}
              onChange={() => formik.setFieldValue('shippingLpTerminal', !formik.values.shippingLpTerminal)}
            />
            <label htmlFor="lpTerminal" className="ml-5">
              <TranslatedText collection="newProductForm" id="lpExpress" />
            </label>
            {formik.errors.shippingLpTerminal ? (
              <Label
                className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
                msg={<TranslatedText collection="newProductForm" id={formik.errors.shippingLpTerminal} />}
              />
            ) : null}
          </div>
        ) : null}
        {!virtual && lithuanian ? (
          <div>
            <input
              type="checkbox"
              name="shippingLpCourier"
              checked={formik.values.shippingLpCourier}
              onChange={() => formik.setFieldValue('shippingLpCourier', !formik.values.shippingLpCourier)}
            />
            <label htmlFor="lpCourier" className="ml-5">
              <TranslatedText collection="newProductForm" id="lpExpressCourier" />
            </label>
            {formik.errors.shippingLpCourier ? (
              <Label
                className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
                msg={<TranslatedText collection="newProductForm" id={formik.errors.shippingLpCourier} />}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
  smallInputWrapper: css`
    > div {
      flex-basis: 40%;
    }
  `,
  additionaInputWrapper: css`
    min-width: 29rem;
    > div {
      flex-basis: 25%;
    }
  `,
  textarea: css`
    resize: none;
  `,
  errorMsg: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
  categoryInput: css`
    min-height: 6rem;
  `,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
