import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useSelector, useDispatch } from 'react-redux'
import { getProductIds, UPDATE_PRODUCT_DISCOUNT } from 'store/product'
import { map } from 'lodash'
import { RemoveProduct } from 'components/templates/RemoveProduct'
import { Button, TranslatedText } from 'components/atoms'
import { setModal } from 'store/modal'

interface ConditionalButtonsWrapperContainerProps {
  className?: string
  children?: React.ReactNode
}

export const ConditionalButtonsWrapperContainer = (props: ConditionalButtonsWrapperContainerProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const selectedProducts = useSelector(getProductIds).list
  const productIds = map(selectedProducts, p => p.docId)
  const productNames = map(selectedProducts, p => p.name)

  const dispatch = useDispatch()

  return selectedProducts.length > 0 ? (
    <div className={merged}>
      <RemoveProduct id={productIds} list={productNames} className="mr-6">
        <Button>{<TranslatedText collection="productsPage" id="removeProducts" />}</Button>
      </RemoveProduct>
      <Button
        onClick={() =>
          dispatch(
            setModal('DiscountDialog', {
              collection: 'addDiscountDialog',
              action: {
                type: UPDATE_PRODUCT_DISCOUNT,
                productIds: productIds,
              },
            })
          )
        }>
        {<TranslatedText collection="productsPage" id="discountProducts" />}
      </Button>
    </div>
  ) : null
}

const styles = (theme: any) => ({
  component: css``,
})
