import { routerMiddleware } from 'connected-react-router'

import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'

import { history } from './history'
import rootEpic from './epics'
import createRootReducer from './reducers'
import i18n, { DEFAULT_LANGUAGE } from '../config/i18n'

const epicMiddleware = createEpicMiddleware({
  dependencies: { translations: i18n.getDataByLanguage(DEFAULT_LANGUAGE) },
})

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const resetEnhancer = (rootReducer: any) => (state: any, action: any) => {
  if (action.type !== 'LOGOUT') return rootReducer(state, action)

  const newState = rootReducer(undefined, {})
  newState.router = state.router
  return newState
}

const configureStore = (initialStore: any) => {
  const store = createStore(
    resetEnhancer(createRootReducer(history)),
    initialStore,
    composeEnhancers(applyMiddleware(epicMiddleware, routerMiddleware(history)))
  )

  epicMiddleware.run(rootEpic)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default(history)
      store.replaceReducer(nextReducer)
    })
  }

  return store
}

export default configureStore
