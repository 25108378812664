import React, { ComponentType } from 'react'
import Modal from 'react-modal'
import { css, cx } from 'emotion'
import { useTheme } from 'emotion-theming'

import { Icon } from 'components/atoms/Icon'
import { ReactComponent as Close } from '../../resources/images/icons/close.svg'
import { useDispatch } from 'react-redux'
import { deleteModal } from 'store/modal'
import { useHistory } from 'react-router-dom'

const MODAL_STYLE =
  'items-center shadow focus:outline-none absolute bg-white max-w-lg w-full h-auto top-1/2 left-1/2 right-auto bottom-auto -mr-1/2 transform -translate-y-1/2 -translate-x-1/2 overflow-y-auto'
const ICON_STYLE = 'text-gray-900 absolute right-0 top-0 mt-4 mr-4 cursor-pointer'

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
  },
}

// Call this to properly hide your application from assistive screenreaders and other assistive technologies while the modal is open.
Modal.setAppElement('#modal')

export const withModal = (Component: ComponentType) => (props: any) => {
  const { className } = props
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(MODAL_STYLE, classes.component, className)
  const dispatch = useDispatch()
  const history = useHistory()

  const closeModal = () => {
    if (props.shouldReloadAfterClose) {
      history.go(0)
    }
    dispatch(deleteModal(props.id))
  }

  return (
    <Modal className={merged} isOpen shouldCloseOnOverlayClick={false}>
      <Icon
        className={ICON_STYLE}
        svgIcon={
          <Close
            className={css`
              path {
                fill: currentColor;
              }
            `}
          />
        }
        height={20}
        width={20}
        onClick={closeModal}
      />
      <Component {...props} />
    </Modal>
  )
}

const styles = (theme: any) => ({
  component: css`
    @media print {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  `,
})
