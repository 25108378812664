import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { TranslatedText, Button } from 'components/atoms'
import { withModal } from 'hoc/withModal/withModal'
import { deleteModal } from 'store/modal'
import { useDispatch } from 'react-redux'
import { ModalProps } from 'models'

interface ConfirmationDialogProps extends ModalProps {
  className?: string
  children?: React.ReactNode
  collection?: string
}

const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3'
const BUTTON_WRAPPER_CLASSES = 'mt-5 p-5 flex justify-center items-center'

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { className, collection = '', id, list, action } = props
  const dispatch = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx('pt-5', classes.component, className)

  const confirmAction = () => {
    if (action) {
      dispatch({ ...action, modalId: id })
    } else {
      console.warn(`No action provided for this confirm dialog`, props)
    }
  }
  const cancelAction = () => {
    dispatch(deleteModal(id))
  }

  return (
    <div className={merged}>
      <TranslatedText className={TITLE_CLASSES} collection={collection} id="confirmTitle" />
      <TranslatedText className={DESC_CLASSES} collection={collection} id="confirmDesc" />
      {list && (
        <ul className="ml-10 px-5 overflow-y-auto max-h-64">
          {list.map((item: string, index: number) => (
            <li key={index} className="text-sm list-disc">
              {item}
            </li>
          ))}
        </ul>
      )}
      <div className={BUTTON_WRAPPER_CLASSES}>
        <Button className="mr-2" onClick={confirmAction}>
          <TranslatedText collection={collection} id="okText" />
        </Button>
        <Button color="secondary" onClick={cancelAction}>
          <TranslatedText collection={collection} id="cancelText" />
        </Button>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
})

export const ConfirmationDialogModal = withModal(ConfirmationDialog)
