import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUser } from 'store/user'
import { css, cx } from 'emotion'

import { ListLayout, ProductListContainer } from 'components/templates'
import { Button } from 'components/atoms'
import { PagerFilter, ProductsFilterContainer } from 'components/organisms'
import { useTheme } from 'emotion-theming'

interface ProductListLayoutProps {
  className?: string
  filterUserId?: string
  sellerColumn?: boolean
}

const TABLE_ID = 'ProductsTable1'

export const ProductListLayout = ({ className, filterUserId, sellerColumn = true }: ProductListLayoutProps) => {
  const { t } = useTranslation(['productsPage'])
  const user = useSelector(getUser)

  const history = useHistory()

  const classes = styles(useTheme<any>())
  const merged = cx(classes.component, className)

  const list = <ProductListContainer tableId={TABLE_ID} sellerColumn={sellerColumn} />
  const filter = <ProductsFilterContainer id={TABLE_ID} filterUserId={filterUserId} sellerFilter={sellerColumn} />
  const pagerFilter = <PagerFilter id={TABLE_ID} />

  const btnNew = user && user.role !== 'admin' && (
    <Button onClick={() => history.push('/product/new')}>{t('addProduct')}</Button>
  )
  const btnNewCoupon = user && user.role === 'admin' && (
    <Button onClick={() => history.push('/product/new-coupon')}>{t('addCoupon')}</Button>
  )

  const buttons = []
  buttons.push(btnNew)
  buttons.push(btnNewCoupon)

  return (
    <ListLayout
      className={merged}
      tableId={TABLE_ID}
      filter={filter}
      list={list}
      buttons={buttons}
      pagerFilter={pagerFilter}
    />
  )
}

const styles = (theme: any) => ({
  component: css`
    display: flex;
  `,

  filterWrapper: css`
    min-height: 4rem;
  `,
  list: css`
    min-height: 220px;
  `,
})
