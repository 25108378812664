import React from 'react'
import { css, cx } from 'emotion'
import { get } from 'lodash'
import { InvoiceContainer } from 'components/organisms'
import { ReactComponent as Delete } from '../../../resources/images/icons/close.svg'
import { Icon, TranslatedText } from 'components/atoms'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'emotion-theming'

interface InvoicePageProps {
  className?: string
}

const CLOSE_BUTTON_STYLE = 'absolute top-0 right-0 mr-5 lg:mr-36 mt-4 z-50'
const CLOSE_ICON_STYLE = 'bg-gray-900 text-white flex justify-center items-center h-12 w-12 rounded-full cursor-pointer'

export const InvoicePage = (props: InvoicePageProps) => {
  const { className } = props
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const userId = get(props, 'match.params.id')
  const search = get(props, 'location.search')
  const invoice: string | undefined = search && (new URLSearchParams(search).get('invoice') as string)

  const history = useHistory()

  return (
    <div className={merged}>
      <div className={CLOSE_BUTTON_STYLE} id="closeInvoice">
        <div className={CLOSE_ICON_STYLE} onClick={() => history.goBack()}>
          <Icon svgIcon={<Delete className={classes.icon} />} width={16} height={16} />
        </div>
        <TranslatedText collection="globalEvents" id="close" />
      </div>
      <InvoiceContainer className={className} userId={userId} invoice={invoice} />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    @media print {
      #closeInvoice {
        display: none !important;
      }
    }
  `,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
