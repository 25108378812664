import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { TranslatedText, Input, Label } from 'components/atoms'
import { useTranslation } from 'react-i18next'

interface UsersDetailsFormProps {
  className?: string
  children?: React.ReactNode
  formik: any
}

const INPUT_STYLE =
  'px-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ERROR_MSG_STYLE = 'text-xs text-red-500'
const FORM_STYLE = 'mt-6 w-full '

export const UsersDetailsForm = (props: UsersDetailsFormProps) => {
  const { className, formik } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(FORM_STYLE, classes.component, className)

  const { t } = useTranslation(['userDetails'])

  const placeholderMap: { [key: string]: string } = {
    brandName: t('brandName'),
    shopDesc: t('shopDesc'),
    telNumber: t('telNumber'),
    senderAddressCity: t('senderAddressCity'),
    senderAddressField1: t('senderAddressField1'),
    senderAddressField2: t('senderAddressField2'),
    senderAddressZip: t('senderAddressZip'),
  }

  return (
    <div className={merged}>
      <div className="h-20 relative">
        <label htmlFor="brandName">
          <TranslatedText collection="userDetails" id="brandNameLabel" />
        </label>
        <Input
          name="brandName"
          type="text"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.brandName}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['brandName']}
        />
        {formik.errors.brandName && formik.touched.brandName ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="userDetails" id={formik.errors.brandName} />}
          />
        ) : null}
      </div>
      <div className="h-32 relative">
        <label htmlFor="shopDesc">
          <TranslatedText collection="userDetails" id="shopDescLabel" />
        </label>
        <textarea
          name="shopDesc"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.shopDesc}
          required
          className={cx(INPUT_STYLE, 'h-20 resize-none')}
          placeholder={placeholderMap['shopDesc']}
        />
        {formik.errors.shopDesc && formik.touched.shopDesc ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="userDetails" id={formik.errors.shopDesc} />}
          />
        ) : null}
      </div>
      <div className="h-20 relative">
        <label htmlFor="telNumber">
          <TranslatedText collection="userDetails" id="telNumberLabel" />
        </label>
        <Input
          name="telNumber"
          type="text"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.telNumber}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['telNumber']}
        />
        {formik.errors.telNumber && formik.touched.telNumber ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="userDetails" id={formik.errors.telNumber} />}
          />
        ) : null}
      </div>
      <div className="h-20 relative">
        <label htmlFor="senderAddressCity">
          <TranslatedText collection="userDetails" id="senderAddressCityLabel" />
        </label>
        <Input
          name="senderAddressCity"
          type="text"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.senderAddressCity}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['senderAddressCity']}
        />
        {formik.errors.senderAddressCity && formik.touched.senderAddressCity ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="userDetails" id={formik.errors.senderAddressCity} />}
          />
        ) : null}
      </div>
      <div className="h-20 relative">
        <label htmlFor="senderAddressField1">
          <TranslatedText collection="userDetails" id="senderAddressField1Label" />
        </label>
        <Input
          name="senderAddressField1"
          type="text"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.senderAddressField1}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['senderAddressField1']}
        />
        {formik.errors.senderAddressField1 && formik.touched.senderAddressField1 ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="userDetails" id={formik.errors.senderAddressField1} />}
          />
        ) : null}
      </div>
      <div className={cx('flex justify-between h-24', classes.smallInputWrapper)}>
        <div className="h-20 relative">
          <label htmlFor="senderAddressField2">
            <TranslatedText collection="userDetails" id="senderAddressField2Label" />
          </label>
          <Input
            name="senderAddressField2"
            type="text"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.senderAddressField2}
            required
            className={INPUT_STYLE}
            placeholder={placeholderMap['senderAddressField2']}
          />
          {formik.errors.senderAddressField2 && formik.touched.senderAddressField2 ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="userDetails" id={formik.errors.senderAddressField2} />}
            />
          ) : null}
        </div>
        <div className="h-20 relative">
          <label htmlFor="senderAddressZip">
            <TranslatedText collection="userDetails" id="senderAddressZipLabel" />
          </label>
          <Input
            name="senderAddressZip"
            type="text"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.senderAddressZip}
            required
            className={INPUT_STYLE}
            placeholder={placeholderMap['senderAddressZip']}
          />
          {formik.errors.senderAddressZip && formik.touched.senderAddressZip ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="userDetails" id={formik.errors.senderAddressZip} />}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  errorMsg: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
  smallInputWrapper: css`
    > div {
      flex-basis: 40%;
    }
  `,
})
