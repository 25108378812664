import { REMOVE_USER, BLOCK_USER, UNBLOCK_USER } from './constants'
import { ofType, combineEpics } from 'redux-observable'
import { of, from, Observable } from 'rxjs'

import { switchMap, mergeMap, catchError } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { app } from 'hoc/FirebaseProvider'
import { deleteModal } from 'store/modal'
import { push, go } from 'connected-react-router'
import { addNotification } from 'store/notification'
import { concat, map } from 'lodash'

const deleteUserEpic = (action$: Observable<AnyAction>, $state: any, { translations }: any): Observable<any> =>
  action$.pipe(
    ofType(REMOVE_USER),
    switchMap(({ ids, ...rest }: any) => {
      const db = app.firestore()

      async function getProductsDocs(id: string[]): Promise<any> {
        const listOfPromise: any = id.map(async (i: string) => {
          return new Promise(async resolve => {
            const result = await db.collection('products').where('userId', '==', i).get()
            resolve(result)
          })
        })

        return Promise.all(listOfPromise)
      }

      async function getIds(): Promise<any> {
        let arr: any = []
        const productSnapshot = await getProductsDocs(ids)
        productSnapshot.forEach((product: any) => {
          arr = concat(arr, map(product.docs, 'id'))
        })
        return arr
      }

      const docs = getIds()

      return of({ response: from(docs), ids, props: rest })
    }),
    switchMap(({ response, ids, props }: any) =>
      response.pipe(
        mergeMap((data: string[]) => {
          const db = app.firestore()
          // Get a new write batch
          const batch = db.batch()

          const getIds = (id: string[], collection: string) => {
            const arr: any[] = []
            id.forEach(i => {
              arr.push(db.collection(collection).doc(i))
            })
            return arr
          }
          const sellersCollectionRef = getIds(ids, 'users')
          sellersCollectionRef.forEach(s => {
            batch.delete(s)
          })
          const sellerStatsColectionRef = getIds(ids, 'userStats')
          sellerStatsColectionRef.forEach(s => {
            batch.delete(s)
          })

          const productsCollectionRef = getIds(data, 'products')
          productsCollectionRef.forEach(p => {
            batch.delete(p)
          })

          return of({ response: from(batch.commit()), props })
        }),
        catchError((error: any) => {
          return of({ type: 'ERROR', error: error })
        })
      )
    ),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          const actions = []
          actions.push(deleteModal(props.modalId))
          actions.push(push('/seller'))
          actions.push(addNotification('success', translations['removeUser']['success']))
          return actions
        }),
        catchError((error: any) => {
          return of({ type: 'ERROR', error: error })
        })
      )
    ),
    catchError(error => {
      return of({ type: 'ERROR', error: error })
    })
  )

const blockUserEpic = (action$: Observable<AnyAction>): Observable<any> =>
  action$.pipe(
    ofType(BLOCK_USER),
    switchMap(({ ids, ...rest }: any) => {
      const db = app.firestore()
      // Get a new write batch
      const batch = db.batch()

      const getIds = (id: string[]) => {
        const arr: any[] = []
        id.forEach(i => {
          arr.push(db.collection('users').doc(i))
        })
        return arr
      }

      const sellersCollectionRef = getIds(ids)
      sellersCollectionRef.forEach(async p => {
        await batch.set(p, { disabled: true }, { merge: true })
      })

      return of({ response: from(batch.commit()), props: rest })
    }),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          const actions = []
          actions.push(deleteModal(props.modalId))

          if (props.redirectAfterAction) {
            actions.push(push('/user'))
          } else {
            actions.push(go(0))
          }

          return actions
        })
      )
    ),
    catchError(error => {
      return of({ type: 'ERROR', error: error })
    })
  )

const unblockUserEpic = (action$: Observable<AnyAction>): Observable<any> =>
  action$.pipe(
    ofType(UNBLOCK_USER),
    switchMap(({ ids, ...rest }: any) => {
      const db = app.firestore()
      // Get a new write batch
      const batch = db.batch()

      const getIds = (id: string[]) => {
        const arr: any[] = []
        id.forEach(i => {
          arr.push(db.collection('users').doc(i))
        })
        return arr
      }

      const sellersCollectionRef = getIds(ids)
      sellersCollectionRef.forEach(async p => {
        await batch.set(p, { disabled: false }, { merge: true })
      })

      return of({ response: from(batch.commit()), props: rest })
    }),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          return [go(0)]
        })
      )
    ),
    catchError(error => {
      return of({ type: 'ERROR', error: error })
    })
  )

export const userEpics = combineEpics(deleteUserEpic, blockUserEpic, unblockUserEpic)
