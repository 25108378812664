import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Divider } from 'components/atoms/Divider'

interface SignInTemplateProps {
  className?: string
  children?: React.ReactNode
  link: React.ReactNode
}

export const SignInTemplate = (props: SignInTemplateProps) => {
  const { className, children, link } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  return (
    <div className={merged}>
      <div className="py-10 px-9">
        {/* Form template */}
        {children}
      </div>
      <Divider></Divider>
      <div className="flex justify-center">{link}</div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
