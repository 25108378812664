import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import {
  Column,
  TableState,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import { Pagination, setPagination } from 'store/pagination'
import { Filter } from 'store/filtering'
import { sortByDate } from 'components/util'
import { IndeterminateCheckboxWithForwardRef } from 'components/atoms'

interface TableProps {
  tableId: string
  className?: string
  children?: React.ReactNode
  columns: Column[]
  data: Array<any>
  paginationOptions: Pagination
  filterOptions: Filter
  initialState?: Partial<TableState<object>>
  filterMapByColumnId?: Map<string, string>
  onRowSelect?: (items: any[]) => void
  isRowSelectable?: boolean
  getCellProps?: any
}

export const Table = (props: TableProps) => {
  const {
    tableId,
    className,
    columns,
    data,
    paginationOptions,
    filterOptions,
    initialState,
    filterMapByColumnId,
    onRowSelect = () => {},
    isRowSelectable = false,
  } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const dispatcher = useDispatch()
  const isActionsLast = columns[columns.length - 1].accessor === 'actions'

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Pagination props
    page,
    pageCount,
    gotoPage,
    setPageSize,
    setFilter,
    // Item selection
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: initialState,
      sortTypes: {
        date: sortByDate,
      },
    },
    useSticky,
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect,

    hooks => {
      if (isRowSelectable) {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckboxWithForwardRef {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckboxWithForwardRef {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            width: 50,
          },
          ...columns,
        ])
      }
    }
  )

  // Table filtering
  useEffect(() => {
    if (filterMapByColumnId) {
      filterMapByColumnId.forEach((columnID: string, filterName: string) => {
        if (filterOptions?.[filterName] !== undefined) {
          setFilter(columnID, filterOptions?.[filterName])
        }
      })
    }
  }, [filterOptions, filterMapByColumnId, setFilter])

  // Table pagination works from 0 index
  const prevPaginationOptions = useRef<Pagination>()
  useEffect(() => {
    if (prevPaginationOptions.current?.totalPages !== pageCount) {
      dispatcher(
        setPagination(tableId, {
          initialPage: 0,
          pageSize: paginationOptions?.pageSize,
          currentPage: 0,
          totalPages: pageCount,
          visible: pageCount > 1,
        })
      )
    }

    if (prevPaginationOptions.current?.currentPage !== paginationOptions?.currentPage) {
      gotoPage(paginationOptions.currentPage)
    }

    if (prevPaginationOptions.current?.totalPages !== paginationOptions?.totalPages) {
      gotoPage(paginationOptions.initialPage)
    }

    if (prevPaginationOptions.current?.pageSize !== paginationOptions?.pageSize) {
      setPageSize(paginationOptions.pageSize)
    }

    prevPaginationOptions.current = paginationOptions
  }, [gotoPage, paginationOptions, setPageSize, pageCount, dispatcher, tableId])

  // On rowSelect push value up
  useEffect(() => {
    onRowSelect(selectedFlatRows)
  }, [onRowSelect, selectedFlatRows])

  return (
    <div className={merged}>
      <table id="table" className="relative min-w-full w-full" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex justify-start items-center"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, index: number) => {
            prepareRow(row)
            return (
              <tr
                className={cx({ 'bg-white': index % 2 === 0 }, { 'bg-gray-50': index % 2 !== 0 })}
                {...row.getRowProps()}>
                {row.cells.map((cell: any, cellIndex: number) => {
                  const isPadding = isActionsLast && row.cells.length !== cellIndex + 1
                  return isPadding ? (
                    <td
                      className={'px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word'}
                      {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ) : (
                    <td
                      className={cx(
                        { 'bg-white': index % 2 === 0 },
                        { 'bg-gray-50': index % 2 !== 0 },
                        'flex w-full justify-center content-center'
                      )}
                      {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const styles = (theme: any) => ({
  actions: css`
    background: 'red';
  `,
  component: css`
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: visible;
    table {
      min-height: 450px;
      th,
      td {
        width: 1%;
        &.opened {
          z-index: 4 !important;
        }
      }
    }
  `,
})
