import React from 'react'
import { cx } from 'emotion'
import Select from 'react-select'
import { TranslatedText } from '../TranslatedText'
import { useTranslation } from 'react-i18next'
import { isString } from 'formik'

interface SelectProps {
  className?: string
  name?: string
  options: any
  isMulti?: boolean
  closeMenuOnSelect?: boolean
  value?: any
  defaultValue?: any
  disabled?: boolean
  noOptionsMessage?: string
  handleChange: (event: any) => void
  handleBlur?: (event: any) => void
}

export const SelectInput = (props: SelectProps) => {
  const {
    className,
    name,
    disabled = false,
    options,
    noOptionsMessage,
    isMulti = false,
    value,
    defaultValue,
    closeMenuOnSelect = true,
    handleChange,
    handleBlur,
  } = props
  const merged = cx(className)

  const { t } = useTranslation(['globalEvents'])
  const { t: opT } = useTranslation(['selectOptions'])

  const customStyles = {
    groupHeading: (style: any) => ({
      ...style,
      fontSize: '1.2em',
    }),

    control: (style: any) => ({
      ...style,
      borderColor: '#e2e8f0', //tailwind gray-300
      fontSize: 14,
      outline: 'none',
      ':hover': {
        borderColor: '#e2e8f0', //tailwind gray-300
      },
    }),
    placeholder: (style: any) => ({
      color: '#e2e8f0', //tailwind gray-300
    }),
    multiValue: (style: any) => {
      return {
        ...style,
        backgroundColor: '#1a202c', //tailwind gray-900
      }
    },
    multiValueLabel: (style: any) => ({
      ...style,
      color: 'white',
    }),
    multiValueRemove: (style: any) => ({
      ...style,
      color: '#1a202c', //tailwind gray-900
      ':hover': {
        color: 'white',
      },
    }),
    menu: (style: any) => ({
      ...style,
      zIndex: 10,
    }),
  }

  return (
    <Select
      getOptionLabel={option => (isString(option.label) ? opT(option.label) : option.value)}
      instanceId={name}
      isDisabled={disabled}
      defaultValue={defaultValue}
      value={value}
      className={merged}
      options={options}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      placeholder={<TranslatedText collection="select" id="selectPlaceholder" />}
      noOptionsMessage={noOptionsMessage ? () => t(noOptionsMessage) : () => t('noMoreItems')}
      styles={customStyles}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}
