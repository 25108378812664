import React from 'react'
import { connect } from 'react-redux'
import { Switch, Redirect, Route } from 'react-router-dom'
import { push } from 'connected-react-router'
import { withTheme } from 'emotion-theming'
import { LoginRoute, SecureRoute } from 'hoc'
import {
  LoginPage,
  ProductsPage,
  BalancePage,
  OrdersPage,
  AddProductPage,
  UsersProfilePage,
  DefaultAgreement,
  SellersPage,
  SellerDashboardPage,
  ForgotPasswordPage,
  OrderDetailsPage,
  OrderReturnPage,
  InvoicePage,
  ResendInvitationLinkPage,
  AdminProfilePage,
  FaqPage,
  RegisterPage,
  JoinedPage,
} from './components/pages'

import { CreatePasswordFormContainer } from 'components/organisms/CreatePasswordForm/CreatePasswordFormContainer'

const AppComponent: React.FC = (props: any) => {
  return (
    <Switch>
      <LoginRoute exact path="/login" component={LoginPage} />
      <LoginRoute exact path="/forgot-password" component={ForgotPasswordPage} />
      <LoginRoute exact path="/resend-invite" component={ResendInvitationLinkPage} />
      <LoginRoute exact path="/register" component={RegisterPage} />
      <Route exact path="/default-agreement" component={DefaultAgreement} />
      <LoginRoute exact path="/signup" component={CreatePasswordFormContainer} />
      <SecureRoute exact path="/product" component={ProductsPage} />
      <SecureRoute exact path="/seller" component={SellersPage} />
      <SecureRoute exact path="/joined" component={JoinedPage} />
      <SecureRoute exact path="/faq" component={FaqPage} />
      <SecureRoute exact path="/product/:id" component={AddProductPage} />
      <SecureRoute exact path="/seller-dashboard/:id" component={SellerDashboardPage} />
      <SecureRoute exact path="/admin" component={AdminProfilePage} />
      <SecureRoute exact path="/user/:id" component={UsersProfilePage} />
      <SecureRoute exact path="/seller/:id" component={UsersProfilePage} />
      <SecureRoute exact path="/balance" component={BalancePage} />
      <SecureRoute exact path="/invoice/:id" component={InvoicePage} />
      <SecureRoute exact path="/order/:id/details" component={OrderDetailsPage} />
      <SecureRoute exact path="/order/:id/:sellerId/details" component={OrderDetailsPage} />
      <SecureRoute exact path="/order/:id/product/:returnId/:returnProductId/return" component={OrderReturnPage} />
      <SecureRoute
        exact
        path="/order/:id/:sellerId/product/:returnId/:returnProductId/return"
        component={OrderReturnPage}
      />
      <SecureRoute exact path="/order" component={OrdersPage} />
      <Redirect from="/" to="/login" />
    </Switch>
  )
}

const mapDispatchToProps = {
  push,
}

export default withTheme(connect(undefined, mapDispatchToProps)(AppComponent))
