import React, { useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { css, cx } from 'emotion'

import { TranslatedText } from 'components/atoms'
import { ActionsLayout } from 'components/molecules'
import { IOption } from 'components/util'
import { setModal } from 'store/modal'
import { CHANGE_ORDER_PRODUCT_STATUS } from 'store/order'

interface ActionMenuProps {
  returnInfo: any
  orderKey: string
  productId: string
  sellerOrderId?: string
  currentStatus?: string
  className?: string
  push: typeof push
}

const TRANSLATION_KEY = 'ordersPage'

export const OrderProductsActionMenuComponent = ({
  returnInfo,
  orderKey,
  productId,
  sellerOrderId,
  currentStatus,
  className,
  push,
}: ActionMenuProps) => {
  const { t } = useTranslation([TRANSLATION_KEY])

  const classes = styles()
  const merged = cx(classes.component, className, 'cursor-pointer', 'hover:bg-gray-200', 'p-4')

  const statusOptions: IOption[] = [
    { label: <TranslatedText collection={'status'} id="toBeReturned" />, value: 'toBeReturned' },
    { label: <TranslatedText collection={'status'} id="returned" />, value: 'returned' },
  ]

  const dispatch = useDispatch()
  const openDialog = useCallback(
    (orderKey: string, productId: string, sellerOrderId?: string, currentStatus?: string) => {
      dispatch(
        setModal('ChangeStatusDialog', {
          collection: TRANSLATION_KEY,
          options: statusOptions,
          defaultValue: statusOptions.find(o => o.value === currentStatus),
          action: {
            type: CHANGE_ORDER_PRODUCT_STATUS,
            orderKey,
            productId,
            sellerOrderId,
            refreshPage: true,
          },
        })
      )
    },
    [dispatch, statusOptions]
  )

  const openReturnDetails = (orderKey: string, productId: string, sellerOrderId?: string) => {
    if (sellerOrderId) {
      push(
        `/order/${orderKey}/${sellerOrderId}/product/${returnInfo.woocommerceRefundId}/${returnInfo.woocommerceRefundItemId}/return`
      )
    } else {
      push(`/order/${orderKey}/product/${returnInfo.woocommerceRefundId}/${returnInfo.woocommerceRefundItemId}/return`)
    }
  }

  return (
    <ActionsLayout isTable>
      <div className={merged} onClick={() => openDialog(orderKey, productId, sellerOrderId, currentStatus)}>
        {t('changeStatus')}
      </div>
      <div className={merged} onClick={() => openReturnDetails(orderKey, productId, sellerOrderId)}>
        {t('returnPolicy')}
      </div>
    </ActionsLayout>
  )
}

const styles = () => ({
  component: css``,
})

export const OrderProductsActionMenu = connect(undefined, { push })(OrderProductsActionMenuComponent)
