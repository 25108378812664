import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { CardLayout } from '../CardLayout'
import { PaginationContainer, ConditionalButtonsWrapperContainer } from 'components/organisms'

interface ListLayoutProps {
  tableId: string
  className?: string
  filter?: React.ReactNode
  list?: React.ReactNode
  buttons?: Array<React.ReactNode>
  pagerFilter?: React.ReactNode
  stats?: React.ReactNode
  children?: React.ReactNode
}

export const ListLayout = ({ className, tableId, filter, list, buttons, pagerFilter, stats }: ListLayoutProps) => {
  const classes = styles(useTheme<any>())
  const merged = cx(classes.component, className)

  return (
    <div className={merged}>
      <CardLayout className="mb-15">
        <div className="p-5 flex flex-col w-full">
          {!pagerFilter && (
            <div
              className={cx(classes.filterWrapper, 'flex-shrink-0 flex-grow-0 flex items-end pb-6 box-content h-auto')}>
              <div className={cx('flex-auto flex flex-row h-auto items-start sm:flex-wrap md:flex-wrap')}>{filter}</div>
              <div className="flex-grow-0">{buttons}</div>
            </div>
          )}
          {pagerFilter && buttons && (
            <div
              className={cx(
                classes.filterWrapper,
                buttons ? 'flex-col' : 'flex-row justify-between',
                'flex flex-shrink-0 flex-grow-1 items-start pb-6 box-content h-auto'
              )}>
              <div className={cx('flex flex-grow-1 flex-row flex-wrap h-auto')}>{filter}</div>
              <div className={cx(buttons ? 'w-full' : 'w-auto', 'flex flex-row items-end')}>
                {buttons.map((btn: React.ReactNode, index: number) => (
                  <div key={index} className="mr-6">
                    {btn}
                  </div>
                ))}
                <ConditionalButtonsWrapperContainer className="flex flex-row justify-center" />
                <div className="ml-auto">{pagerFilter}</div>
              </div>
            </div>
          )}
          {pagerFilter && !buttons && (
            <div
              className={cx(
                classes.filterWrapper,
                'flex-shrink-0 flex-grow-1 flex  flex-row  items-end pb-6 box-content h-auto'
              )}>
              <div className={cx('flex  flex-grow-1 flex-row flex-wrap h-auto')}>{filter}</div>
              <div className="ml-auto">{pagerFilter}</div>
            </div>
          )}

          <div className={cx(classes.list, 'h-full flex-shrink-0 flex-grow-1')}>{list}</div>
          <div className={cx('flex-shrink-1 flex-grow-0 flex justify-center items-center')}>
            <PaginationContainer tableId={tableId} />
          </div>

          {stats && <div className={cx(classes.list, 'h-full flex-shrink-0 flex-grow-1')}>{stats}</div>}
        </div>
      </CardLayout>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: flex;
  `,
  card: css``,
  filterWrapper: css`
    min-height: 4rem;
  `,
  list: css`
    min-height: 220px;
  `,
})
