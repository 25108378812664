import React from 'react'
import { css, cx } from 'emotion'
import { OrderTypeFilter } from '../OrderTypeFilter'
import { MonthPickerFilterContainer } from '../MonthPickerFilterContainer'

interface FilterContainerProps {
  id: string
  orderType?: string
  className?: string
}

export const OrderProductsFilterContainer = ({ id, className, orderType }: FilterContainerProps) => {
  const classes = styles()
  const merged = cx('flex flex-row w-full justify-start flex-wrap', className)

  return (
    <div className={merged}>
      <OrderTypeFilter orderType={orderType} id={id} />
      <div className="flex flex-row  flex-shrink-0 ml-5">
        <MonthPickerFilterContainer id={id} className={classes.filter} maxMonthDelta={0} useDefaultDate={false} />
      </div>
    </div>
  )
}

// @ts-ignore
const styles = () => ({
  filter: css`
    &:not(:last-child) {
      margin-right: 24px;
    }
  `,
})
