import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'

import { CommonPageLayout, SideNavigationLayout } from 'components/templates'
import { ProductListLayout } from 'components/templates/ProductListLayout'
import { getUser } from 'store/user'

interface ProductsPageProps {
  className?: string
  children?: React.ReactNode
}

export const ProductsPage = (props: ProductsPageProps) => {
  const { className } = props
  const { t } = useTranslation(['productsPage'])

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const user = useSelector(getUser)
  const location = useLocation()

  const filterUserId = new URLSearchParams(location.search).get('user')
  return (
    <div className={merged}>
      {user.role === 'seller' && user.disabled ? (
        <Redirect to={`/seller/${user.uid}`} />
      ) : (
        <SideNavigationLayout>
          <CommonPageLayout pageTitle={t('title')}>
            <ProductListLayout filterUserId={filterUserId != null ? filterUserId : undefined} />
          </CommonPageLayout>
        </SideNavigationLayout>
      )}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: flex;
  `,
})
