import { AppState } from '../reducers'
import { FILTERING_STATE_KEY } from './constants'

export const getFilterState = (state: AppState) => state[FILTERING_STATE_KEY]
export const getFilter = (state: AppState, tableName: string) => getFilterState(state).tables[tableName]
export const getFreeTextField = (state: AppState, tableName: string) =>
  getFilterState(state).tables[tableName]?.freeText
export const getCategoryField = (state: AppState, tableName: string) =>
  getFilterState(state).tables[tableName].categories
export const getStatusField = (state: AppState, tableName: string) => getFilterState(state).tables[tableName]?.statuses
export const getBrandsField = (state: AppState, tableName: string) => getFilterState(state).tables[tableName]?.brands
export const getDateRangeField = (state: AppState, tableName: string) =>
  getFilterState(state).tables[tableName]?.dateRange
export const getDateField = (state: AppState, tableName: string) => getFilterState(state).tables[tableName]?.date
export const getDateFieldOrDefault = (state: AppState, tableName: string, defValue: any) =>
  getFilterState(state).tables[tableName]?.date || defValue
