import { UserState } from './reducer'
import { SET_CURRENT_USER, LOGOUT, REMOVE_USER, BLOCK_USER, UNBLOCK_USER } from './constants'

export interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER
  payload: UserState
}

export const setCurrentUser = (user: UserState): SetCurrentUserAction => ({
  type: SET_CURRENT_USER,
  payload: user,
})

export interface SetLogoutAction {
  type: typeof LOGOUT
}

export const logout = (): SetLogoutAction => ({
  type: LOGOUT,
})

// Epic
export interface DeleteUserAction {
  type: typeof REMOVE_USER
  ids: string[]
}

export const deleteUser = (ids: string[] = []): DeleteUserAction => ({
  type: REMOVE_USER,
  ids: ids,
})

export interface BlockUserAction {
  type: typeof BLOCK_USER
  ids: string[]
}

export const blockUser = (ids: string[] = []): BlockUserAction => ({
  type: BLOCK_USER,
  ids: ids,
})

export interface UnBlockUserAction {
  type: typeof UNBLOCK_USER
  ids: string[]
}

export const unblockUser = (ids: string[] | string): UnBlockUserAction => ({
  type: UNBLOCK_USER,
  ids: typeof ids === 'string' ? [ids] : ids,
})

export type UserActions = SetCurrentUserAction
