import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import { loadingReducer, LoadingState, LOADING_STATE_KEY } from './loading'
import { userReducer, USER_STATE_KEY, UserState } from './user'
import { modalReducer, MODAL_STATE_KEY, ModalState } from './modal'
import { paginationReducer, PAGINATION_STATE_KEY, PaginationState } from './pagination'
import { filteringReducer, FILTERING_STATE_KEY, FilterState } from './filtering'
import { ProductState, productReducer, PRODUCT_STATE_KEY } from './product'

export type AppState = {
  [LOADING_STATE_KEY]: LoadingState
  [MODAL_STATE_KEY]: ModalState
  [USER_STATE_KEY]: UserState
  [PAGINATION_STATE_KEY]: PaginationState
  [FILTERING_STATE_KEY]: FilterState
  [PRODUCT_STATE_KEY]: ProductState
  router: RouterState
}

export default (history: History<History.PoorMansUnknown>) =>
  combineReducers({
    [LOADING_STATE_KEY]: loadingReducer,
    [MODAL_STATE_KEY]: modalReducer,
    [USER_STATE_KEY]: userReducer,
    [PAGINATION_STATE_KEY]: paginationReducer,
    [FILTERING_STATE_KEY]: filteringReducer,
    [PRODUCT_STATE_KEY]: productReducer,
    router: connectRouter(history),
  })
