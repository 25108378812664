import React, { useCallback } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { ReactComponent as Delete } from '../../../resources/images/icons/close.svg'
import { Icon } from 'components/atoms/Icon'

interface ThumbnailProps {
  className?: string
  img: string
  setSelectedImage: (image: any) => void
  onDelete: (image: any) => void
}

const WRAPPER_CLASSES = 'relative border'
const DELETE_ICON_STYLE =
  'hidden bg-gray-800 text-white flex justify-center items-center h-6 w-6 rounded-full absolute top-0 right-0 -mr-1 -mt-3 z-50 cursor-pointer'
const IMAGE_WRAPPER_CLASSES = 'relative w-20 h-28 overflow-hidden cursor-pointer'

export const Thumbnail = (props: ThumbnailProps) => {
  const { className, img, setSelectedImage, onDelete } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(WRAPPER_CLASSES, classes.component, className)

  const removeIcon: JSX.Element = <Delete className={classes.closeIcon} />

  const onImageClick = useCallback(() => setSelectedImage(img), [img, setSelectedImage])
  const onImageDelete = useCallback(() => onDelete(img), [img, onDelete])

  return (
    <div className={merged}>
      <div className={IMAGE_WRAPPER_CLASSES} onClick={onImageClick}>
        <img alt="Product" src={img} className="w-full h-full object-cover" />
      </div>
      <div className={cx(DELETE_ICON_STYLE, 'icon')} onClick={onImageDelete}>
        <Icon svgIcon={removeIcon} width={12} height={12} />
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    width: fit-content;

    &:hover {
      .icon {
        display: flex;
      }
    }
  `,
  closeIcon: css`
    path {
      fill: currentColor;
    }
  `,
})
