import { SET_CURRENT_PAGE, SET_PAGINATION } from './constants'
import { Pagination } from './reducer'

export interface SetCurrentPageAction {
  type: typeof SET_CURRENT_PAGE
  table: string
  currentPage: number
}

export const setCurrentPage = (table: string, currentPage: number = 0): SetCurrentPageAction => ({
  type: SET_CURRENT_PAGE,
  table,
  currentPage,
})

export interface SetPaginationAction {
  type: typeof SET_PAGINATION
  table: string
  pagination: any
}

export const setPagination = (
  table: string,
  pagination: Pagination = { initialPage: 0, currentPage: 1, pageSize: 0, totalPages: 1, visible: true }
): SetPaginationAction => ({
  type: SET_PAGINATION,
  table,
  pagination,
})

export type PaginationActions = SetCurrentPageAction | SetPaginationAction
