import React from 'react'
import { Redirect } from 'react-router-dom'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getUser } from 'store/user'
import { CommonPageLayout, SideNavigationLayout } from 'components/templates'
import { OrderList } from 'components/organisms/OrderList'

interface OrdersPageProps {
  className?: string
  children?: React.ReactNode
}

export const OrdersPage = (props: OrdersPageProps) => {
  const { className } = props
  const { t } = useTranslation(['ordersPage'])
  const classes = styles()
  const merged = cx(classes.component, className)
  const user = useSelector(getUser)

  return (
    <div>
      {user.role === 'seller' && user.disabled ? (
        <Redirect to={`/seller/${user.uid}`} />
      ) : (
        <SideNavigationLayout className={merged}>
          <CommonPageLayout pageTitle={t('title')}>
            <OrderList />
          </CommonPageLayout>
        </SideNavigationLayout>
      )}
    </div>
  )
}

const styles = () => ({
  component: css``,
})
