import React, { useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { css, cx } from 'emotion'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { setModal } from 'store/modal'
import { useDispatch, useSelector } from 'react-redux'
import { ListLayout, SellerListContainer, SideNavigationLayout, CommonPageLayout } from 'components/templates'
import { Button, TranslatedText } from 'components/atoms'
import { PagerFilter, SellerFilterContainer } from 'components/organisms'
import { getUser } from 'store/user'

interface PageProps {
  className?: string
  children?: React.ReactNode
}

const TABLE_ID = 'SellersTable'

export const SellersPage = ({ className }: PageProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['sellersPage'])

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const filter = <SellerFilterContainer id={TABLE_ID} />
  const list = <SellerListContainer tableId={TABLE_ID} />
  const pagerFilter = <PagerFilter id={TABLE_ID} />
  const user = useSelector(getUser)

  const onAddNeUser = useCallback(() => {
    dispatch(setModal('AddNewUser', { shouldReloadAfterClose: true }))
  }, [dispatch])
  const btnNew = (
    <Button onClick={onAddNeUser}>
      <TranslatedText collection="sellersPage" id="add" />
    </Button>
  )

  return (
    <div className={merged}>
      {user && user.role === 'admin' ? (
        <SideNavigationLayout>
          <CommonPageLayout pageTitle={t('title')}>
            <ListLayout tableId={TABLE_ID} filter={filter} list={list} buttons={[btnNew]} pagerFilter={pagerFilter} />
          </CommonPageLayout>
        </SideNavigationLayout>
      ) : (
        <Redirect to={`/seller/${user.uid}`} />
      )}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: flex;
  `,
})
