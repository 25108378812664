import { REMOVE_PRODUCT, UNPUBLISH_PRODUCT, SET_PRODUCT_IDS, UPDATE_PRODUCT_DISCOUNT } from './constants'

export interface SetProductAction {
  type: typeof SET_PRODUCT_IDS
  list: any[]
}

export const setProductIds = (products: any[] = []): SetProductAction => ({
  type: SET_PRODUCT_IDS,
  list: products,
})

export interface DeleteProductAction {
  type: typeof REMOVE_PRODUCT
  ids: string[]
}

export const deleteProduct = (ids: string[] = []): DeleteProductAction => ({
  type: REMOVE_PRODUCT,
  ids: ids,
})

export interface UnpublishProductAction {
  type: typeof UNPUBLISH_PRODUCT
  ids: string[]
}

export const unpublishProduct = (ids: string[] = []): UnpublishProductAction => ({
  type: UNPUBLISH_PRODUCT,
  ids: ids,
})

export interface UpdateProductDiscountAction {
  type: typeof UPDATE_PRODUCT_DISCOUNT
  ids: string[]
}

export const updateProductDiscount = (ids: string[] = []): UpdateProductDiscountAction => ({
  type: UPDATE_PRODUCT_DISCOUNT,
  ids: ids,
})

export type ProductActions =
  | DeleteProductAction
  | UnpublishProductAction
  | SetProductAction
  | UpdateProductDiscountAction
