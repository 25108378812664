import { SET_MODAL, REMOVE_MODAL_BY_ID } from './constants'
import { ModalProps } from 'models'
import { ModalType } from 'hoc/withModal/ModalRegistry'

export interface SetModalAction {
  type: typeof SET_MODAL
  modalType: ModalType
  modalProps: ModalProps
}

export const setModal = (modalType: ModalType, modalProps: ModalProps = {}): SetModalAction => ({
  type: SET_MODAL,
  modalType,
  modalProps,
})

export interface DeleteModalAction {
  type: typeof REMOVE_MODAL_BY_ID
  id: string
}

export const deleteModal = (id: string): DeleteModalAction => ({
  type: REMOVE_MODAL_BY_ID,
  id: id,
})

export type ModalActions = SetModalAction | DeleteModalAction
