import React, { useCallback } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import * as _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { InputLabel, SelectInput, TranslatedText } from 'components/atoms'
import { getPagination, Pagination, setPagination } from '../../../store/pagination'
import { AppState } from '../../../store/reducers'

interface FilterProps {
  className?: string
  id: string
}

const CONTAINER_CLASSES = 'w-20'
const LABEL_CLASSES = 'pb-1'

//Set option to define default pager filter state
const OPTION_INDEX_DEFAULT = 1

const options = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
]

const defaultOption = options[OPTION_INDEX_DEFAULT]

export const PAGE_SIZE = defaultOption.value

export const PagerFilter = ({ id, className }: FilterProps) => {
  const dispatcher = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CONTAINER_CLASSES, classes.component, className)

  const pager: Pagination = useSelector((state: AppState) => getPagination(state, id)) || {
    initialPage: 0,
    currentPage: 0,
    pageSize: 0,
    totalPages: 0,
    visible: false,
  }

  const updateSelect = useCallback(
    _.debounce((data: any) => {
      if (data && pager.pageSize !== data && pager) {
        dispatcher(setPagination(id, { ...pager, pageSize: data }))
      }
    }, 100),
    []
  )
  const handleChange = (selected: { label: string; value: string }) => {
    if (selected) {
      updateSelect(selected.value)
    } else {
      updateSelect(undefined)
    }
  }

  const value =
    options.find(opt => {
      return pager?.pageSize === opt.value
    }) || defaultOption

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES}>
        <TranslatedText collection="globalEvents" id="pagerFilterLabel" />
      </InputLabel>
      <SelectInput isMulti={false} options={options} handleChange={handleChange} value={value} />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
