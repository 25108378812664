import { ChangePasswordLayoutModal } from 'components/templates/ChangePasswordLayout'
import { NewUserAccLayoutModal } from 'components/templates/NewUserAccLayout'
import {
  ConfirmationDialogModal,
  ChangeStatusDialogModal,
  DiscountDialogModal,
  InfoDialogModal,
  ShippingFormContainer,
} from 'components/organisms'
import { SetVacationDialogModal } from '../../components/organisms/SetVacationDialog/SetVacationDialog'

export const ModalRegistry = {
  ChangePassword: ChangePasswordLayoutModal,
  AddNewUser: NewUserAccLayoutModal,
  ConfirmDialog: ConfirmationDialogModal,
  ChangeStatusDialog: ChangeStatusDialogModal,
  SetVacationDialog: SetVacationDialogModal,
  DiscountDialog: DiscountDialogModal,
  InfoDialog: InfoDialogModal,
  ShippingDialog: ShippingFormContainer,
}

export type ModalType = keyof typeof ModalRegistry
