import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useAuth } from '../../../hoc/FirebaseProvider'
import { isEmpty } from 'lodash'
import { setLoading, isActionLoading } from 'store/loading'
import { useDispatch, useSelector } from 'react-redux'

import { SimpleEmailForm } from 'components/organisms'
import { TranslatedText, Notification, LoadingBar, Logo } from '../../atoms'
import { ContainerLayout } from 'components/templates/ContainerLayout'
import { CardLayout } from 'components/templates/CardLayout'
import { config } from '../../../config'
import { Link } from 'react-router-dom'
import { SignInTemplate } from 'components/templates/SignInTemplate'
import { FirebaseErrorResponse } from 'components/util/interfaces'

interface ForgotPasswordPageProps {
  className?: string
}

const FORGOT_PASS_CARD = 'overflow-hidden box-border max-w-md'
const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3'
const TRANSLATED_TEXT_STYLE = 'h-16 flex items-center justify-center text-gray-900 text-sm cursor-pointer'

export const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  const { className } = props
  const auth = useAuth()
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<string>()
  const dispatch = useDispatch()
  const formLoading = useSelector(isActionLoading('FORGOT_PASSWORD_FORM_REQUEST'))
  const options: any = {
    url: `${config.APP_MAIL_URL}/login`,
  }

  const sendResetEmail = (email: string) => {
    dispatch(setLoading('FORGOT_PASSWORD_FORM_REQUEST'))
    auth.languageCode = 'lt'
    auth
      .sendPasswordResetEmail(email, options)
      .then((response: any) => {
        setError('')
        setSuccess('resetSuccess')
        dispatch(setLoading('FORGOT_PASSWORD_FORM_REQUEST', false))
      })
      .catch((err: FirebaseErrorResponse) => {
        setSuccess('')
        setError(err.code)
        dispatch(setLoading('FORGOT_PASSWORD_FORM_REQUEST', false))
      })
  }

  const clearMessages = () => {
    setError(undefined)
    setSuccess(undefined)
  }

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(FORGOT_PASS_CARD, classes.component, className)

  const link = (
    <Link to="/login">
      <TranslatedText className={TRANSLATED_TEXT_STYLE} collection="forgotForm" id="loginLink" />
    </Link>
  )

  return (
    <ContainerLayout className={'flex-col'}>
      <Logo />
      <CardLayout className={merged}>
        <SignInTemplate link={link}>
          <h2 className={cx(TITLE_CLASSES, classes.title)}>
            <TranslatedText collection="forgotForm" id="title" />
          </h2>
          {formLoading && <LoadingBar className={'bg-gray-900'} />}
          {error && (
            <Notification
              className={classes.notification}
              type="error"
              text={<TranslatedText collection="globalEvents" id={error || ''} />}
            />
          )}
          {success && (
            <Notification
              className={classes.notification}
              type="success"
              text={<TranslatedText collection="globalEvents" id={success || ''} />}
            />
          )}
          <h4 className={cx(DESC_CLASSES, classes.desc)}>
            <TranslatedText collection="forgotForm" id="desc" />
          </h4>
          <SimpleEmailForm
            collection="forgotForm"
            sendResetEvent={sendResetEmail}
            clearMessages={clearMessages}
            isQueryFailed={!isEmpty(error)}
          />
        </SignInTemplate>
      </CardLayout>
    </ContainerLayout>
  )
}

const styles = (theme: any) => ({
  fullScreen: css``,
  component: css``,
  title: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  desc: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  notification: css`
    min-width: 100%;
    font-family: 'SourceSansPro', sans-serif;
  `,
})
