import React, { useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'hoc/FirebaseProvider'
import moment from 'moment'

import { AppState } from 'store/reducers'
import { getFilter } from 'store/filtering'
import { Order, OrderOrdersStatsTable, OrderOrdersTable, SellerOrder } from 'models/Order'
import { addNotification } from 'store/notification'
import { getCurrentUser } from 'store/user'
import { SimpleTable } from '../../molecules/Table/SimpleTable'

interface StatsContainerProps {
  tableId: string
  userId?: string
}

export const OrderOrdersStatsContainer = ({ tableId, userId }: StatsContainerProps) => {
  const [orders, setOrders] = useState<OrderOrdersTable[]>([])
  const [filteredOrders, setFilteredOrders] = useState<OrderOrdersTable[]>([])
  const [ordersStats, setOrdersStats] = useState<OrderOrdersStatsTable[]>([])

  const { t } = useTranslation(['ordersPage'])

  const db = useFirestore()
  const filter = useSelector((state: AppState) => getFilter(state, tableId))
  const user = useSelector((state: AppState) => getCurrentUser(state))

  const data = useMemo(() => ordersStats as OrderOrdersStatsTable[], [ordersStats])
  const dispatch = useDispatch()

  const isAdmin = useMemo(() => {
    return user.role === 'admin'
  }, [user])

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'disabled', desc: false }],
      hiddenColumns: [],
    }),
    []
  )

  const columns = useMemo(
    () =>
      [
        {
          Header: t('status'),
          accessor: 'group',
          width: 200,
        },
        {
          Header: t('productCount'),
          accessor: 'productCount',
          width: 100,
        },
        {
          Header: t('orderValue'),
          accessor: 'price',
          Cell: ({ row }) => {
            const value = row.values['price'] as number
            const dispValue = value !== undefined ? `${value.toFixed(2)} €` : ''
            return <div>{dispValue}</div>
          },
          width: 120,
        },
      ] as Array<Column>,
    [t]
  )

  useEffect(() => {
    if (filter && filter['dateRange']) {
      const filteredOrders = orders.filter(order => {
        const filterValue = filter['dateRange']
        if (filterValue.length < 1) {
          return true
        }
        const created = order.orderDate

        if (!created) {
          return false
        }

        const momentDate = moment(created)
        const from = moment(filterValue[0])
        const to = moment(filterValue[1])

        return momentDate.isBetween(from, to)
      })
      setFilteredOrders(filteredOrders)
    } else {
      setFilteredOrders(orders)
    }
  }, [dispatch, orders, filter])

  useEffect(() => {
    const ordersStats: OrderOrdersStatsTable[] = []
    const isStatusSent = (status: string) => status === 'sent' || status === 'delivered'
    const isStatusSold = (status: string) => status === 'sold'
    if (filteredOrders) {
      const soldOrdersStats = { group: t('stats_sold'), productCount: 0, price: 0 }
      const sentOrdersStats = { group: t('stats_sent'), productCount: 0, price: 0 }
      const soldAndSentOrdersStats = { group: t('stats_soldAndSent'), productCount: 0, price: 0 }

      filteredOrders.forEach(order => {
        if (order && isStatusSent(order.status)) {
          sentOrdersStats.productCount += order.productCount
          sentOrdersStats.price += order.price
        }
        if (order && isStatusSold(order.status)) {
          soldOrdersStats.productCount += order.productCount
          soldOrdersStats.price += order.price
        }
      })
      soldAndSentOrdersStats.productCount = soldOrdersStats.productCount + sentOrdersStats.productCount
      soldAndSentOrdersStats.price = soldOrdersStats.price + sentOrdersStats.price

      ordersStats.push(soldOrdersStats)
      ordersStats.push(sentOrdersStats)
      ordersStats.push(soldAndSentOrdersStats)
    }
    setOrdersStats(ordersStats)
  }, [filteredOrders, dispatch, t, user, isAdmin, userId])

  useEffect(() => {
    const orderConverter: firebase.firestore.FirestoreDataConverter<OrderOrdersTable | undefined> = {
      toFirestore(doc: OrderOrdersTable): Order {
        throw new Error('Not implemented')
      },
      fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<Order>,
        options: firebase.firestore.SnapshotOptions
      ): OrderOrdersTable | undefined {
        const orderData: Order = snapshot.data(options)
        const orderKey = snapshot.id
        if (isAdmin && !userId) {
          return orderData && orderData.orderId
            ? {
                orderKey: orderKey,
                orderId: orderData.orderId,
                productCount: orderData.productCount || 1,
                orderDate: orderData.dateCreated?.toDate(),
                price: Number(
                  orderData.priceWithDelivery ||
                    orderData.totalPrice ||
                    orderData.price + (orderData.deliveryPrice || 0)
                ),
                deliveryType: orderData.deliveryType || 'N/D',
                status: orderData.status,
              }
            : undefined
        } else {
          const sellerOrder = orderData.sellerOrders?.find(
            (sellerOrder: SellerOrder) => sellerOrder.userId === userId || sellerOrder.userId === user.uid
          )
          return sellerOrder && orderData.orderId && sellerOrder.orderId
            ? {
                orderKey: orderKey,
                orderId: orderData.orderId,
                sellerOrderId: sellerOrder.orderId,
                productCount: sellerOrder.productCount || 1,
                orderDate: orderData.dateCreated?.toDate(),
                price: Number((sellerOrder.totalPrice || sellerOrder.price) + (sellerOrder.deliveryPrice || 0)),
                deliveryType: orderData.deliveryType || 'N/D',
                status: sellerOrder.status,
              }
            : undefined
        }
      },
    }

    async function fetchData(converter: firebase.firestore.FirestoreDataConverter<OrderOrdersTable | undefined>) {
      try {
        const docRef = db.collection('orders')
        const querySnapshot = await docRef.withConverter(converter).get()
        const orders: OrderOrdersTable[] = []
        querySnapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<OrderOrdersTable | undefined>) => {
          const order = doc.data()
          if (order) {
            orders.push(order)
          }
        })
        setOrders(orders)
      } catch (error) {
        dispatch(addNotification('error', t('fetch_error')))
        console.error('Orders are not fetched', error)
      }
    }

    fetchData(orderConverter)
  }, [db, dispatch, t, user, isAdmin, userId])

  return <SimpleTable columns={columns} data={data} initialState={initialState} />
}
