import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useAuth } from 'hoc/FirebaseProvider'
import { useDispatch, useSelector } from 'react-redux'
import { TranslatedText, LoadingBar, Logo } from 'components/atoms'
import { isActionLoading, setLoading } from 'store/loading'
import { createLoginActionTypes } from 'store/user/constants'
import { Notification } from 'components/atoms/Notification'
import { LoginForm } from 'components/organisms/LoginForm/LoginForm'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '../../templates/CardLayout'
import { ContainerLayout } from 'components/templates'
import { Divider } from 'components/atoms/Divider'
import { Link } from 'react-router-dom'
import { FirebaseErrorResponse } from 'components/util/interfaces'

interface LoginPageProps {
  className?: string
  children?: React.ReactNode
}

const TITLE_CLASSES = 'block mt-10 not-italic font-medium text-xl text-center leading-6 pb-5'
const TRANSLATED_TEXT_STYLE = 'h-16 flex items-center justify-center text-gray-900 text-sm cursor-pointer'
const CARD_STYLE = 'max-w-md pt-10'
export const LoginPage = (props: LoginPageProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CARD_STYLE, classes.component, className)

  const auth = useAuth()
  const [authError, setAuthError] = useState('')
  const dispatch = useDispatch()
  const isLoading = useSelector(isActionLoading(createLoginActionTypes.REQUEST))
  const { t } = useTranslation(['login'])

  const onSubmit = (values: any) => {
    dispatch(setLoading(createLoginActionTypes.REQUEST))
    auth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(result => {
        dispatch(setLoading(createLoginActionTypes.REQUEST, false))
        setAuthError('')
      })
      .catch((error: FirebaseErrorResponse) => {
        dispatch(setLoading(createLoginActionTypes.REQUEST, false))
        setAuthError(error.code)
      })
  }

  return (
    <React.Fragment>
      <ContainerLayout className={'flex-col'}>
        <Logo />
        <CardLayout className={merged}>
          <h2 className={cx(TITLE_CLASSES, classes.title)}>
            <TranslatedText collection="login" id="title" />
          </h2>
          {authError && !isLoading && <Notification type={'error'} text={t(authError)} />}
          <LoginForm handleSubmit={onSubmit} />
          {isLoading && <LoadingBar className={'bg-gray-900'} />}
          {!isLoading && <Divider></Divider>}
          <Link to="/forgot-password">
            <TranslatedText className={TRANSLATED_TEXT_STYLE} collection="login" id="forgetLink" />
          </Link>
          <Link to="/register">
            <TranslatedText className={TRANSLATED_TEXT_STYLE} collection="login" id="registerLink" />
          </Link>
        </CardLayout>
      </ContainerLayout>
    </React.Fragment>
  )
}

const styles = (theme: any) => ({
  component: css``,
  title: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
})
