import React from 'react'
import { Pagination } from './Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage, getPagination } from 'store/pagination'
import { AppState } from 'store/reducers'

interface PaginationContainerProps {
  className?: string
  withBorders?: boolean
  tableId: string
}

export const PaginationContainer = (props: PaginationContainerProps) => {
  const { className, withBorders = false, tableId = 'table' + Math.random() } = props
  const dispacher = useDispatch()
  const pagination = useSelector((state: AppState) => getPagination(state, tableId))

  const handlePageChange = (id: number) => {
    dispacher(setCurrentPage(tableId, id))
  }

  // Hide pagination if page size is 0
  // which means there is no need for pagination
  return (
    <>
      {pagination && pagination.visible && (
        <Pagination
          className={className}
          initialPage={pagination.initialPage}
          pageCount={pagination.totalPages}
          withBorders={withBorders}
          onPageChange={handlePageChange}
        />
      )}
    </>
  )
}
