import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface ContainerLayoutProps {
  className?: string
  children?: React.ReactNode
}

const CONTAINER_STYLE = 'min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'

export const ContainerLayout = (props: ContainerLayoutProps) => {
  const { className, children } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CONTAINER_STYLE, classes.component, className)

  return <div className={merged}>{children}</div>
}

const styles = (theme: any) => ({
  component: css``,
})
