import React, { useCallback, useState, useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { CreatePasswordForm } from './CreatePasswordForm'
import { ContainerLayout, CardLayout } from 'components/templates'
import { TranslatedText, Notification } from 'components/atoms'
import { useDispatch } from 'react-redux'
import { setLoading } from 'store/loading'
import { useAuth } from 'hoc/FirebaseProvider'
import { Redirect, useLocation } from 'react-router-dom'
import { config } from '../../../config'

interface CreatePasswordFormContainerProps {
  className?: string
}

const FORGOT_PASS_CARD = 'overflow-hidden box-border max-w-md pt-10'
const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3'

export const CreatePasswordFormContainer = (props: CreatePasswordFormContainerProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const dispatch = useDispatch()
  const auth = useAuth()

  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState<string>()

  const location = useLocation()
  useEffect(() => {
    const path = location.search.split('email=')
    setEmail(path.length > 1 ? path[1].split('&')[0] : '')
  }, [location.search])

  const handleSubmit = useCallback(
    (values: any) => {
      setErrorMessage('')
      dispatch(setLoading('CREATE_PASSWORD_FORM_REQUEST', true))
      const url = `${config.APP_URL}${location.pathname}${location.search}`
      if (email) {
        auth
          .signInWithEmailLink(email, url)
          .then(result => {
            dispatch(setLoading('CREATE_PASSWORD_FORM_REQUEST', false))
            result.user?.updatePassword(values.password)
          })
          .catch(error => {
            setErrorMessage(error.code)
            dispatch(setLoading('CREATE_PASSWORD_FORM_REQUEST', false))
          })
      }
    },
    [auth, dispatch, email, location.pathname, location.search]
  )
  return (
    <ContainerLayout className={merged}>
      {email && (
        <CardLayout className={FORGOT_PASS_CARD}>
          <h2>
            <TranslatedText className={cx(TITLE_CLASSES)} collection="createPassForm" id="title" />
          </h2>
          <h4 className={cx(DESC_CLASSES)}>
            <TranslatedText collection="createPassForm" id="desc" />
          </h4>
          {errorMessage && (
            <Notification
              className={'w-full'}
              type={'error'}
              text={<TranslatedText collection={'createPassForm'} id={errorMessage} />}
            />
          )}
          <CreatePasswordForm email={email} handleSubmit={handleSubmit} />
        </CardLayout>
      )}
      {email === '' && <Redirect to="/login" />}
    </ContainerLayout>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
