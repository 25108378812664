import React, { useCallback, useEffect } from 'react'
import DatePicker from 'react-date-picker'
import { css, cx } from 'emotion'
import { InputLabel, TranslatedText } from 'components/atoms'
import { ReactComponent as CalendarIcon } from '../../../resources/images/icons/calendar.svg'
import { ReactComponent as ClearIcon } from '../../../resources/images/icons/close.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getDateFieldOrDefault, setDateFilterField } from 'store/filtering'
import { debounce } from 'lodash'
import { AppState } from '../../../store/reducers'

interface MonthPickerFilterContainerProps {
  className?: string
  maxMonthDelta?: number
  useDefaultDate?: boolean
  id: string
}

const CONTAINER_CLASSES = 'w-64'
const LABEL_CLASSES = 'block pb-1'

export const MonthPickerFilterContainer = (props: MonthPickerFilterContainerProps) => {
  const { className, id, maxMonthDelta = -1, useDefaultDate = true } = props

  const classes = styles()
  const merged = cx(classes.component, className, CONTAINER_CLASSES)
  const today = new Date()
  const maxMonth = new Date(today.getFullYear(), today.getMonth() + maxMonthDelta, 1)
  const date = useSelector((state: AppState) => getDateFieldOrDefault(state, id, useDefaultDate ? maxMonth : undefined))
  const dispatcher = useDispatch()

  const onChange = (date: any) => {
    updateSelect(date)
  }

  const updateSelect = useCallback(
    debounce((data: any) => {
      dispatcher(setDateFilterField(id, data))
    }, 100),
    []
  )

  useEffect(() => {
    updateSelect(date)
  }, [date, updateSelect])

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES}>
        <TranslatedText collection="balancePage" id="dateLabel" />
      </InputLabel>
      <DatePicker
        className={cx(classes.datePicker, 'w-full')}
        onChange={onChange}
        value={date}
        locale="lt-LT"
        maxDetail="year"
        minDetail="year"
        maxDate={maxMonth}
        clearIcon={useDefaultDate ? null : <ClearIcon className={classes.iconSmall} />}
        calendarIcon={<CalendarIcon className={classes.icon} />}
      />
    </div>
  )
}

const styles = () => ({
  component: css`
    display: block;
  `,
  datePicker: css`
    .react-date-picker__wrapper {
      background-color: hsl(0, 0%, 100%);
      border-color: #e2e8f0;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      padding: 4.5px 5px;
      .react-date-picker__calendar-button {
        order: -1;
      }
    }
    ,
    .react-calendar__tile,
    .react-calendar__year-view__months__month {
      text-transform: capitalize;
    }
    ,
    .react-date-picker__inputGroup__input,
    .react-date-picker__inputGroup__month {
      &:focus {
        outline: none;
      }
    }
    ,
    .react-calendar__tile--now {
      background: none;
      &:enabled:hover {
        background-color: #e6e6e6;
      }
    }
    .react-calendar__tile--active {
      background: #006edc;
    }
  `,
  icon: css`
    height: 19px;
    width: 19px;
  `,
  iconSmall: css`
    height: 13px;
    width: 13px;
  `,
})
