import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface PriceWithDiscountProps {
  className?: string
  price: number
  priceSuffix: string
  priceWithDiscount?: number | undefined
}

const STYLES = 'flex flex-col'

export const PriceWithDiscount = (props: PriceWithDiscountProps) => {
  const { className, price, priceSuffix, priceWithDiscount } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(STYLES, classes.component, className)

  return (
    <div className={merged}>
      <div
        className={cx(
          'flex-auto text-sm leading-4',
          {
            'line-through text-xs text-gray-300': priceWithDiscount !== undefined,
          },
          {
            'text-gray-700': !priceWithDiscount,
          }
        )}>
        {price.toFixed(2)} {priceSuffix}
      </div>
      <div className={cx('text-gray-700 flex-initial text-sm leading-4', { hidden: !priceWithDiscount })}>
        {priceWithDiscount?.toFixed(2)} {priceSuffix}
      </div>
    </div>
  )
}

const styles = (priceWithDiscount: string) => ({
  component: css``,
})
