import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { css, cx } from 'emotion'

import { ListLayout } from 'components/templates'
import { isOrderCanceled, isOrderOrders } from '../OrderTypeFilter'
import { OrderOrdersFilterContainer, OrderOrdersListContainer, OrderOrdersStatsContainer } from '../OrderOrders'
import { OrderProductsFilterContainer, OrderProductsListContainer } from '../OrderProducts'
import { PagerFilter } from '../PagerFilter'
import { OrderCanceledListContainer } from '../OrderOrders/OrderCanceledListContainer'

interface OrdersListProps {
  className?: string
  userId?: string
}

const TABLE_ID = 'OrdersTable'

export const OrderList = (props: OrdersListProps) => {
  const { className, userId } = props
  const classes = styles()
  const merged = cx('w-full', classes.component, className)
  const location = useLocation()
  const orderType: string = new URLSearchParams(location.search).get('type') || 'sold'

  const filter = useMemo(() => {
    return isOrderOrders(orderType) || isOrderCanceled(orderType) ? (
      <OrderOrdersFilterContainer id={TABLE_ID} orderType={orderType} />
    ) : (
      <OrderProductsFilterContainer id={TABLE_ID} orderType={orderType} />
    )
  }, [orderType])

  const list = useMemo(() => {
    return isOrderOrders(orderType) ? (
      <OrderOrdersListContainer tableId={TABLE_ID} orderType={orderType} userId={userId} />
    ) : isOrderCanceled(orderType) ? (
      <OrderCanceledListContainer tableId={TABLE_ID} orderType={orderType} userId={userId} />
    ) : (
      <OrderProductsListContainer tableId={TABLE_ID} orderType={orderType} userId={userId} />
    )
  }, [userId, orderType])

  const pagerFilter = useMemo(() => {
    return <PagerFilter id={TABLE_ID} />
  }, [])

  const stats = useMemo(() => {
    return <OrderOrdersStatsContainer tableId={TABLE_ID} userId={userId} />
  }, [userId])

  return (
    <ListLayout
      className={merged}
      tableId={TABLE_ID}
      filter={filter}
      list={list}
      pagerFilter={pagerFilter}
      stats={stats}
    />
  )
}

const styles = () => ({
  component: css``,
})
