import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface LabelProps {
  className?: string
  msg: JSX.Element | string
}

export const Label = (props: LabelProps) => {
  const { className, msg } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  return <div className={merged}>{msg}</div>
}

const styles = (theme: any) => ({
  component: css`
    display: inline-block;
  `,
})
