import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import {
  Column,
  TableState,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

interface SimpleTableProps {
  className?: string
  children?: React.ReactNode
  columns: Column[]
  data: Array<any>
  initialState?: Partial<TableState<object>>
}

export const SimpleTable = (props: SimpleTableProps) => {
  const { className, columns, data, initialState } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx('block', classes.component, className)

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect
  )

  return (
    <div className={merged}>
      <table className="min-w-full w-full" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex justify-start items-center"
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, index: number) => {
            prepareRow(row)
            return (
              <tr
                className={cx({ 'bg-white': index % 2 === 0 }, { 'bg-gray-50': index % 2 !== 0 })}
                {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word"
                      {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    overflow-x: auto;
    overflow-y: hidden;
    table {
      th,
      td {
        width: 1%;
      }
    }
  `,
})
