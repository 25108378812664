import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { SideNavigationLayout, CommonPageLayout } from 'components/templates'

interface FaqPageProps {
  className?: string
}

export const FaqPage = (props: FaqPageProps) => {
  const { className } = props
  const { t } = useTranslation(['faqPage'])

  const questionNumber: number = +t('questions')
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  var rows = []
  for (var i = 1; i <= questionNumber; i++) {
    rows.push(<div className="text-xl font-bold mt-5">{t(`question${i}`)}</div>)
    rows.push(t(`answer${i}`))
  }
  return (
    <div>
      {
        <SideNavigationLayout className={merged}>
          <CommonPageLayout pageTitle={t('title')}>
            <div className="w-full bg-white rounded-sm shadow-lg py-8 px-4 sm:px-6 lg:px-8">
              <img src="https://popup.lt/app/uploads/2020/11/Pop_Up_Logotipas_Su_Remeliu.png" alt="" />
              {rows}
            </div>
          </CommonPageLayout>
        </SideNavigationLayout>
      }
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
