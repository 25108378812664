import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import 'react-notifications-component/dist/theme.css'

interface CommonPageLayoutProps {
  className?: string
  children?: React.ReactNode
  pageTitle: string
}

const MAIN_CLASSES = 'pt-2 pb-6 md:py-6'

export const CommonPageLayout = (props: CommonPageLayoutProps) => {
  const { className, children, pageTitle } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(MAIN_CLASSES, classes.component, className, 'app-container')

  return (
    <div className={merged}>
      <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">{pageTitle}</h1>
      </div>
      <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">{children}</div>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
