import React, { useState, useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { PortfolioPanel } from 'components/molecules'
import { useFirestore, useAuth } from 'hoc/FirebaseProvider'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentUser } from 'store/user'
import { addNotification } from 'store/notification'
import { useTranslation } from 'react-i18next'

interface PortfolioPanelContainerProps {
  className?: string
  children?: React.ReactNode
  userId: string
}

export const PortfolioPanelContainer = (props: PortfolioPanelContainerProps) => {
  const { className, children, userId } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const [user, setUser] = useState<any>({})
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const currentUser = useSelector(getCurrentUser)
  const auth = useAuth()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const db = useFirestore()
  const [photoUrl, setPhotoUrl] = useState<string>()
  if (user?.profilePic && !photoUrl) {
    setPhotoUrl(user.profilePic)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const docRef = db.collection('users').doc(userId)
        const result = await docRef.get()
        const data = result.data() as any

        setUser(data)
      } catch (error) {
        dispatch(addNotification('error', t('fetch_error')))
        console.error('User details are not fetched', error)
      }
    }

    if (userId && auth.currentUser) fetchData()
  }, [db, userId, auth.currentUser, dispatch, t])

  useEffect(() => {
    setIsAdmin(currentUser.role === 'admin')
  }, [currentUser.role])

  return (
    <PortfolioPanel
      className={merged}
      isCurrentUserAdmin={isAdmin}
      isDisabled={user?.disabled}
      userId={userId}
      name={user?.brandName}
      address={
        user &&
        user.senderAddressField1 &&
        `${user.senderAddressField1} ${user.senderAddressField2}, ${user.senderAddressCity}`
      }
      photoUrl={photoUrl}>
      {children}
    </PortfolioPanel>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: flex;
  `,
})
