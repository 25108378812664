import React, { useEffect, useState, useRef } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { v4 as uuidv4 } from 'uuid'

import { AddProductFormContainer } from 'components/templates/AddProductFormContainer'
import { useProductId } from 'components/util/useProductId'
import { useFirestore, useStorage } from 'hoc/FirebaseProvider'
import { some, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'store/loading'
import { useHistory } from 'react-router-dom'
import { addNotification } from 'store/notification'
import { useTranslation } from 'react-i18next'
import { Product } from 'models/Product'
import { Category } from 'models/Category'
import { ImageUpload } from '../ImageUpload'
import { IOptions } from '../AddProductForm'
import { IProductImage, IOption } from 'components/util/interfaces'
import { getCurrentUser } from 'store/user'

interface EditProductWrapperContainerProps {
  className?: string
}

interface EditProductWrapperContainerState {
  duplicateProductId: string
}

const INITIAL_VALUES = {
  id: '',
  name: '',
  desc: '',
  brand: '',
  userId: '',
  material: '',
  categoryIds: [] as Category[],
  categoryNames: '',
  color: '',
  price: '',
  discount: '',
  items: [
    {
      size: { label: '', value: '' },
      totalAmount: 0,
      lowStock: 1,
    } as any,
  ],
  created: null,
  eco: false,
  virtual: false,
  shippingLpTerminal: true,
  shippingLpCourier: true,
  images: [],
}
const MAX_SIZE = 2097152 //2 MB
const MAX_IMAGES = 5

const binTypes: Map<string, string> = new Map([
  ['image/jpeg', 'jpg'],
  ['image/png', 'png'],
])

const IMAGES_ACCEPT_TYPES = 'image/jpeg, image/png'

const ITEM_STYLE = 'flex flex-col relative xl:absolute '

export const EditProductWrapperContainer = (props: EditProductWrapperContainerProps) => {
  const { className } = props
  const db = useFirestore()
  const storageRef = useStorage()
  const history = useHistory()
  const state = history.location.state as EditProductWrapperContainerState
  const { t } = useTranslation(['newProductForm', 'globalEvents'])
  const dispatch = useDispatch()

  //LIST OF ALL CATEGORIES
  const [categories, setCategories] = useState<Category[]>([])
  //LOWEST LEVEL CATEGORIES THAT WERE ACTUALY SELECTED BY USER
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
  const [uid, setUid] = useState<string>()

  const [removingImages, setRemovingImages] = useState<any>([])
  const [blobImages, setBlobImages] = useState<any>([])
  const [images, setImages] = useState<any>([])
  const [allImageOrder, setAllImageOrder] = useState<any>([])

  const [storageChildRef, setStorageChildRef] = useState<any>(null)
  const [product, setProduct] = useState<any>(INITIAL_VALUES)
  const [productLoaded, setProductLoaded] = useState<boolean>(false)

  const url = useProductId()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const id = useRef<any>(null)

  const authUser = useSelector(getCurrentUser)

  async function saveBlobedImages(images: IProductImage[]) {
    const listOfPromise: any = images.map(async (image: IProductImage) => {
      return new Promise(async resolve => {
        try {
          const blob = await fetch(image.url).then(r => r.blob())
          const ext = binTypes.get(blob.type) || 'bin'
          resolve(storageChildRef.child(`${image.id}.${ext}`).put(blob))
        } catch (error) {
          showErrorAndStopLoader(error)
        }
      })
    })

    return Promise.all(listOfPromise)
  }

  async function getFullURLs(response: any): Promise<any> {
    const listOfPromise: any = response.map(async (image: any) => {
      return new Promise(async resolve => {
        try {
          const url = await image.ref.getDownloadURL()
          resolve({ url, id: image.metadata.name, thumbnail: '' })
        } catch (error) {
          showErrorAndStopLoader(error)
        }
      })
    })

    return Promise.all(listOfPromise)
  }

  async function deleteImagesFromStorage(): Promise<any> {
    const listOfPromise: any = removingImages.map(async (image: any) => {
      return new Promise(async resolve => {
        try {
          const result = await storageChildRef.child(image.id).delete()
          resolve({ result })
        } catch (error) {
          showErrorAndStopLoader(error)
        }
      })
    })

    return Promise.all(listOfPromise)
  }

  function reorderImages(images: any, key: string) {
    images.sort((a: any, b: any) => {
      const A = a[key]
      const B = b[key]
      return allImageOrder.indexOf(A) > allImageOrder.indexOf(B) ? 1 : -1
    })
    return images
  }

  async function handleSubmit(product: Product) {
    dispatch(setLoading('SAVE_PRODUCT', true))
    if (url === 'new-coupon') {
      const catIds: string[] = []
      categories.forEach(cat => {
        cat.options.forEach((s: Category) => {
          catIds.push(s.value)
        })
      })
      product.categoryIds = catIds
    }
    try {
      await deleteImagesFromStorage()
      saveBlobedImages(blobImages).then(async response => {
        getFullURLs(response).then(async (urls: IProductImage[]) => {
          product.images = reorderImages([...product.images, ...urls], 'id')

          await db.collection('products').doc(id.current).set(product, { merge: true })
          dispatch(addNotification('success', t('success')))
          clearState()
          if (url !== id.current && !product.status?.published) {
            history.go(0)
          } else if (product.status?.published) {
            history.push('/product')
          }
          dispatch(setLoading('SAVE_PRODUCT', false))
        })
      })
    } catch (error) {
      showErrorAndStopLoader(error)
    }
  }

  function showErrorAndStopLoader(error: any) {
    dispatch(setLoading('SAVE_PRODUCT', false))
    dispatch(addNotification('error', t(`globalEvents:${error.code}`)))
  }

  function onDrop(accepted: any) {
    const list: any = []

    if (!hasMaxImageCount(accepted.length)) {
      accepted.forEach((img: any) => {
        list.push({
          id: uuidv4(),
          url: window.URL.createObjectURL(img),
          thumbnail: '',
        } as IProductImage)
      })

      setBlobImages((state: any) => [...state, ...list])
      setImages((state: any) => [...state, ...list])
      setAllImageOrder((state: any) => [...state, ...list.map((item: IProductImage) => item.id)])
    } else {
      dispatch(
        addNotification(
          'error',
          t(`globalEvents:maxImage`, {
            maxCount: MAX_IMAGES,
            currentCount: images.length,
            addCount: accepted.length,
          })
        )
      )
    }
  }

  function hasMaxImageCount(newImageCount: number) {
    const currentImageCount = images.length
    return newImageCount + currentImageCount > MAX_IMAGES
  }

  function confirmDelete(image: IProductImage) {
    if (image.url.indexOf('blob') !== -1) {
      removeBlobImages(image)
    } else {
      removeImagesFromProduct(image)
    }
  }

  function onImageReorder(images: IProductImage[]) {
    setAllImageOrder(images.map(item => item.id))
  }

  function removeBlobImages(image: IProductImage) {
    const index = blobImages.findIndex((item: IProductImage) => item.id === image.id)
    setBlobImages([...blobImages.slice(0, index), ...blobImages.slice(index + 1)])
    removeFromImagesArr(image)
    removeFromImageOrderArr(image.id)
  }

  function removeFromImagesArr(image: IProductImage) {
    const index = images.findIndex((item: IProductImage) => item.id === image.id)
    setImages([...images.slice(0, index), ...images.slice(index + 1)])
  }

  function updateProductImages(image: IProductImage) {
    const index = product.images.findIndex((item: IProductImage) => item.id === image.id)
    setProduct({
      ...product,
      images: [...product.images.slice(0, index), ...product.images.slice(index + 1)],
    })
  }

  function removeImagesFromProduct(image: IProductImage) {
    setRemovingImages((state: any) => [...state, image])
    updateProductImages(image)
    removeFromImagesArr(image)
    removeFromImageOrderArr(image.id)
  }

  function removeFromImageOrderArr(id: string) {
    const index = allImageOrder.indexOf(id)
    setAllImageOrder([...allImageOrder.slice(0, index), ...allImageOrder.slice(index + 1)])
  }

  function clearState() {
    setBlobImages([])
    setRemovingImages([])
  }

  useEffect(() => {
    const fetchData = async () => {
      const docRef = db.collection('common').doc('adminInfo')
      const doc = await docRef.get()
      const data = doc.data()
      if (data && url === 'new-coupon') {
        setProduct({
          ...product,
          virtual: true,
          shippingLpCourier: false,
          shippingLpTerminal: false,
          categoryIds: categories,
        })
      }
    }
    fetchData()
  }, [categories, db, product, url])

  useEffect(() => {
    const randomHash = uuidv4()
    const productId = url !== 'new' && url !== 'new-coupon' ? url : randomHash

    if ((url === 'new' && authUser.role === 'seller') || url === 'new-coupon') {
      setUid(authUser.uid)
    }
    id.current = productId
  }, [authUser.role, authUser.uid, id, url])

  useEffect(() => {
    if (uid) {
      const formatedPath = `${uid}/products/${id.current}/`
      setStorageChildRef(storageRef.child(formatedPath as any))
    }
  }, [uid, storageRef])

  //FETCH CATEGORIES
  useEffect(() => {
    const categoriesConverter = {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ) {
        const data = snapshot.data(options)
        let values = [
          {
            label: data.name,
            options: [] as IOption[],
          },
        ]
        if (data.children) {
          Object.values(data.children).forEach((child: any, index: number) => {
            if (child.children) {
              values[index + 1] = { label: '', options: [] as IOption[] } as IOptions
              values[index + 1].label = `${data.name} >> ${child.name}`
              Object.values(child.children).forEach((ch: any) => {
                const returnTimeInDays = ch.returnTimeInDays || 0
                values[index + 1].options.push(
                  new Category(ch.name, ch.id, null, [data.id, child.id], [data.name, child.name], returnTimeInDays)
                )
              })
            } else {
              const returnTimeInDays = child.returnTimeInDays || 0
              values[0].options.push(new Category(child.name, child.id, null, [data.id], [data.name], returnTimeInDays))
            }
          })
        }
        return values
      },
    }

    const fetchCategories = async () => {
      try {
        const docRef = db.collection('productCategories')
        const categoryList = await docRef.withConverter(categoriesConverter as any).get()

        const cat: Category[] = []
        categoryList.forEach(function (doc: any) {
          const data = doc.data()
          data.forEach((d: Category) => cat.push(d))
        })
        setCategories(cat)
      } catch (error) {
        console.error(error)
      }
    }
    fetchCategories()
  }, [db])

  //GET PRODUCT DATA ON EDIT PRODUCT
  useEffect(() => {
    const shouldDuplicate = url === 'new' && state && state.duplicateProductId
    function getCategories(ids: string[]): Category[] {
      const selectedCats = [] as Category[]
      const allCats = map(
        categories.filter((c: Category) => some(ids, id => some(c.options, o => o.value === id))),
        'options'
      )
      allCats.forEach(all => all.forEach((a: Category) => selectedCats.push(a)))
      return selectedCats.filter(sc => some(ids, id => sc.value === id))
    }
    function editBeforeDuplicating(doc: any) {
      doc.status = null
      doc.created = null
    }

    async function getProductData() {
      setProductLoaded(true)
      let res
      if (shouldDuplicate) {
        res = await db.collection('products').doc(state.duplicateProductId).get()
      } else {
        res = await db.collection('products').doc(id.current).get()
      }

      if (res.exists) {
        const doc = res.data() as any
        let imgs = doc.images || []
        const categories = getCategories(doc.categoryIds)
        if (shouldDuplicate) {
          editBeforeDuplicating(doc)
          imgs = []
        }

        setProduct({
          name: doc.name,
          brand: doc.brand,
          userId: doc.userId,
          desc: doc.desc,
          material: doc.material,
          categoryIds: categories,
          color: doc.color,
          price: doc.price,
          discount: doc.discount,
          items: doc.items || INITIAL_VALUES.items,
          status: doc.status,
          created: doc.created,
          eco: doc.eco || false,
          virtual: doc.virtual || false,
          shippingLpTerminal: doc.shippingLpTerminal,
          shippingLpCourier: doc.shippingLpCourier,
          images: imgs,
          returnTimeInDays: doc.returnTimeInDays || 0,
        })

        setUid(doc.userId)
        setImages(imgs)
        setSelectedCategories(categories)
        // SET DEFAULT order
        setAllImageOrder(imgs.map((item: any) => item.id))
      }
    }

    if (!productLoaded && (url !== 'new' || shouldDuplicate) && url !== 'new-coupon' && categories.length > 0) {
      getProductData()
    }
  }, [categories, db, productLoaded, url, state])

  return (
    <div className={merged}>
      <ImageUpload
        className={ITEM_STYLE}
        accept={IMAGES_ACCEPT_TYPES}
        maxSize={MAX_SIZE}
        maxImageCount={MAX_IMAGES}
        onDrop={onDrop}
        onDelete={confirmDelete}
        onImageReorder={onImageReorder}
        images={images}
      />
      <AddProductFormContainer
        className="w-full"
        productId={id.current}
        url={url}
        product={product}
        categories={categories}
        selectedCategories={selectedCategories}
        onCategoriesSelect={setSelectedCategories}
        onSubmit={handleSubmit}></AddProductFormContainer>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
