import React, { useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { css, cx } from 'emotion'

import { TranslatedText } from 'components/atoms'
import { ActionsLayout } from 'components/molecules'
import { IOption } from 'components/util'
import { setModal } from 'store/modal'
import { CHANGE_ORDER_STATUS, CHANGE_SELLER_ORDER_STATUS } from 'store/order/constants'
import { useLocation } from 'react-router-dom'

interface ActionMenuProps {
  orderKey: string
  sellerOrderId?: string
  currentStatus?: string
  className?: string
  push: typeof push
}

const TRANSLATION_KEY = 'ordersPage'

export const OrderOrdersActionMenuComponent = ({
  orderKey,
  sellerOrderId,
  currentStatus,
  className,
  push,
}: ActionMenuProps) => {
  const { t } = useTranslation([TRANSLATION_KEY])
  const classes = styles()
  const merged = cx(classes.component, className, 'cursor-pointer', 'hover:bg-gray-200', 'p-4')
  const location = useLocation()
  const isCanceled = 'canceled' === new URLSearchParams(location.search).get('type')

  const statusOptions: IOption[] = [
    { label: <TranslatedText collection="status" id="sold" />, value: 'sold' },
    { label: <TranslatedText collection="status" id="sent" />, value: 'sent' },
  ]

  const dispatch = useDispatch()

  const openPage = (orderKey: string, sellerOrder?: string) => {
    return sellerOrder ? push(`/order/${orderKey}/${sellerOrder}/details`) : push(`/order/${orderKey}/details`)
  }

  const openDialog = useCallback(
    (orderId: string, sellerOrderId?: string, currentStatus?: string) => {
      dispatch(
        setModal('ChangeStatusDialog', {
          collection: TRANSLATION_KEY,
          options: statusOptions,
          defaultValue: statusOptions.find(o => o.value === currentStatus),
          action: {
            type: sellerOrderId ? CHANGE_SELLER_ORDER_STATUS : CHANGE_ORDER_STATUS,
            orderKey,
            sellerOrderId,
            refreshPage: true,
          },
        })
      )
    },
    [dispatch, orderKey, statusOptions]
  )

  return (
    <ActionsLayout isTable>
      {!isCanceled && (
        <div className={merged} onClick={() => openDialog(orderKey, sellerOrderId, currentStatus)}>
          {t('changeStatus')}
        </div>
      )}
      <div className={merged} onClick={() => openPage(orderKey, sellerOrderId)}>
        {t('orderInfo')}
      </div>
    </ActionsLayout>
  )
}

const styles = () => ({
  component: css``,
})

export const OrderOrdersActionMenu = connect(undefined, { push })(OrderOrdersActionMenuComponent)
