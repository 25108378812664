import React, { useMemo, useState, useEffect } from 'react'
import { Table } from 'components/molecules'
import { connect, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { get, some } from 'lodash'

import { AppState } from 'store/reducers'
import { getPagination } from 'store/pagination'
import { getFilter, getDateField } from 'store/filtering'
import { PAGE_SIZE } from 'components/organisms/PagerFilter'
import { getCurrentUser } from 'store/user'
import { useFirestore } from 'hoc/FirebaseProvider'
import { Balance } from 'components/util/interfaces'
import { useBalancesColumn } from 'components/util/useBalanceColumn'

interface BalanceListContainerProps {
  tableId: string
  push: typeof push
}

export const BalanceListComponent = (props: BalanceListContainerProps) => {
  const { tableId, push } = props
  const db = useFirestore()
  const [sellersBalances, setSellersBalances] = useState<Balance[]>([])
  const data = useMemo(() => sellersBalances as any[], [sellersBalances])
  const pagination = useSelector((state: AppState) => getPagination(state, tableId))
  const dataFilter = useSelector((state: AppState) => getFilter(state, tableId))
  const filterByColumnID = new Map<string, string>()
  filterByColumnID.set('brands', 'brandName')
  const currentDate = useSelector((state: AppState) => getDateField(state, tableId))

  const user = useSelector(getCurrentUser)
  const columns = useBalancesColumn({ user, push })

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'status', desc: false }],
      pageIndex: get(pagination, 'initialPage', 0),
      pageSize: get(pagination, 'pageSize', PAGE_SIZE),
      hiddenColumns: ['docId', 'date'],
    }),
    [pagination]
  )

  useEffect(() => {
    const fetchSellersBalance = async () => {
      const selectedYear = (currentDate as Date).getFullYear()
      const selectedMonth = (currentDate as Date).getMonth()
      const balances: Balance[] = []
      let snapshotStats
      if (user.role === 'admin') {
        snapshotStats = await db
          .collection('balances')
          .where('year', '==', selectedYear)
          .where('month', '==', selectedMonth)
          .get()
        snapshotStats.forEach(doc => {
          const data = doc.data() as Balance
          if (data) {
            balances.push({ ...data, docId: doc.id })
          }
        })
        const sellersSnapshot = await db.collection('users').get()
        const existingSellers = [] as string[]
        sellersSnapshot.forEach(doc => {
          if (doc) {
            existingSellers.push(doc.id)
          }
        })
        balances.forEach((balance: Balance) => {
          if (some(existingSellers, e => e === balance.userId)) {
            balance.userExists = true
          }
        })
      }
      if (user.role === 'seller') {
        snapshotStats = await db
          .collection('balances')
          .where('userId', '==', user.uid)
          .where('year', '==', selectedYear)
          .where('month', '==', selectedMonth)
          .get()

        snapshotStats.forEach(doc => {
          const data = doc.data() as Balance
          if (data) {
            balances.push({ ...data, userExists: true })
          }
        })
      }
      setSellersBalances(balances)
    }
    if (currentDate) {
      fetchSellersBalance()
    }
  }, [db, user, currentDate])

  return (
    <Table
      tableId={tableId}
      columns={columns}
      data={data}
      paginationOptions={pagination}
      filterOptions={dataFilter}
      initialState={initialState}
      filterMapByColumnId={filterByColumnID}
    />
  )
}

export const BalanceListContainer = connect(undefined, { push })(BalanceListComponent)
