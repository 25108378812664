import * as actions from './actions'
import * as constants from './constants'
import { UserActions } from './actions'

export interface UserState {
  email: string
  name: string
  role: string
  uid: string
  brandName?: string
  vacation?: boolean
  [key: string]: any
}

type State = UserState

export const initialState: UserState = {
  email: '',
  name: '',
  role: '',
  uid: '',
  vacation: false,
  brandName: undefined,
}

const setCurrentUser = (state: State, action: actions.SetCurrentUserAction) => ({
  ...state,
  ...action.payload,
})

const strategies = {
  [constants.SET_CURRENT_USER]: setCurrentUser,
  __default__: (state: State) => state,
}

export const userReducer = (state = initialState, action: UserActions): State => {
  const transformer = strategies[action.type] ?? strategies.__default__
  return transformer(state, action as any)
}
