import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

import { ReactComponent as Lock } from '../../../resources/images/icons/lock.svg'
import { TranslatedText, Label, Input, Icon } from '../../atoms'

interface ChangePasswordFormProps {
  className?: string
  children?: React.ReactNode
  formik: any
}

const TRANSLATION_COLLECTION_KEY = 'changePassForm'

const INPUT_CLASSES =
  'pl-8 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ICON_CLASSES = 'absolute top-10 left-2 z-10 text-gray-300'

const passwordIconWithStyles: JSX.Element = (
  <Lock
    className={css`
      path {
        fill: currentColor;
      }
    `}
  />
)

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { className, formik } = props
  const { t } = useTranslation([TRANSLATION_COLLECTION_KEY])

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  return (
    <div className={merged}>
      <div className={cx(classes.group, 'relative')}>
        <Label className={classes.label} msg={t('passwordPlaceholder')} />
        <Input
          type="password"
          name="password"
          className={cx(INPUT_CLASSES, classes.input)}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.password}
          placeholder={t('passwordPlaceholder')}
          autoComplete="current-password"
        />
        <Icon className={ICON_CLASSES} svgIcon={passwordIconWithStyles} height={12} width={12} />
        {formik.errors.password && formik.touched.password ? (
          <Label
            className={cx(classes.errorMsg, 'text-xs text-red-500 text-center')}
            msg={
              <TranslatedText
                className={classes.errMSgTranslation}
                collection={TRANSLATION_COLLECTION_KEY}
                id={formik.errors.password}
              />
            }
          />
        ) : undefined}
      </div>
      <div className={cx(classes.group, 'relative')}>
        <Label className={classes.label} msg={t('newPasswordPlaceholder')} />
        <Input
          type="password"
          name="newPassword"
          className={cx(INPUT_CLASSES, classes.input)}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.newPassword}
          placeholder={t('newPasswordPlaceholder')}
          autoComplete="new-password"
        />
        <Icon className={ICON_CLASSES} svgIcon={passwordIconWithStyles} height={12} width={12} />
        {formik.errors.newPassword && formik.touched.newPassword ? (
          <Label
            className={cx(classes.errorMsg, 'text-xs text-red-500 text-center')}
            msg={
              <TranslatedText
                className={classes.errMSgTranslation}
                collection={TRANSLATION_COLLECTION_KEY}
                id={formik.errors.newPassword}
              />
            }
          />
        ) : undefined}
      </div>
      <div className={cx(classes.group, 'relative')}>
        <Label className={classes.label} msg={t('passwordConfirmPlaceholder')} />
        <Input
          type="password"
          name="passwordConfirm"
          className={cx(INPUT_CLASSES, classes.input)}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.passwordConfirm}
          placeholder={t('passwordConfirmPlaceholder')}
          autoComplete="new-password"
        />
        <Icon className={ICON_CLASSES} svgIcon={passwordIconWithStyles} height={12} width={12} />
        {formik.errors.passwordConfirm && formik.touched.passwordConfirm ? (
          <Label
            className={cx(classes.errorMsg, 'text-xs text-red-500 text-center')}
            msg={
              <TranslatedText
                className={classes.errMSgTranslation}
                collection={TRANSLATION_COLLECTION_KEY}
                id={formik.errors.passwordConfirm}
              />
            }
          />
        ) : undefined}
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  input: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  group: css`
    min-height: 98px;

    &:last-child {
      padding-bottom: 1rem;
    }
  `,
  errorMsg: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  errMSgTranslation: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
  label: css`
    padding-bottom: 4px;
  `,
})
