import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ReactComponent as Lock } from '../../../resources/images/icons/lock.svg'
import { useTranslation } from 'react-i18next'
import { Label, Input, Icon, TranslatedText } from 'components/atoms'

interface CreatePasswordFormProps {
  className?: string
  children?: React.ReactNode
  email: string
  handleSubmit: (values: any) => void
}

const CreatePasswordSchema = Yup.object({
  password: Yup.string().required('required').min(6, 'errorWeakPassword'),
  passwordConfirm: Yup.string()
    .required('required')
    .oneOf([Yup.ref('password')], 'confirm'),
  termsOfService: Yup.boolean().oneOf([true], 'errorAgreement'),
})

const INPUT_CLASSES =
  'appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ICON_CLASSES = 'absolute top-9 left-2 z-10 text-gray-300'
const BUTTON_CLASSES =
  'outline-none align-baseline font-bold py-2 px-4 rounded text-sm bg-black text-white hover:text-gray-100 border border-black border-solid w-full h-10'
const BUTTON_DISABLED = 'opacity-50 cursor-not-allowed'

const TRANSLATION_COLLECTION_KEY = 'login'

export const CreatePasswordForm = (props: CreatePasswordFormProps) => {
  const { className, email, handleSubmit } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const { t } = useTranslation([TRANSLATION_COLLECTION_KEY])

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
      termsOfService: false,
    },
    validationSchema: CreatePasswordSchema,
    onSubmit: handleSubmit,
  })

  return (
    <form className={merged} onSubmit={formik.handleSubmit}>
      <div className={cx(classes.group, 'relative')}>
        <Label msg={t('email')} />
        <div className={cx(INPUT_CLASSES, 'bg-gray-200 text-opacity-50 h-9')}>{email}</div>
      </div>
      <div className={cx(classes.group, 'relative')}>
        <Label msg={t('password')} />
        <Input
          type="password"
          name="password"
          className={cx(INPUT_CLASSES, 'pl-8')}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.password}
          placeholder={t('password')}
          autoComplete="current-password"
        />
        <Icon className={ICON_CLASSES} svgIcon={<Lock className={classes.icon} />} height={12} width={12} />
        {formik.errors.password && formik.touched.password ? (
          <Label
            className={cx(classes.errorMsg, 'text-xs text-red-500 text-center')}
            msg={
              <TranslatedText
                className={classes.errMSgTranslation}
                collection={TRANSLATION_COLLECTION_KEY}
                id={formik.errors.password}
              />
            }
          />
        ) : undefined}
      </div>
      <div className={cx(classes.group, 'relative')}>
        <Label msg={t('passwordConfirm')} />
        <Input
          type="password"
          name="passwordConfirm"
          className={cx(INPUT_CLASSES, 'pl-8')}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.passwordConfirm}
          placeholder={t('passwordConfirm')}
          autoComplete="new-password"
        />
        <Icon className={ICON_CLASSES} svgIcon={<Lock className={classes.icon} />} height={12} width={12} />
        {formik.errors.passwordConfirm && formik.touched.passwordConfirm ? (
          <Label
            className={cx(classes.errorMsg, 'text-xs text-red-500 text-center')}
            msg={
              <TranslatedText
                className={classes.errMSgTranslation}
                collection={TRANSLATION_COLLECTION_KEY}
                id={formik.errors.passwordConfirm}
              />
            }
          />
        ) : undefined}
      </div>
      <div className="h-12">
        <input
          type="checkbox"
          name="termsOfService"
          checked={formik.values.termsOfService}
          onChange={formik.handleChange}
          className="mr-3"
        />
        <a href="/default-agreement" className="hover:underline">
          <TranslatedText collection={TRANSLATION_COLLECTION_KEY} id="termsOfService" />
        </a>
        {formik.errors.termsOfService && formik.touched.termsOfService ? (
          <Label
            className={cx(classes.errorMsg, 'text-xs text-red-500 text-center')}
            msg={
              <TranslatedText
                className={classes.errMSgTranslation}
                collection={TRANSLATION_COLLECTION_KEY}
                id={formik.errors.termsOfService}
              />
            }
          />
        ) : undefined}
      </div>
      <button
        disabled={!(formik.isValid && formik.dirty)}
        className={cx({ [BUTTON_DISABLED]: !(formik.isValid && formik.dirty) }, BUTTON_CLASSES)}
        type="submit">
        <TranslatedText collection={TRANSLATION_COLLECTION_KEY} id="loginBtn" />
      </button>
    </form>
  )
}

const styles = (theme: any) => ({
  component: css`
    padding: 40px 60px;
  `,
  group: css`
    min-height: 98px;

    &:last-child {
      padding-bottom: 1rem;
    }
  `,
  errorMsg: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  errMSgTranslation: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
