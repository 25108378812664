import { config } from '../config'
import { combineEpics } from 'redux-observable'
import { buildApiService } from '../services'
import { loadingEpics } from './loading/epics'
import { productEpics } from './product/epics'
import { userEpics } from './user/epics'
import { profileEpics } from './profile/epics'
import { notificationEpics } from './notification/epics'
import { modalEpics } from './modal/epics'
import { balanceEpics } from './balance/epics'
import { orderEpics } from './order/epics'
import { errorEpics } from './error/epics'

const apiServiceEpic = buildApiService({
  API_URL: config.API_URL as string,
  retryStrategyOptions: {
    excludedStatusCodes: [500],
    maxRetryAttempts: 4,
    scalingDuration: 1000,
  },
})

export default combineEpics(
  profileEpics,
  modalEpics,
  productEpics,
  userEpics,
  notificationEpics,
  balanceEpics,
  orderEpics,
  errorEpics,
  /*generated code*/
  loadingEpics,
  apiServiceEpic
)
