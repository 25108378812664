import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { get } from 'lodash'

import { SideNavigationLayout } from 'components/templates/SideNavigationLayout'
import { PortfolioPanelContainer } from 'components/templates/PortfolioPanelContainer'
import { PortfolioTabs } from 'components/templates/PortfolioTabs'
import { useSelector } from 'react-redux'
import { getUser } from 'store/user'
import { Redirect } from 'react-router-dom'

interface SellerDashboardPageProps {
  className?: string
}

export const SellerDashboardPage = (props: SellerDashboardPageProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const userId = get(props, 'match.params.id')
  const user = useSelector(getUser)
  const search = get(props, 'location.search')
  const tab: string | undefined = search && (new URLSearchParams(search).get('tab') as string)

  return (
    <>
      {user.role === 'seller' && user.disabled ? (
        <Redirect to={`/seller/${user.uid}`} />
      ) : (
        <SideNavigationLayout className={merged}>
          <PortfolioPanelContainer userId={userId}>
            <PortfolioTabs tabSelected={tab} />
          </PortfolioPanelContainer>
        </SideNavigationLayout>
      )}
    </>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
