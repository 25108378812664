import React, { useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { withModal } from 'hoc/withModal/withModal'
import { deleteModal } from 'store/modal'
import { ModalProps } from 'models'
import { TranslatedText, Button, Input, Label, Divider, LoadingBar } from 'components/atoms'
import * as data from 'resources/shipping.json'
import { isEmpty, isNaN } from 'lodash'
import { useFunctions } from 'hoc/FirebaseProvider'
import { addNotification } from 'store/notification'
import { useTranslation } from 'react-i18next'
import { FUNCTIONS_REGION } from 'config'
import { isActionLoading, setLoading } from 'store/loading'

interface ShippingFormContainerProps extends ModalProps {
  className?: string
  children?: React.ReactNode
  deliveryType?: string
  sendAbroad?: boolean
  collection?: string
  priceSuffix?: string
  orderId?: string
  sellerId?: string
}

interface IShippingCourier extends IShipping {
  weight: number
}

interface IShippingTerminal extends IShipping {
  size: string
}
interface IShipping {
  maxWeight: string
  description: string
  dimensions: string
  price: number
}

const TITLE_CLASSES = 'block not-italic font-medium text-xl leading-6 pb-5 mt-5'
const BUTTON_WRAPPER_CLASSES = 'mt-5 p-5 flex justify-center items-center'

export const ShippingFormContainerComponent = (props: ShippingFormContainerProps) => {
  const {
    className,
    deliveryType = '',
    sendLocally = true,
    collection = '',
    priceSuffix = '€',
    orderId = '',
    id,
    sellerId = '',
  } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const [selectedItem, setSelectedItem] = useState<IShippingTerminal | IShippingCourier>(
    !sendLocally
      ? data.shippingCourierAbroad
      : deliveryType === 'terminal'
      ? ({} as IShippingTerminal)
      : data.shippingCourier
  )
  const dispatch = useDispatch()
  const functions = useFunctions(FUNCTIONS_REGION)

  const isLoading = useSelector(isActionLoading('GENERATE_LABEL'))

  const { t } = useTranslation(['shipping'])

  const confirmAction = () => {
    dispatch(setLoading('GENERATE_LABEL', true))
    let item = {
      orderId: orderId,
      sellerId: sellerId,
    } as any

    deliveryType === 'terminal'
      ? (item.boxSize = (selectedItem as IShippingTerminal).size.toLowerCase())
      : (item.weight = (selectedItem as IShippingCourier).weight)
    const fn = deliveryType === 'terminal' ? 'getTerminalLabel' : 'getCourierLabel'
    functions
      .httpsCallable(fn)(item)
      .then((res: any) => {
        dispatch(setLoading('GENERATE_LABEL', false))
        if (res.data.status === 200) {
          window.location.href = res.data.data.labelUrl
          dispatch(addNotification('success', t('success')))
          dispatch(deleteModal(id))
        }
        if (res.data.error) {
          dispatch(setLoading('GENERATE_LABEL', false))
          dispatch(addNotification('error', t(res.data.error)))
        }
      })
      .catch(err => {
        dispatch(addNotification('error', t(err)))
      })
  }

  const cancelAction = () => {
    dispatch(deleteModal(id))
  }

  return (
    <div className={merged}>
      <TranslatedText className={TITLE_CLASSES} collection={collection} id="shippingObject" />
      {deliveryType === 'terminal' ? (
        <div className="flex w-full justify-around">
          {data.shippingTerminal.map((item: IShippingTerminal, index: number) => (
            <div
              className="block w-52 h-32 bg-gray-100 shadow relative"
              onClick={() => setSelectedItem(item)}
              key={index}>
              <label>
                <input type="radio" name="radio" className="absolute h-0 w-0" />
                <div className="absolute right-0 top-0 w-full h-full checkmark cursor-pointer p-2">
                  <div>
                    {item.size} - {item.maxWeight}
                  </div>
                  <div className="text-gray-500 text-xs">{item.dimensions}</div>
                  <div className="text-xs font-bold pt-5">{item.description}</div>
                </div>
              </label>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="block w-52 h-32 bg-gray-100 shadow relative">
            <label>
              <div className="absolute right-0 top-0 w-full h-full p-2">
                <div>{selectedItem.maxWeight}</div>
                <div className="text-gray-500 text-xs">{selectedItem.dimensions}</div>
                <div className="text-xs font-bold pt-5">{selectedItem.description}</div>
              </div>
            </label>
          </div>
          <Divider className="mt-10" />
          <div className="flex w-60 items-center py-5">
            <Label msg={t('weight')} className="mr-2" />
            <Input
              type="number"
              className="mr-2"
              placeholder="0.00"
              handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                const item: IShippingCourier = data.shippingCourier
                item.weight = parseFloat(event?.target?.value)
                setSelectedItem(state => ({ ...state, weight: item.weight }))
              }}
            />
            kg
          </div>
          <Divider className="mb-10" />
        </div>
      )}
      <TranslatedText className={TITLE_CLASSES} collection={collection} id="deliveryPrice" />
      <div>
        <TranslatedText collection="shipping" id={deliveryType} />: {selectedItem.price || 0} {priceSuffix}
      </div>
      {isLoading && <LoadingBar className="bg-gray-900 mt-5" />}
      <div className={BUTTON_WRAPPER_CLASSES}>
        <Button color="secondary" className="mr-2" onClick={cancelAction}>
          <TranslatedText collection={collection} id="cancelText" />
        </Button>
        <Button
          onClick={confirmAction}
          disabled={
            deliveryType === 'terminal'
              ? isEmpty(selectedItem)
              : (selectedItem as IShippingCourier).weight <= 0 || isNaN((selectedItem as IShippingCourier).weight)
          }>
          <TranslatedText collection={collection} id="continueText" />
        </Button>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
    input:checked ~ .checkmark {
      background-color: #fcde05;
    }
  `,
})

export const ShippingFormContainer = withModal(ShippingFormContainerComponent)
