import React, { useCallback, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { ContainerLayout, CardLayout } from 'components/templates'
import { TranslatedText, LoadingBar, Notification, Logo } from 'components/atoms'
import { SimpleEmailForm } from 'components/organisms'
import { useFunctions } from 'hoc/FirebaseProvider'
import { FUNCTIONS_REGION } from 'config'

interface ResendInvitationLinkPageProps {
  className?: string
  children?: React.ReactNode
}

const CARD_CLASSES = 'overflow-hidden box-border max-w-md py-10 px-9'
const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3'

export const ResendInvitationLinkPage = (props: ResendInvitationLinkPageProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CARD_CLASSES, classes.component, className)
  const functions = useFunctions(FUNCTIONS_REGION)

  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const resendActivationLink = useCallback(
    (email: string) => {
      setError('')
      setSuccess('')
      setLoading(true)
      functions
        .httpsCallable('resendSellerInvitationEmail')({ email: email })
        .then(response => {
          setLoading(false)
          if (response.data.status === 200) {
            setError('')
            setSuccess('resendSuccess')
            setLoading(false)
          } else {
            setSuccess('')
            setError(response.data.error)
            setLoading(false)
          }
        })
        .catch(error => {
          setError(error)
          setLoading(false)
        })
    },
    [functions]
  )

  return (
    <ContainerLayout className={'flex-col'}>
      <Logo />
      <CardLayout className={merged}>
        <h2 className={TITLE_CLASSES}>
          <TranslatedText collection="resendInvite" id="title" />
        </h2>
        {loading && <LoadingBar className={'bg-gray-900'} />}
        {error && <Notification type="error" text={<TranslatedText collection="globalEvents" id={error || ''} />} />}
        {success && (
          <Notification type="success" text={<TranslatedText collection="globalEvents" id={success || ''} />} />
        )}
        <h4 className={DESC_CLASSES}>
          <TranslatedText collection="resendInvite" id="desc" />
        </h4>
        <SimpleEmailForm
          collection="resendInvite"
          sendResetEvent={resendActivationLink}
          clearMessages={() => {}}
          isQueryFailed={false}
        />
      </CardLayout>
    </ContainerLayout>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
