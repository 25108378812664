import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SellerOrder } from 'models/Order'
import { Seller } from 'models'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { addNotification } from 'store/notification'
import { addError } from 'store/error'
import { setModal } from 'store/modal'
import { CHANGE_SELLER_ORDER_STATUS } from 'store/order'
import { useFirestore } from 'hoc/FirebaseProvider'
import { OrderProductsTable } from './OrderProductsTable'
import { Divider, Icon, Label, LabelBadge, SummaryLine, TranslatedText, Button } from 'components/atoms'
import { ActionsLayout } from 'components/molecules'
import { IOption } from 'components/util'
import { ReactComponent as ArrowUp } from '../../../resources/images/icons/arrow_up.svg'
import { ReactComponent as ArrowDown } from '../../../resources/images/icons/arrow_down.svg'
import { ReactComponent as Info } from '../../../resources/images/icons/info.svg'
import { ReactComponent as Download } from '../../../resources/images/icons/download.svg'
import { getCurrentUser } from 'store/user'

export interface SellerOrderProductsProps {
  className?: string
  sellerOrder: SellerOrder
  orderKey: string
}

const TRANSLATION_BLOCK = ['orderDetailsPage', 'status']

export const SellerOrderProducts = (props: SellerOrderProductsProps) => {
  const { className, sellerOrder, orderKey } = props
  const classes = styles()
  const merged = cx('flex flex-col', classes.container, className)
  const { t } = useTranslation(TRANSLATION_BLOCK)

  const db = useFirestore()
  const dispatch = useDispatch()

  const user = useSelector(getCurrentUser)
  const isAdmin = useMemo(() => {
    return user.role === 'admin'
  }, [user])

  const [seller, setSeller] = useState<Seller>()

  const statusOptions: IOption[] = [
    { label: <TranslatedText collection="status" id="sold" />, value: 'sold' },
    { label: <TranslatedText collection="status" id="sent" />, value: 'sent' },
  ]
  if (isAdmin) {
    statusOptions.push({ label: <TranslatedText collection="status" id="canceled" />, value: 'canceled' })
  }

  useEffect(() => {
    async function fetchSeller(sellerKey: string) {
      try {
        const docRef = db.collection('users').doc(sellerKey)

        const querySnapshot = await docRef.get()
        const seller: Seller | undefined = querySnapshot.data() as Seller
        if (seller) {
          setSeller(seller)
        } else {
          dispatch(addNotification('error', 'orderNotFound'))
        }
      } catch (error) {
        dispatch(addError(error))
      }
    }

    if (sellerOrder?.userId) {
      fetchSeller(sellerOrder?.userId)
    }
  }, [db, dispatch, sellerOrder, t])

  const openDialog = useCallback(() => {
    dispatch(
      setModal('ChangeStatusDialog', {
        collection: ['orderDetailsPage'],
        options: statusOptions,
        defaultValue: statusOptions.find(o => o.value === sellerOrder.status),
        action: {
          type: CHANGE_SELLER_ORDER_STATUS,
          orderKey,
          sellerOrderId: sellerOrder?.orderId,
          refreshPage: true,
        },
      })
    )
  }, [dispatch, sellerOrder, orderKey, statusOptions])

  const openShippingDialog = useCallback(() => {
    dispatch(
      setModal('ShippingDialog', {
        className: cx(sellerOrder.deliveryType === 'terminal' ? 'max-w-5xl' : 'max-w-xl', 'py-5 px-2'),
        collection: ['orderDetailsPage'],
        deliveryType: sellerOrder?.deliveryType,
        orderId: orderKey,
        sellerId: sellerOrder?.userId,
      })
    )
  }, [dispatch, sellerOrder, orderKey])

  const totalPrice = sellerOrder?.totalPrice || sellerOrder.price + (sellerOrder.deliveryPrice || 0)
  const sellerName: string = seller && seller.brandName ? seller.brandName : sellerOrder.brand || t('brandDeleted')
  return (
    <div className={merged}>
      <ReactTooltip className="max-w-sm uppercase" effect="solid" />
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading className="flex justify-between">
            <AccordionItemButton className="flex items-center justify-between p-4 w-full focus:outline-none">
              <div className="flex font-bold text-xl items-center">
                <Label className="pr-4" msg={sellerName} />
                <AccordionItemState>
                  {({ expanded }) =>
                    expanded ? (
                      <Icon svgIcon={<ArrowUp />} width={20} height={20} />
                    ) : (
                      <Icon svgIcon={<ArrowDown />} width={20} height={20} />
                    )
                  }
                </AccordionItemState>
              </div>
              <div className="flex items-center text-gray-500 text-xs">
                <div className="flex items-center mx-6">
                  <TranslatedText collection="orderDetailsPage" id="status" className="mr-2" />
                  {sellerOrder.status === 'sold' && (
                    <div data-tip={t(`${sellerOrder.status}Tooltip`)}>
                      <Icon svgIcon={<Info />} width={16} height={16} className={classes.icon} />
                    </div>
                  )}
                </div>
                <LabelBadge type="default" text={t(`status:${sellerOrder.status}`)} className="mx-8" />
                {sellerOrder.status === 'sold' &&
                  user.role === 'admin' &&
                  (sellerOrder.labelUrl ? (
                    <div className="px-4 mt-2 mb-4">
                      <a
                        className="text-right  align-center cursor-pointer"
                        href={sellerOrder.labelUrl}
                        onClick={e => {
                          e.stopPropagation()
                        }}>
                        <TranslatedText collection="orderDetailsPage" id="getLabel" className="mr-2" />
                        <Icon width={20} height={20} svgIcon={<Download />} />
                      </a>
                    </div>
                  ) : (
                    <div className="px-4 mt-2 mb-4">
                      <Button className="align-center cursor-pointer" onClick={openShippingDialog}>
                        {t('generateLabel')}
                      </Button>
                    </div>
                  ))}
                <ActionsLayout>
                  <div className="cursor-pointer  p-4 hover:bg-gray-200" onClick={openDialog}>
                    <TranslatedText collection="orderDetailsPage" id="changeStatus" />
                  </div>
                </ActionsLayout>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="flex w-full flex-col">
              <OrderProductsTable products={sellerOrder?.productSnapshots} />
              <div className="h-5" />
              {sellerOrder?.deliveryPrice && (
                <>
                  <SummaryLine title={t('deliveryPrice')} value={sellerOrder.deliveryPrice} />
                  <Divider />
                </>
              )}
              <SummaryLine type={'bold'} title={t('totalPrice')} value={totalPrice} />
              {sellerOrder?.labelUrl !== undefined && (
                <>
                  <div className="px-4 mt-2 mb-4">
                    <a className="text-right flex align-center cursor-pointer" href={sellerOrder.labelUrl}>
                      <TranslatedText collection="orderDetailsPage" id="getLabel" className="mr-2" />
                      <Icon width={20} height={20} svgIcon={<Download />} />
                    </a>
                  </div>
                </>
              )}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

const styles = () => ({
  container: css``,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
