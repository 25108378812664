import { IProductImage } from '../components/util'

export interface ProductStatuses {
  deleted: boolean
  published: boolean
}

export interface ProductsTable {
  userId: string
  name: string
  brand: string
  categoryIds: string[]
  categoryNames: string
  color?: string
  created: Date | number
  desc?: string
  quantity: number
  price: number
  discount: number
  discountedPrice?: number
  material?: string
  status: ProductStatuses
  convertedStatus: string[]
  actions?: any
}

export class Product implements ProductsTable {
  id: string
  name: string
  brand: string
  categoryIds: string[]
  categoryNames: string
  color?: string
  created: Date | number
  desc?: string
  quantity: number
  price: number
  discountedPrice: number | undefined
  discount: number
  status: ProductStatuses
  convertedStatus: string[]
  items?: Array<object>
  material?: string
  docId: string
  userId: string
  eco?: boolean
  virtual?: boolean
  shippingLpTerminal: boolean
  shippingLpCourier: boolean
  images: Array<IProductImage>
  returnTimeInDays: number

  private _original: any
  public get original(): any {
    return this._original
  }
  public set original(value: any) {
    this._original = value
  }

  constructor(values: any) {
    this._original = values

    this.id = values.id
    this.name = values.name
    this.brand = values.brand
    this.categoryIds = values.categoryIds || []
    this.categoryNames = values.categoryNames?.join(', ')
    this.quantity = values.totalAmount
    this.price = Number(values.price)
    this.discountedPrice = values.discountedPrice
    this.discount = values.discount
    this.status = values.status
    this.convertedStatus = values.convertedStatus
    this.items = values.items
    this.created = new Date(values.created)
    this.docId = values.docId
    this.material = values.material
    this.desc = values.desc
    this.color = values.color
    this.userId = values.userId
    this.eco = values.eco
    this.virtual = values.coupon
    this.shippingLpTerminal = values.shippingLpTerminal
    this.shippingLpCourier = values.shippingLpCourier
    this.images = values.images
    this.returnTimeInDays = values.returnTimeInDays
  }

  toJSON() {
    return {
      name: this.name,
      brand: this.brand,
      categoryIds: this.categoryIds,
      categoryNames: this.categoryNames,
      created: this.created,
      quantity: this.quantity,
      price: this.price,
      discount: this.discount,
      discountedPrice: this.discountedPrice,
      status: this.status,
      items: this.items,
      docId: this.docId,
      material: this.material,
      desc: this.desc,
      userId: this.userId,
      returnTimeInDays: this.returnTimeInDays,
    }
  }

  getCategoriesAsString() {
    return this.categoryIds.join(', ')
  }
}
