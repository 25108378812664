import { push } from 'connected-react-router'
import { useAuth, useFirestore } from 'hoc/FirebaseProvider'
import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { getCurrentUser, setCurrentUser, UserState } from 'store/user'

const SecureRouteComponent = (props: RouteProps & { push: typeof push }) => {
  const auth = useAuth()
  const db = useFirestore()
  const dispatcher = useDispatch()

  useEffect(() => {
    function onSecureRouteCallback(authUser: any) {
      if (!authUser) {
        props.push('/login')
      }

      if (!auth.currentUser) return <Redirect to="/login" />

      const docRef = db.collection('users').doc(auth.currentUser.uid)
      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const user = ({ ...doc.data(), uid: doc.id } as unknown) as UserState
            dispatcher(setCurrentUser(user))
          }
        })
        .catch(error => {
          console.error('Error getting document:', error)
        })
    }

    const sub = auth.onAuthStateChanged(onSecureRouteCallback)

    return () => sub()
  }, [auth, db, dispatcher, props])

  return <Route {...props} />
}

export const SecureRoute = connect(undefined, { push })(SecureRouteComponent)

export const LoginRouteComponent = (props: RouteProps & { push: typeof push }) => {
  const auth = useAuth()
  const db = useFirestore()
  const user = useSelector(getCurrentUser)

  useEffect(() => {
    async function fetchUser(userKey: string) {
      try {
        const docRef = db.collection('users').doc(userKey)
        const querySnapshot = await docRef.get()
        return querySnapshot.data() as UserState
      } catch (error) {
        console.error(error)
      }
    }

    async function onLoginRouteCallback(authUser: any) {
      if (authUser && user) {
        try {
          //todo: check if works after update to firebase 7.24.0
          const userRole = JSON.parse(atob(authUser.xa.split('.')[1])).role
          if (userRole === 'admin') {
            props.push('/seller')
            return
          }
        } catch (e) {}

        if (auth.currentUser && auth.currentUser.uid) {
          const userState = user && user.uid ? user : await fetchUser(auth.currentUser.uid)
          if (userState && !userState.disabled) {
            props.push(`/product`)
          } else {
            props.push(`/seller/${auth.currentUser.uid}`)
          }
        }
      }
    }

    const sub = auth.onAuthStateChanged(onLoginRouteCallback)

    return () => sub()
  }, [auth, db, props, user])

  if (auth.currentUser) return <Redirect to={`/user/${auth.currentUser.uid}`} />
  return <Route {...props} />
}

export const LoginRoute = connect(undefined, { push })(LoginRouteComponent)
