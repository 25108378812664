import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useFirestore, useFunctions } from 'hoc/FirebaseProvider'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'

import { getPagination } from 'store/pagination'
import { getFilter } from 'store/filtering'
import { AppState } from 'store/reducers'

import { Seller, SellerStat } from 'models'
import { PAGE_SIZE, SellerActionMenu } from 'components/organisms'
import { LabelBadge } from 'components/atoms'
import { ModalSpinner, Table } from 'components/molecules'
import { DATETIME_FORMAT } from 'components/util'
import { addNotification } from 'store/notification'
import { FUNCTIONS_REGION } from 'config'

interface ListContainerProps {
  tableId: string
}

export const SellerListContainer = ({ tableId }: ListContainerProps) => {
  const [sellers, setSellers] = useState<Seller[]>([])

  const { t } = useTranslation(['sellersPage'])
  const db = useFirestore()
  const pagination = useSelector((state: AppState) => getPagination(state, tableId))
  const filter = useSelector((state: AppState) => getFilter(state, tableId))

  const data = useMemo(() => sellers as Seller[], [sellers])
  const functions = useFunctions(FUNCTIONS_REGION)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  // key - filter value, value - column id / accessor
  const filterByColumnID = new Map<string, string>()
  filterByColumnID.set('freeText', 'name')
  filterByColumnID.set('statuses', 'disabled')
  filterByColumnID.set('dateRange', 'created')

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'disabled', desc: false }],
      pageIndex: get(pagination, 'initialPage', 0),
      pageSize: get(pagination, 'pageSize', PAGE_SIZE),
      hiddenColumns: ['docId', 'created', 'registered'],
    }),
    [pagination]
  )

  const filterByStatus = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        if (filterValue.length === 0) {
          return true
        }

        const isUnregistered = !(row.original as Seller).brandName || false
        const isDisabled = (!isUnregistered && (row.original as Seller).disabled) || false

        const filterMode = filterValue[0]

        switch (filterMode) {
          case 'active':
            return !isDisabled && !isUnregistered
          case 'passive':
            return isDisabled
          case 'unregistered':
            return isUnregistered
          default:
            return true
        }
      })
    }
  }, [])
  const resendEmail = useCallback(
    (id: string) => {
      setLoading(true)
      const email = get(
        sellers.find(s => s.docId === id),
        'email'
      )
      functions
        .httpsCallable('resendSellerInvitationEmail')({ email: email })
        .then(response => {
          setLoading(false)
          if (response.data.status === 200) {
            dispatch(addNotification('success', t('emailSuccess')))
          } else {
            dispatch(addNotification('error', t(response.data.error)))
          }
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotification('error', error))
        })
    },
    [sellers, dispatch, functions, t]
  )

  const filterByDateRange = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        if (filterValue.length === 0) {
          return true
        }
        const created = (row.original as Seller).created

        if (!created) {
          return false
        }

        const momentDate = moment(created)
        const from = moment(filterValue[0])
        const to = moment(filterValue[1])

        return momentDate.isBetween(from, to)
      })
    }
  }, [])

  const getStatusCode = (row: Row) => {
    const registered = row.values.registered || false
    const disabled = row.values.disabled || false
    return !registered ? 'notRegistered' : disabled ? 'disabled' : 'active'
  }

  const columns = useMemo(
    () =>
      [
        {
          Header: '',
          accessor: 'docId',
          width: 0,
        },
        {
          Header: '',
          accessor: 'registered',
          width: 0,
        },
        {
          Header: t('companyName'),
          accessor: 'name',
          Cell: ({ row }) => {
            const registered = row.values.registered
            const value = row.values.name
            return <div className={`${!registered && 'opacity-50'}`}>{value}</div>
          },
          width: 140,
        },
        {
          Header: t('productCount'),
          accessor: 'stat.productCount',
          Cell: ({ row }) => {
            const value = row.values['stat.productCount']
            return <div>{value}</div>
          },
          width: 100,
        },

        {
          Header: t('soldProductCount'),
          accessor: 'stat.soldProductCount',

          width: 100,
        },
        {
          Header: t('totalProductValue'),
          accessor: 'stat.totalProductValue',
          Cell: ({ row }) => {
            const value = row.values['stat.totalProductValue'] as number
            const dispValue = value !== undefined ? `${value.toFixed(2)} €` : ''
            return <div>{dispValue}</div>
          },
          width: 120,
        },
        {
          Header: t('soldProductValue'),
          accessor: 'stat.soldProductValue',
          Cell: ({ row }) => {
            const value = row.values['stat.soldProductValue'] as number
            const dispValue = value !== undefined ? `${value.toFixed(2)} €` : ''
            return <div>{dispValue}</div>
          },
          width: 130,
        },

        {
          Header: t('created'),
          accessor: 'created',
          Cell: ({ row }) => {
            const value = row.values.created
            const strDate = value && moment(value).format(DATETIME_FORMAT)
            return <div>{strDate || 'N/D'}</div>
          },
          sortType: 'date',
          filter: filterByDateRange,
          width: 100,
        },
        {
          Header: t('status'),
          accessor: 'disabled',
          width: 180,
          minWidth: 160,
          Cell: ({ row }) => {
            const statusCode = getStatusCode(row)
            const registered = row.values.registered
            return <LabelBadge size="sm" text={t(statusCode)} type={registered ? 'default' : 'disabled'} />
          },
          sortType: (rowA: Row, rowB: Row) => {
            const statusCodeA = getStatusCode(rowA)
            const statusCodeB = getStatusCode(rowB)

            return statusCodeA > statusCodeB ? 1 : -1
          },
          filter: filterByStatus,
        },
        {
          Header: '',
          accessor: 'actions',
          disableSortBy: true,
          width: 40,
          sticky: 'right',
          Cell: ({ row }) => {
            const id = row.values.docId || ''
            const registered = row.values.registered || false
            return <SellerActionMenu id={id} registered={registered} loading={loading} action={resendEmail} />
          },
        },
      ] as Array<Column>,
    [filterByDateRange, filterByStatus, loading, t, resendEmail]
  )

  useEffect(() => {
    ;(async function () {
      const statMap: Map<string, SellerStat> = new Map<string, SellerStat>()
      const snapshotStats = await db.collection('userStats').get()
      snapshotStats.forEach(doc => {
        statMap.set(doc.id, doc.data())
      })

      const sellerList: Seller[] = []
      const snapshotUsers = await db.collection('users').where('role', '==', 'seller').get()
      snapshotUsers.forEach(doc => {
        const brandName = doc.get('brandName')
        const email = doc.get('email')
        const name = brandName || email
        const registered = brandName !== undefined && brandName.length > 0

        const seller: Seller = {
          ...doc.data(),
          stat: statMap.get(doc.id),
          docId: doc.id,
          name,
          registered,
        } as Seller
        const created = doc.get('created') as firebase.firestore.Timestamp
        seller.created = created && created.toDate()
        if (seller.stat) {
          seller.stat.productCount = registered ? seller.stat?.productCount || 0 : undefined
          seller.stat.soldProductCount = registered ? seller.stat?.soldProductCount || 0 : undefined
          seller.stat.totalProductValue = registered ? seller.stat?.totalProductValue || 0 : undefined
          seller.stat.soldProductValue = registered ? seller.stat?.soldProductValue || 0 : undefined
        }
        sellerList.push(seller)
      })
      setSellers(sellerList)
    })()
  }, [db])

  return (
    <>
      {loading && <ModalSpinner />}
      <Table
        tableId={tableId}
        columns={columns}
        data={data}
        paginationOptions={pagination}
        filterOptions={filter}
        initialState={initialState}
        filterMapByColumnId={filterByColumnID}
      />
    </>
  )
}
