import { createActionTypes } from 'services'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'

// Epics
export const REMOVE_USER = 'REMOVE_USER'
export const BLOCK_USER = 'BLOCK_USER'
export const UNBLOCK_USER = 'UNBLOCK_USER'

// Reducer
export const USER_STATE_KEY = 'currentUser'

export const createLoginActionTypes = createActionTypes('LOGIN')
