import React from 'react'
import { useTheme } from 'emotion-theming'
import { css } from 'emotion'
import { useTranslation } from 'react-i18next'

import { SearchInputContainer } from '../SearchInputContainer'
import { SellerStatusFilter } from '../SellerStatusFilter'

interface FilterContainerProps {
  id: string
  className?: string
}

export const SellerFilterContainer = ({ id }: FilterContainerProps) => {
  const { t } = useTranslation(['sellersPage'])
  const theme = useTheme<any>()
  const classes = styles(theme)

  return (
    <>
      <SearchInputContainer
        className={classes.filter}
        id={id}
        name={'sellerSearch'}
        label={t('searchLabel')}
        placeholder={t('searchPlaceholder')}
      />
      <SellerStatusFilter className={classes.filter} id={id} />
      {/*//todo: disabled till nex release*/}
      {/*<DateRangePickerFilterContainer className={classes.filter} id={id} label={t('created')} />*/}
    </>
  )
}

const styles = (theme: any) => ({
  filter: css`
    &:not(:last-child) {
      margin-right: 24px;
    }
  `,
})
