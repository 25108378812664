import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

export type BadgeType = 'success' | 'warning' | 'error' | 'info' | 'default' | 'disabled'
export type BadgeSize = 'sm' | 'md' | 'lg'

const BADGE_CLASSES = 'inline-block rounded-full uppercase font-semibold tracking-wide text-center'

interface LabelBadgeProps {
  className?: string
  size?: BadgeSize
  type?: BadgeType
  text: string
}

export const LabelBadge = (props: LabelBadgeProps) => {
  const { className, size = 'md', type = 'default', text } = props

  const chooseStyleByType = (type: BadgeType) => {
    let badgeStyle = ''
    switch (type) {
      case 'success':
        badgeStyle = 'bg-green-500 text-gray-100'
        break
      case 'warning':
        badgeStyle = 'bg-yellow-500 text-gray-100'
        break
      case 'error':
        badgeStyle = 'bg-red-500 text-gray-100'
        break
      case 'info':
        badgeStyle = 'bg-blue-500 text-gray-100'
        break
      case 'disabled':
        badgeStyle = 'bg-gray-300 text-white'
        break
      default:
        badgeStyle = 'bg-gray-400 text-white'
    }
    return badgeStyle
  }

  const chooseStyleBySize = (size: BadgeSize) => {
    let badgeStyle = ''
    switch (size) {
      case 'sm':
        badgeStyle = 'p-p2 text-xs'
        break
      case 'md':
        badgeStyle = 'text-xs px-2 py-1'
        break
      case 'lg':
        badgeStyle = 'text-sm px-2 py-2'
        break
      default:
        badgeStyle = 'text-xs px-2 py-1'
    }
    return badgeStyle
  }

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(BADGE_CLASSES, chooseStyleByType(type), chooseStyleBySize(size), classes.component, className)

  return <span className={merged}>{text}</span>
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
