export class Category {
  private _label: string
  public get label(): string {
    return this._label
  }

  private _value: any
  public get value(): any {
    return this._value
  }

  private _options: any | undefined
  public get options(): any | undefined {
    return this._options
  }

  private _children: any | undefined
  public get children(): any | undefined {
    return this._children
  }

  private _parentIds: Array<string> | undefined
  public get parentIds(): Array<string> | undefined {
    return this._parentIds
  }

  private _parentNames: Array<string> | undefined
  public get parentNames(): Array<string> | undefined {
    return this._parentNames
  }

  private _returnTimeInDays: number | undefined
  public get returnTimeInDays(): number | undefined {
    return this._returnTimeInDays
  }

  constructor(
    _label: string,
    _value: any,
    _options?: any | undefined,
    _parentIds?: Array<string> | undefined,
    _parentNames?: Array<string> | undefined,
    _returnTimeInDays?: number | undefined
  ) {
    this._label = _label
    this._value = _value
    this._options = _options
    this._children = _options
    this._parentIds = _parentIds
    this._parentNames = _parentNames
    this._returnTimeInDays = _returnTimeInDays
  }

  toJSON() {
    return {
      label: this.label,
      values: this.value,
      options: this.options,
      parentIds: this.parentIds,
      parentNames: this.parentNames,
      returnTimeInDays: this.returnTimeInDays,
    }
  }
}
