import React, { useMemo } from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { getCurrentUser } from '../../../store/user'

interface OrderTypeProps {
  className?: string
  orderType?: string
  id: string
  push: typeof push
}

const FILTER_STYLES = 'flex flex-row flex-shrink-0'
const BUTTON_STYLES = 'focus:outline-none border-gray-500 hover:bg-gray-100  m-5 p-1'
const BUTTON_SELECTED = 'border-b-2'

export const isOrderOrders = (type: string) => {
  return type === 'sold' || type === 'sent'
}

export const isOrderCanceled = (type: string) => {
  return type === 'canceled'
}

export const OrderTypeFilterComponent = (props: OrderTypeProps) => {
  const { className, orderType = 'sold', push } = props
  const { t } = useTranslation(['ordersPage'])
  const classes = styles()
  const merged = cx(classes.component, className, FILTER_STYLES)
  const user = useSelector(getCurrentUser)
  const isAdmin = useMemo(() => {
    return user.role === 'admin'
  }, [user])

  const orderTypes: Array<string> = isAdmin
    ? ['sold', 'sent', 'toBeReturned', 'returned', 'canceled']
    : ['sold', 'sent', 'toBeReturned', 'returned']

  const defaultIndex = orderTypes.indexOf(orderType)

  return (
    <div className={merged}>
      {orderTypes.map((type, index) => {
        return (
          <button
            key={index}
            className={cx(BUTTON_STYLES, index === defaultIndex && BUTTON_SELECTED)}
            onClick={() => {
              push(`?type=${type}`)
            }}>
            {t(`menu_${type}`)}
          </button>
        )
      })}
    </div>
  )
}

export const OrderTypeFilter = connect(undefined, { push })(OrderTypeFilterComponent)

const styles = () => ({
  component: css`
    display: block;
  `,
})
