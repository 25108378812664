import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface CardLayoutProps {
  className?: string
  children?: React.ReactNode
}

const CARD_STYLE = 'w-full bg-white rounded-sm shadow-lg'

export const CardLayout = (props: CardLayoutProps) => {
  const { className, children } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CARD_STYLE, classes.component, className)

  return <div className={merged}>{children}</div>
}

const styles = (theme: any) => ({
  component: css``,
})
