import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { withModal } from 'hoc/withModal/withModal'
import { useDispatch } from 'react-redux'
import { deleteModal } from 'store/modal'
import { TranslatedText, Button } from 'components/atoms'
import { ModalProps } from 'models'

interface InfoDialogProps extends ModalProps {
  className?: string
  children?: React.ReactNode
  collection?: string
}

const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 py-5'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3 px-10'
const BUTTON_WRAPPER_CLASSES = 'mt-5 p-5 flex justify-center items-center'

export const InfoDialog = (props: InfoDialogProps) => {
  const { className, collection = '', id } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(deleteModal(id))
  }

  return (
    <div className={merged}>
      <TranslatedText className={TITLE_CLASSES} collection={collection} id="infoTitle" />
      <TranslatedText className={DESC_CLASSES} collection={collection} id="infoDesc" />
      <div className={BUTTON_WRAPPER_CLASSES}>
        <Button className="mr-2" onClick={closeModal}>
          <TranslatedText collection={collection} id="okText" />
        </Button>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})

export const InfoDialogModal = withModal(InfoDialog)
