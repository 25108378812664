import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { SideNavigationLayout, CommonPageLayout, ListLayout } from 'components/templates'
import { useSelector } from 'react-redux'
import { getUser } from 'store/user'
import { Redirect } from 'react-router-dom'
import { PagerFilter, BalanceFilterContainer } from 'components/organisms'
import { BalanceListContainer } from 'components/organisms/BalanceListContainer'

interface BalancePageProps {
  className?: string
  children?: React.ReactNode
}

const TABLE_ID = 'BalanceTable'

export const BalancePage = (props: BalancePageProps) => {
  const { className } = props
  const { t } = useTranslation(['balancePage'])

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const user = useSelector(getUser)
  const pagerFilter = <PagerFilter id={TABLE_ID} />
  const list = <BalanceListContainer tableId={TABLE_ID} />
  const filter = <BalanceFilterContainer id={TABLE_ID} />

  return (
    <div>
      {user.role === 'seller' && user.disabled ? (
        <Redirect to={`/seller/${user.uid}`} />
      ) : (
        <SideNavigationLayout className={merged}>
          <CommonPageLayout pageTitle={t('title')}>
            <ListLayout tableId={TABLE_ID} filter={filter} list={list} pagerFilter={pagerFilter} />
          </CommonPageLayout>
        </SideNavigationLayout>
      )}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
