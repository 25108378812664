import React, { useCallback } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useDispatch } from 'react-redux'
import { setModal } from 'store/modal'
import { REMOVE_PRODUCT } from 'store/product'

interface RemoveProductProps {
  className?: string
  children?: React.ReactNode
  id: string | string[] // Product ids
  list?: string[]
  redirectAfterAction?: boolean
}

export const RemoveProduct = (props: RemoveProductProps) => {
  const { className, children, id, redirectAfterAction = false, list } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const dispatch = useDispatch()
  const multiple = typeof id !== 'string'

  const openDialog = useCallback(() => {
    dispatch(
      setModal('ConfirmDialog', {
        collection: multiple ? 'removeProductsDialog' : 'removeProduct',
        list: list,
        action: {
          type: REMOVE_PRODUCT,
          productIds: multiple ? id : [id],
          redirectAfterAction: redirectAfterAction,
        },
      })
    )
  }, [dispatch, id, list, multiple, redirectAfterAction])

  return (
    <div className={merged} onClick={openDialog}>
      {children}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
