import * as actions from './actions'
import * as constants from './constants'
import { FilteringActions } from './actions'

export interface Filter {
  freeText?: string
  [key: string]: any
}

export interface FilterState {
  tables: {
    [tableName: string]: Filter
  }
}

type State = FilterState

const initialState: State = {
  tables: {},
}

const setFilter = (state: State, action: actions.SetFilteringAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: action.filter,
  },
})

const setFreeTextFilter = (state: State, action: actions.SetFreeTextSearchAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: {
      ...state.tables[action.table],
      freeText: action.freeText,
    },
  },
})

const setCategoryFilter = (state: State, action: actions.SetCategoryFilterAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: {
      ...state.tables[action.table],
      categories: [...action.categories],
    },
  },
})

const setBrandFilter = (state: State, action: actions.SetBrandFilterAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: {
      ...state.tables[action.table],
      brands: action.brands,
    },
  },
})

const setStatusFilter = (state: State, action: actions.SetStatusFilterAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: {
      ...state.tables[action.table],
      statuses: action.statuses,
    },
  },
})

const setDateRangeFilter = (state: State, action: actions.SetDateRangeFilterAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: {
      ...state.tables[action.table],
      dateRange: action.dateRange,
    },
  },
})

const setDateFilter = (state: State, action: actions.SetDateFilterAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: {
      ...state.tables[action.table],
      date: action.date,
    },
  },
})

const strategies = {
  [constants.SET_FILTERING]: setFilter,
  [constants.SET_FREE_TEXT_SEARCH]: setFreeTextFilter,
  [constants.SET_CATEGORY_FILTER]: setCategoryFilter,
  [constants.SET_STATUS_FILTER]: setStatusFilter,
  [constants.SET_BRAND_FILTER]: setBrandFilter,
  [constants.SET_DATERANGE_FILTER]: setDateRangeFilter,
  [constants.SET_DATE_FILTER]: setDateFilter,
  __default__: (state: State) => state,
}

export const filteringReducer = (state = initialState, action: FilteringActions): State => {
  const transformer = strategies[action.type] ?? strategies.__default__
  return transformer(state, action as any)
}
