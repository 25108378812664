import React, { useEffect, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAuth, useFirestore, useStorage } from 'hoc/FirebaseProvider'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getCurrentUser, setCurrentUser } from 'store/user'
import { Button, TranslatedText } from 'components/atoms'
import { AddNewAccForm, UserProfileImage, UsersDetailsForm } from 'components/organisms'
import { CardLayout } from 'components/templates/CardLayout'
import { DIALOG_CANCEL, setModal } from 'store/modal'
import { useUserId } from 'components/util/useUserId'
import { push } from 'connected-react-router'
import { addNotification } from 'store/notification'

interface UsersProfileComponentProps {
  className?: string
  children?: React.ReactNode
  push: typeof push
}

const initialValues = {
  agreementNumber: '',
  companyName: '',
  VATCode: '',
  marginPercentage: '',
  companyCode: '',
  bankAccountNumber: '',
  country: '',
  email: '',
  brandName: '',
  shopDesc: '',
  telNumber: '',
  senderAddressCity: '',
  senderAddressField1: '',
  senderAddressField2: '',
  senderAddressZip: '',
}

const UserProfileSchema = Yup.object().shape({
  agreementNumber: Yup.string().required('errorRequired'),
  companyName: Yup.string().required('errorRequired'),
  country: Yup.string().required('errorRequired'),
  VATCode: Yup.string(),
  marginPercentage: Yup.number().required('errorRequired'),
  companyCode: Yup.string().required('errorRequired'),
  bankAccountNumber: Yup.string().required('errorRequired'),
  email: Yup.string().email('errorInvalidEmail').required('errorRequired'),
  brandName: Yup.string().required('errorRequired'),
  shopDesc: Yup.string(),
  telNumber: Yup.string().required('errorRequired'),
  senderAddressCity: Yup.string().required('errorRequired'),
  senderAddressField1: Yup.string().required('errorRequired'),
  senderAddressField2: Yup.string().required('errorRequired'),
  senderAddressZip: Yup.string().required('errorRequired'),
})

const TRANSLATION_BLOCK = 'profile'

const binTypes: Map<string, string> = new Map([
  ['image/jpeg', 'jpg'],
  ['image/png', 'png'],
])

export const UsersProfileComponent = (props: UsersProfileComponentProps) => {
  const { className, push } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const { t } = useTranslation([TRANSLATION_BLOCK])

  const db = useFirestore()
  const storageRef = useStorage()
  const [userDetails, setUserDetails] = useState<any>({})
  const [photoUrl, setPhotoUrl] = useState<string>('')
  const [brandPhotoUrl, setBrandPhotoUrl] = useState<string>('')
  const user = useSelector(getCurrentUser)
  const userId = useUserId()
  const dispatch = useDispatch()
  const auth = useAuth()

  const PATH = `${userId}/`
  const PROFILE_PIC_NAME = 'profilePic'
  const BRAND_PIC_NAME = 'brandPic'

  // FETCH user details
  useEffect(() => {
    let unsubscribe: any

    function fetchData() {
      const docRef = db.collection('users').doc(userId)
      unsubscribe = docRef.onSnapshot(
        (querySnapshot: any) => {
          const data = querySnapshot.data()
          if (data) {
            setUserDetails(data)

            if (data.profilePic) {
              setPhotoUrl(data.profilePic)
            }
            if (data.brandPic) {
              setBrandPhotoUrl(data.brandPic)
            }
          }
        },
        error => {
          dispatch(addNotification('error', t('fetch_error')))
          console.error('User details are not fetched', error)
        }
      )
    }

    if (userId && auth.currentUser) {
      fetchData()
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [db, userId, auth.currentUser, dispatch, t])

  const getInitialValues = (): any => {
    if (userDetails) {
      return {
        agreementNumber: userDetails.agreementNumber || '',
        companyName: userDetails.companyName || '',
        VATCode: userDetails.VATCode || '',
        marginPercentage: userDetails.marginPercentage || '',
        country: userDetails.country || '',
        companyCode: userDetails.companyCode || '',
        bankAccountNumber: userDetails.bankAccountNumber || '',
        email: userDetails.email || '',
        brandName: userDetails.brandName || '',
        shopDesc: userDetails.shopDesc || '',
        telNumber: userDetails.telNumber || '',
        senderAddressCity: userDetails.senderAddressCity || '',
        senderAddressField1: userDetails.senderAddressField1 || '',
        senderAddressField2: userDetails.senderAddressField2 || '',
        senderAddressZip: userDetails.senderAddressZip || '',
      }
    }
    return initialValues
  }

  const handleImage = async () => {
    if (photoUrl && photoUrl !== '' && userDetails.profilePic !== photoUrl) {
      const blob = await fetch(photoUrl).then(r => r.blob())
      const ext = binTypes.get(blob.type) || 'bin'
      const ref = storageRef.child(PATH + PROFILE_PIC_NAME + `.${ext}`)
      await ref
        .put(blob)
        .then(() => console.log('success'))
        .catch(err => console.log(err))
      return await ref.getDownloadURL()
    } else if (userDetails.profilePic && photoUrl === '') {
      return await storageRef
        .child(PATH)
        .listAll()
        .then(res => {
          res.items
            .filter(item => item.name.startsWith(PROFILE_PIC_NAME))
            .forEach(item => {
              item
                .delete()
                .then(() => '')
                .catch(err => console.log(err))
            })
        })
        .then(() => '')
        .catch(err => console.log(err))
    } else {
      return photoUrl
    }
  }

  const handleBrandImage = async () => {
    if (brandPhotoUrl && brandPhotoUrl !== '' && userDetails.brandPic !== brandPhotoUrl) {
      let blob = await fetch(brandPhotoUrl).then(r => r.blob())
      const ext = binTypes.get(blob.type) || 'bin'
      const ref = storageRef.child(PATH + BRAND_PIC_NAME + `.${ext}`)
      await ref
        .put(blob)
        .then(() => console.log('success'))
        .catch(err => console.log(err))
      return await ref.getDownloadURL()
    } else if (userDetails.brandPic && brandPhotoUrl === '') {
      return await storageRef
        .child(PATH)
        .listAll()
        .then(res => {
          res.items
            .filter(item => item.name.startsWith(BRAND_PIC_NAME))
            .forEach(item => {
              item
                .delete()
                .then(() => '')
                .catch(err => console.log(err))
            })
        })
        .then(() => '')
        .catch(err => console.log(err))
    } else {
      return brandPhotoUrl
    }
  }

  const resizeImage = async (img: Blob): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.src = window.URL.createObjectURL(img)
      image.onload = () => {
        let width = image.width
        let height = image.height
        let newWidth
        let newHeight
        let sourceX
        let sourceY
        let scale = 1.5
        if (width < 900) {
          dispatch(addNotification('error', t('profile:min_width_error')))
          return
        }
        if (height < 600) {
          dispatch(addNotification('error', t('profile:min_height_error')))
          return
        }
        if (width > height * scale) {
          newHeight = height
          newWidth = newHeight * scale
          sourceY = 0
          sourceX = width / 2 - newWidth / 2
        } else {
          newWidth = width
          newHeight = newWidth / scale
          sourceX = 0
          sourceY = height / 2 - newHeight / 2
        }

        let canvas = document.createElement('canvas')
        canvas.width = newWidth
        canvas.height = newHeight

        let context = canvas.getContext('2d')
        context!.drawImage(image, sourceX, sourceY, newWidth, newHeight, 0, 0, newWidth, newHeight)

        canvas.toBlob(function (blob) {
          return resolve(blob!)
        }, 'image/jpeg')
      }
      image.onerror = reject
    })
  }

  const validateAdminEnteredInfo = () => {
    return !!(
      formik.errors.agreementNumber ||
      formik.errors.companyName ||
      formik.errors.marginPercentage ||
      formik.errors.country ||
      formik.errors.companyCode ||
      formik.errors.bankAccountNumber ||
      formik.errors.email
    )
  }

  const handleSubmit = async (values: any) => {
    const docRef = db.collection('users').doc(userId)
    values.disabled = userDetails.disabled
    values.profilePic = await handleImage()
    values.brandPic = await handleBrandImage()
    const item = {
      ...userDetails,
      ...values,
    }

    if (!item.uid) {
      item.uid = userId
    }

    if (item.shopDesc) {
      item.shopDesc = item.shopDesc.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '').replace(/www\.[\n\S]+/g, '')
    }

    if (user.role !== 'admin' && (!values.profilePic || !values.brandPic)) {
      if (!values.profilePic) dispatch(addNotification('error', t('userDetails:errorRequired/profilePic')))
      if (!values.brandPic) dispatch(addNotification('error', t('userDetails:errorRequired/brandPic')))
      return false
    }

    const getIsUniqFields = async () => {
      if (values.email !== userDetails.email) {
        const existingDocsEmail = await db.collection('users').where('email', '==', values.email).get()
        if (existingDocsEmail.docs.length > 0) {
          dispatch(addNotification('error', t('newUserForm:auth/email-already-exists')))
          return false
        }
      }
      if (values.agreementNumber !== userDetails.agreementNumber) {
        const existingDocsAgreement = await db
          .collection('users')
          .where('agreementNumber', '==', values.agreementNumber)
          .get()

        if (existingDocsAgreement.docs.length > 0) {
          dispatch(addNotification('error', t('newUserForm:validation/agrement-number-is-not-unique')))
          return false
        }
      }
      return true
    }

    const isUniq = await getIsUniqFields()

    if (isUniq) {
      docRef
        .set(item, { merge: true })
        .then(res => {
          if (user.role !== 'admin') {
            dispatch(setCurrentUser(item))
            push('/product')
          }
          dispatch(addNotification('success', t('profile_saved')))
        })
        .catch(err => {
          dispatch(addNotification('error', err))
          console.log(err)
        })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: UserProfileSchema,
    onSubmit: handleSubmit,
  })

  const uploadImage = async (img: any[]) => {
    if (img.length > 0) {
      const imgBlob = await resizeImage(img[0])
      const localImage = window.URL.createObjectURL(imgBlob)
      setPhotoUrl(localImage)
    }
  }

  const uploadBrandImage = async (img: any[]) => {
    if (img.length > 0) {
      const imgBlob = await resizeImage(img[0])
      const localImage = window.URL.createObjectURL(imgBlob)
      setBrandPhotoUrl(localImage)
    }
  }

  const handleCancel = () => {
    if (formik.dirty) {
      dispatch(
        setModal('ConfirmDialog', {
          collection: TRANSLATION_BLOCK,
          action: {
            type: DIALOG_CANCEL,
            formik,
            successNote: t('changes_cancelled'),
          },
        })
      )
    } else {
      dispatch(addNotification('success', t('changes_cancelled')))
    }
  }

  return (
    <form className={merged} onSubmit={formik.handleSubmit} noValidate>
      <CardLayout className="px-16 py-10 mb-10">
        <AddNewAccForm formik={formik} disabled={user.role !== 'admin'} />
        <a href="/default-agreement" className="hover:underline font-semibold">
          <TranslatedText collection="profile" id="defaultAgreement" />
        </a>
      </CardLayout>
      <h1 className="pb-2">
        <TranslatedText collection="profile" id="sellerEnteredInfo" />
      </h1>
      <CardLayout className="px-16 py-10">
        <UserProfileImage
          onDrop={uploadImage}
          onDelete={() => setPhotoUrl('')}
          photoUrl={photoUrl}
          photoName="profilePic"
        />
        <UsersDetailsForm formik={formik} />
        <UserProfileImage
          onDrop={uploadBrandImage}
          onDelete={() => setBrandPhotoUrl('')}
          photoUrl={brandPhotoUrl}
          photoName="brandPic"
        />
      </CardLayout>
      <div className="mt-6 flex justify-end">
        <Button color="secondary" onClick={handleCancel}>
          <TranslatedText collection="profile" id="cancel" />
        </Button>
        {user.role === 'admin' ? (
          <Button
            type="button"
            disabled={validateAdminEnteredInfo()}
            onClick={() => handleSubmit(formik.values)}
            className="ml-4">
            <TranslatedText collection="profile" id="save" />
          </Button>
        ) : (
          <Button type="submit" disabled={formik.dirty && !formik.isValid} className="ml-4">
            <TranslatedText collection="profile" id="save" />
          </Button>
        )}
      </div>
    </form>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})

export const UsersProfileContainer = connect(undefined, { push })(UsersProfileComponent)
