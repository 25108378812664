import * as actions from './actions'
import * as constants from './constants'
import { PaginationActions } from './actions'

export interface Pagination {
  initialPage: number
  currentPage: number
  totalPages: number
  pageSize: number
  visible: boolean
}

export interface PaginationState {
  tables: {
    [tableName: string]: Pagination
  }
}

type State = PaginationState

const initialState: State = {
  tables: {},
}

const setPagination = (state: State, action: actions.SetPaginationAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: action.pagination,
  },
})

const setCurrentPage = (state: State, action: actions.SetCurrentPageAction) => ({
  ...state,
  tables: {
    ...state.tables,
    [action.table]: {
      ...state.tables[action.table],
      currentPage: action.currentPage,
    },
  },
})

const strategies = {
  [constants.SET_PAGINATION]: setPagination,
  [constants.SET_CURRENT_PAGE]: setCurrentPage,
  __default__: (state: State) => state,
}

export const paginationReducer = (state = initialState, action: PaginationActions): State => {
  const transformer = strategies[action.type] ?? strategies.__default__
  return transformer(state, action as any)
}
