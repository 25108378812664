import React, { useCallback, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useDispatch } from 'react-redux'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import * as _ from 'lodash'

import { InputLabel } from 'components/atoms/InputLabel'
import { TranslatedText } from 'components/atoms/TranslatedText'
import { setDateRangeFilterField } from 'store/filtering'
import { ReactComponent as CalendarIcon } from '../../../resources/images/icons/calendar.svg'

interface DateRangePickerFilterContainerProps {
  className?: string
  id: string
  label?: string
}

const CONTAINER_CLASSES = 'w-48'
const LABEL_CLASSES = 'pb-1'

export const DateRangePickerFilterContainer = (props: DateRangePickerFilterContainerProps) => {
  const { label, className, id } = props
  const [dateRange, setDateRange] = useState<any>(undefined)
  const dispatcher = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CONTAINER_CLASSES, classes.component, className)

  const updateSelect = useCallback(
    _.debounce((data: any) => {
      dispatcher(setDateRangeFilterField(id, data || []))
    }, 100),
    []
  )

  const handleChange = (selected: [Date, Date]) => {
    if (selected) {
      setDateRange(selected)
      updateSelect(selected)
    } else {
      // On last chip removing, value is retured as null
      updateSelect([])
      setDateRange(undefined)
    }
  }

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES}>
        {!label && <TranslatedText collection="productsPage" id="dateLabel" />} {label}
      </InputLabel>
      <DateRangePicker
        className={cx(classes.datePicker)}
        onChange={handleChange}
        value={dateRange}
        locale="lt-LT"
        format="yyyy-MM-dd"
        calendarIcon={<CalendarIcon className={classes.icon} />}
      />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  datePicker: css`
    .react-daterange-picker__wrapper {
      background-color: hsl(0, 0%, 100%);
      border-color: #e2e8f0;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      padding: 4.5px 5px;

      .react-daterange-picker__calendar-button {
        order: -1;
      }
    }
  `,
  icon: css`
    height: 19px;
    width: 19px;
  `,
})
