import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { TranslatedText } from 'components/atoms/TranslatedText'
import './TabsStyle.scss'

import { ProductListLayout } from 'components/templates/ProductListLayout'
import { UsersProfileContainer } from 'components/organisms/UsersProfileCointainer'
import { OrderList } from 'components/organisms/OrderList'
import { useUserId } from 'components/util/useUserId'

interface PortfolioTabsProps {
  className?: string
  tabSelected?: string
}

export const PortfolioTabs = (props: PortfolioTabsProps) => {
  const { className, tabSelected } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const tabIndex = ['products', 'orders', 'profile']
  const defaultIndex = tabSelected ? tabIndex.indexOf(tabSelected) : undefined
  const userId = useUserId()

  return (
    <div className={merged}>
      <Tabs defaultIndex={defaultIndex}>
        <TabList>
          <Tab>
            <TranslatedText collection="sidebar" id={tabIndex[0]} />
          </Tab>
          <Tab>
            <TranslatedText collection="sidebar" id={tabIndex[1]} />
          </Tab>
          <Tab>
            <TranslatedText collection="sidebar" id={tabIndex[2]} />
          </Tab>
        </TabList>

        <TabPanel>
          <ProductListLayout sellerColumn={false} />
        </TabPanel>
        <TabPanel>
          <OrderList userId={userId} />
        </TabPanel>
        <TabPanel>
          <UsersProfileContainer className="max-w-xl abolute left-0 right-0 mx-auto my-0" />
        </TabPanel>
      </Tabs>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
