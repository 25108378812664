import firebase, { firestore } from 'firebase'
import { config, isLocal } from 'config'
import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'

export const app = firebase.initializeApp(config.FIREBASE_CONFIG)
app.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

export const FirebaseContext = createContext<firebase.app.App>(app)

const emulator = {
  FUNCTION_EMULATOR_URL: 'http://localhost:5001',
  AUTH_EMULATOR_URL: 'http://localhost:9099',
  FIRESTORE_EMULATOR_HOST: 'localhost:8080',
}

export const useFunctions = (region: string = 'us-central1') => {
  const app = useContext(FirebaseContext)
  if (isLocal()) {
    app.functions().useFunctionsEmulator(emulator.FUNCTION_EMULATOR_URL)
    return app.functions()
  }
  return app.functions(region)
}

export const useFirestoreCollection = (ref: firestore.CollectionReference) => {
  const [data, setData] = useState<() => any>(() => undefined)
  useEffect(() => {
    async function execute() {
      return await ref.get()
    }
    const result = execute()
    setData(() => result)
  }, [ref])

  return data
}

export const useFirestore = () => {
  const app = useContext(FirebaseContext)
  if (isLocal()) {
    app.firestore().settings({
      host: emulator.FIRESTORE_EMULATOR_HOST,
      ssl: false,
    })
  }
  return app.firestore()
}
export const useAuth = () => {
  const app = useContext(FirebaseContext)
  if (isLocal()) {
    app.auth().useEmulator(emulator.AUTH_EMULATOR_URL)
  }

  return app.auth()
}

export const useStorage = () => {
  const app = useContext(FirebaseContext)
  const ref = useMemo(() => app.storage().ref(), [app])
  return ref
}

export const FirebaseProvider: React.FC<{ app: any }> = props => {
  return <FirebaseContext.Provider value={app}>{props.children}</FirebaseContext.Provider>
}
