import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface ErrorLabelProps {
  className?: string
  children?: React.ReactNode
}

export const ErrorLabel = (props: ErrorLabelProps) => {
  const { className, children } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  return <div className={merged}>{children}</div>
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
