import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import ReactPaginate from 'react-paginate'

const PREV_NAV_BUTTON_CLASSES =
  'outline-none relative inline-flex items-center px-2 py-2 rounded-l-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 disabled:cursor-not-allowed disabled:opacity-75 transition ease-in-out duration-150'
const NEXT_NAV_BUTTON_CLASSES =
  'outline-none -ml-px relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 disabled:cursor-not-allowed disabled:opacity-75 transition ease-in-out duration-150'
const PAGINATION_ITEM_CLASSES =
  'cursor-pointer -ml-px relative inline-flex items-center bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'

interface PaginationProps {
  className?: string
  initialPage?: number
  pageCount: number
  withBorders?: boolean
  onPageChange?: (id: number) => void
}

export const Pagination = (props: PaginationProps) => {
  const { className, pageCount, withBorders = false, onPageChange = () => {} } = props

  const pages = Array.from(Array(pageCount), (_, i) => i + 1)
  const prevLabel = (
    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clipRule="evenodd"></path>
    </svg>
  )
  const nextLabel = (
    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"></path>
    </svg>
  )

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const handlePageChangeClick = (data: any) => {
    onPageChange(data.selected)
  }

  return (
    <div className={merged}>
      <div>
        <nav className="relative inline-flex">
          <ReactPaginate
            previousLabel={prevLabel}
            previousClassName={cx(PREV_NAV_BUTTON_CLASSES, { 'border border-gray-300': withBorders })}
            previousLinkClassName="focus:outline-none"
            nextLabel={nextLabel}
            nextClassName={cx(NEXT_NAV_BUTTON_CLASSES, { 'border border-gray-300': withBorders })}
            nextLinkClassName="focus:outline-none"
            breakLabel={'...'}
            breakClassName={PAGINATION_ITEM_CLASSES}
            pageCount={pages.length}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChangeClick}
            containerClassName="inline-flex"
            pageClassName={PAGINATION_ITEM_CLASSES}
            pageLinkClassName="focus:outline-none px-4 py-2"
            activeClassName="bg-gray-700"
            activeLinkClassName="text-white focus:outline-none px-4 py-2"
          />
        </nav>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
