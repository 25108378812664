import * as actions from './actions'
import * as constants from './constants'

export interface ProductState {
  list: any[]
}

type State = ProductState

const initialState: State = {
  list: [],
}

const setproductIds = (state: State, action: actions.SetProductAction) => ({
  ...state,
  list: action.list,
})

const strategies = {
  [constants.SET_PRODUCT_IDS]: setproductIds,
  __default__: (state: State) => state,
}

export const productReducer = (state = initialState, action: actions.SetProductAction): State => {
  const transformer = strategies[action.type] ?? strategies.__default__
  return transformer(state, action as any)
}
