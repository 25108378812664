import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useDispatch } from 'react-redux'

import { setModal } from 'store/modal'
import { BLOCK_USER, unblockUser } from 'store/user'

interface BlockUserProps {
  className?: string
  children?: React.ReactNode
  id: string | string[]
}

export const BlockUser = (props: BlockUserProps) => {
  const { className, children, id } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const dispatch = useDispatch()

  return (
    <div
      className={merged}
      onClick={() => {
        dispatch(
          setModal('ConfirmDialog', {
            collection: 'blockUser',
            action: {
              type: BLOCK_USER,
              ids: typeof id === 'string' ? [id] : id,
            },
          })
        )
      }}>
      {children}
    </div>
  )
}

export const UnBlockUser = (props: BlockUserProps) => {
  const { className, children, id } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const dispatch = useDispatch()

  return (
    <div
      className={merged}
      onClick={() => {
        dispatch(unblockUser(id))
      }}>
      {children}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
