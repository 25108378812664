import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { withModal } from 'hoc/withModal/withModal'
import { TranslatedText, Button } from 'components/atoms'
import { ModalProps } from 'models'
import { useDispatch } from 'react-redux'
import { deleteModal } from 'store/modal'
import { useTranslation } from 'react-i18next'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { ReactComponent as CalendarIcon } from '../../../resources/images/icons/calendar.svg'
import * as _ from 'lodash'
import { setDateRangeFilterField } from '../../../store/filtering'
import { addNotification } from '../../../store/notification'
import { useFirestore } from '../../../hoc/FirebaseProvider'
import { useHistory } from 'react-router-dom'
import { Vacation } from '../../../models/Vacation'

interface SetVacationDialogProps extends ModalProps {
  className?: string
  collection?: string
}

//TODO: RULES

const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5 pt-10'
const BUTTON_WRAPPER_CLASSES = 'mt-5 p-5 flex justify-center items-center'
const TEXT_CENTER = 'block text-center'

export const SetVacationDialog = (props: SetVacationDialogProps) => {
  const { className, collection = '', id, action } = props

  const { t } = useTranslation(['setVacation'])
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx('pb-10', classes.component, className)
  const [dateRange, setDateRange] = useState<any>()
  const [vacationActive, setVacationActive] = useState<boolean>(false)
  const [vacationStart, setVacationStart] = useState<string>()
  const [vacationEnd, setVacationEnd] = useState<string>()
  const db = useFirestore()
  const history = useHistory()
  const userDocRef = db.collection('users').doc(action.productIds)

  useEffect(() => {
    const vacationDocRef = db.collection('vacation').doc(action.productIds)
    const fetchUser = async () => {
      const doc = await vacationDocRef.get()
      if (doc.exists) {
        const data = await doc.data()
        setVacationActive(!!data!.active)
        setVacationEnd(new Date(data!.vacationDates[0].vacation_end.seconds * 1000).toLocaleDateString('LT'))
        setVacationStart(new Date(data!.vacationDates[0].vacation_start.seconds * 1000).toLocaleDateString('LT'))
      }
    }
    if (action.productIds) {
      fetchUser()
    }
  }, [db, action.productIds, userDocRef])

  const dispatch = useDispatch()

  const handleSubmit = async (values: any) => {
    values.preventDefault()
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)

    if (dateRange[0] < currentDate) {
      dispatch(addNotification('error', t('userDetails:errorDate/holidayDate')))
      return false
    }
    await update(dateRange, false).catch(e => console.log(e, e.message))
    dispatch(deleteModal(id))
    dispatch(addNotification('success', t('vacationSubmitted')))
    return history.push('/product')
  }
  const handleDisableSubmit = async (values: any) => {
    values.preventDefault()
    await update(dateRange, true).catch(e => console.log(e, e.message))
    dispatch(deleteModal(id))
    dispatch(addNotification('success', t('vacationDisabled')))
    return history.push('/product')
  }

  async function update(daterange: any, toDelete: boolean) {
    const vacationDocRef = db.collection('vacation').doc(action.productIds)
    const vacationDoc = await db.collection('vacation').doc(action.productIds).get()
    let vacation: Vacation | undefined = vacationDoc.data() as Vacation

    if (toDelete && vacation) {
      vacation.vacationDates[0].vacation_end = new Date()
      vacation.active = false
      await userDocRef.set({ vacation: false }, { merge: true })
    } else {
      await userDocRef.set({ vacation: dateRange[0] < new Date() }, { merge: true })
      if (vacation) {
        vacation.active = true
        vacation.vacationDates.unshift({
          vacation_set: new Date(),
          vacation_start: daterange[0],
          vacation_end: daterange[1],
        })
      } else {
        vacation = {
          active: true,
          vacationDates: [{ vacation_set: new Date(), vacation_start: daterange[0], vacation_end: daterange[1] }],
        }
      }
    }
    await vacationDocRef.set(vacation, { merge: true })
  }

  /***
   * */
  const cancelAction = () => {
    dispatch(deleteModal(id))
  }

  const handleDateChange = (selected: [Date, Date]) => {
    if (selected) {
      setDateRange(selected)
      updateSelect(selected)
    } else {
      // On last chip removing, value is retured as null
      updateSelect([])
      setDateRange(undefined)
    }
  }

  const updateSelect = useCallback(
    _.debounce((data: any) => {
      dispatch(setDateRangeFilterField(id, data || []))
    }, 100),
    []
  )

  return !vacationActive ? (
    <div className={merged}>
      <div className="w-96 flex justify-center my-0 mx-auto pb-2">
        <div className="w-64">
          <TranslatedText className={TITLE_CLASSES} collection={collection} id="enableVacation" />
          <TranslatedText collection={collection} id="vacationDate" />
          <DateRangePicker
            className={cx(classes.datePicker)}
            onChange={handleDateChange}
            value={dateRange}
            locale="lt-LT"
            format="yyyy-MM-dd"
            calendarIcon={<CalendarIcon className={classes.icon} />}
          />
          <br />
          <br />
          <TranslatedText collection={collection} id="vacayInfo" />
          <div className={BUTTON_WRAPPER_CLASSES}>
            <form className="flex" onSubmit={handleSubmit}>
              <Button className="mr-2" type="submit" disabled={!dateRange}>
                <TranslatedText collection={collection} id="okText" />
              </Button>
              <Button color="secondary" onClick={cancelAction}>
                <TranslatedText collection={collection} id="cancelText" />
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={merged}>
      <div className="w-96 flex justify-center my-0 mx-auto">
        <div className="w-64">
          <p>
            <TranslatedText className={TITLE_CLASSES} collection={collection} id="disableVacation" />
            <TranslatedText className={TEXT_CENTER} collection={collection} id="vacationEnabled" />
            {console.log(vacationStart)}
            <span className={TEXT_CENTER}>
              {t('VacationFrom')} {vacationStart} {t('VacationTo')} {vacationEnd}
            </span>
            <br />
            <TranslatedText className={TEXT_CENTER} collection={collection} id="vacationDisable" />
          </p>
          <div className={BUTTON_WRAPPER_CLASSES}>
            <form className="flex" onSubmit={handleDisableSubmit}>
              <Button className="mr-2" type="submit">
                <TranslatedText collection={collection} id="disableButton" />
              </Button>
              <Button color="secondary" onClick={cancelAction}>
                <TranslatedText collection={collection} id="cancelText" />
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
  datePicker: css`
    .react-daterange-picker__calendar {
      left: -40px !important;
    }
    .react-daterange-picker__wrapper {
      background-color: hsl(0, 0%, 100%);
      border-color: #e2e8f0;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      padding: 4.5px 5px;

      .react-daterange-picker__calendar-button {
        order: -1;
      }
    }
    .react-daterange-picker-holiday {
      width: 350px;
    }
  `,
  icon: css`
    height: 19px;
    width: 19px;
  `,
  holiday: css`
    width: 350px;
  `,
})

export const SetVacationDialogModal = withModal(SetVacationDialog)
