import React, { useEffect, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { NavList, SettingsMenu } from 'components/organisms'
import { ReactComponent as Balances } from '../../../resources/images/icons/dashboard.svg'
import { ReactComponent as Orders } from '../../../resources/images/icons/orders.svg'
import { ReactComponent as Products } from '../../../resources/images/icons/product.svg'
import { ReactComponent as Users } from '../../../resources/images/icons/users.svg'
import { ReactComponent as Faq } from '../../../resources/images/icons/info.svg'
import { ReactComponent as UserSvg } from '../../../resources/images/icons/user.svg'

import { UserCard } from 'components/atoms'
import { useSelector } from 'react-redux'
import { getUser } from 'store/user'

interface MenuItem {
  icon: JSX.Element
  url: string
  category: string
}

interface SideNavigationLayoutProps {
  className?: string
  children?: React.ReactNode
}

const SIDE_NAV_STYLE = 'h-screen flex overflow-hidden bg-gray-100 w-full'
const TITLE_CLASSES = 'non-italic font-bold text-2xl leading-7 pt-3 pb-10'

export const SideNavigationLayout = (props: SideNavigationLayoutProps) => {
  const { className, children } = props
  const [isOpen, setIsOpen] = useState(false)

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(SIDE_NAV_STYLE, classes.component, className)

  const user = useSelector(getUser)
  const [photoUrl, setPhotoUrl] = useState<string>()

  useEffect(() => {
    setPhotoUrl(user.profilePic)
  }, [user])

  const menuItems: MenuItem[] = [
    {
      icon: <Balances className={classes.svgIcon} />,
      url: '/balance',
      category: 'balances',
    },
    {
      icon: <Products className={classes.svgIcon} />,
      url: '/product',
      category: 'products',
    },
    {
      icon: <Orders className={classes.svgIcon} />,
      url: '/order',
      category: 'orders',
    },
    {
      icon: <Faq className={classes.svgIcon} />,
      url: '/faq',
      category: 'faq',
    },
    {
      icon: <UserSvg className={classes.svgIcon} />,
      url: `/user/${user.uid}`,
      category: 'profile',
    },
  ]

  if (user.role === 'admin') {
    menuItems.push({
      icon: <Users className={classes.svgIcon} />,
      url: '/seller',
      category: 'users',
    })
  }

  if (user.role === 'admin') {
    menuItems.push({
      icon: <Users className={classes.svgIcon} />,
      url: '/joined',
      category: 'joined',
    })
  }

  return (
    <div className={merged}>
      <div className={cx({ hidden: !isOpen }, 'md:hidden')}>
        <div className="fixed inset-0 flex z-40">
          <div className={cx({ hidden: !isOpen }, 'fixed inset-0')}>
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
          <div className={cx({ hidden: !isOpen }, 'relative flex-1 flex flex-col max-w-xs w-full bg-gray-900')}>
            <div className="absolute top-0 right-0 -mr-14 p-1">
              <button
                className={cx(
                  { hidden: !isOpen },
                  'flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600'
                )}
                aria-label="Close sidebar"
                onClick={() => setIsOpen(false)}>
                <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex flex-col items-center px-4 text-white">
                <h2 className={TITLE_CLASSES}>popup.lt</h2>
                {user.role === 'seller' && <UserCard username={user.brandName || user.name} photoUrl={photoUrl} />}
              </div>
              <nav className="mt-5 px-2">
                <NavList menuItems={menuItems} />
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4 justify-center">
              <div className="flex-shrink-0 group block focus:outline-none">
                <div className="flex items-center text-white">
                  <SettingsMenu />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 w-14"></div>
        </div>
      </div>
      <div className={cx({ hidden: !isOpen }, 'md:flex md:flex-shrink-0')}>
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1 bg-gray-900">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-col flex-shrink-0 px-4 text-white">
                <h2 className={TITLE_CLASSES}>popup.lt</h2>
                {user.role === 'seller' && (
                  <UserCard username={user.brandName || user.name} photoUrl={photoUrl} vacation={user.vacation} />
                )}
              </div>
              <nav className="mt-5 flex-1 px-2">
                <NavList menuItems={menuItems} />
              </nav>
              <div className="text-white text-center text-sm p-1">
                {' '}
                <b>Warning:</b> the significant growth and development of the platform are constrained by your product
                descriptions in Lithuanian. Please update all the information from Lithuanian to English asap.
              </div>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4 justify-center">
              <div className="flex-shrink-0 group block focus:outline-none">
                <div className="flex items-center text-white">
                  <SettingsMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
            aria-label="Open sidebar"
            onClick={() => {
              setIsOpen(true)
            }}>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0}>
          {children}
        </main>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  svgIcon: css`
    path {
      fill: currentColor;
    }
  `,
  svgIconInfo: css`
    path {
      fill: red;
    }
  `,
})
