import { REMOVE_PRODUCT, UNPUBLISH_PRODUCT, UPDATE_PRODUCT_DISCOUNT } from './constants'
import { combineEpics, ofType } from 'redux-observable'
import { from, Observable, of } from 'rxjs'

import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { app } from 'hoc/FirebaseProvider'
import { deleteModal } from 'store/modal'
import { push, go } from 'connected-react-router'
import { addNotification } from '../notification'

const deleteProductEpic = (action$: Observable<AnyAction>, $state: any, { translations }: any): Observable<any> =>
  action$.pipe(
    ofType(REMOVE_PRODUCT),
    switchMap(({ productIds, ...rest }: any) => {
      const db = app.firestore()
      // Get a new write batch
      const batch = db.batch()

      const getIds = (id: string[]) => {
        const arr: any[] = []
        id.forEach(i => {
          arr.push(db.collection('products').doc(i))
        })
        return arr
      }

      const productsCollectionRef = getIds(productIds)
      productsCollectionRef.forEach(async p => {
        await batch.set(p, { status: { deleted: true } }, { merge: true })
      })

      return of({ response: from(batch.commit()), props: rest })
    }),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          const actions = []
          actions.push(deleteModal(props.modalId))

          if (props.redirectAfterAction) {
            actions.push(push('/product'))
          }
          actions.push(addNotification('success', translations['productsPage']['product_deleted']))
          return actions
        })
      )
    ),
    catchError(error => {
      return of({ type: 'ERROR', error: error.code })
    })
  )

const unpublishProductEpic = (action$: Observable<AnyAction>, $state: any, { translations }: any): Observable<any> =>
  action$.pipe(
    ofType(UNPUBLISH_PRODUCT),
    switchMap(({ productIds, ...rest }: any) => {
      const db = app.firestore()
      // Get a new write batch
      const batch = db.batch()

      const getIds = (id: string[]) => {
        const arr: any[] = []
        id.forEach(i => {
          arr.push(db.collection('products').doc(i))
        })
        return arr
      }

      const productsCollectionRef = getIds(productIds)
      productsCollectionRef.forEach(async p => {
        await batch.set(p, { status: { published: false } }, { merge: true })
      })

      return of({ response: from(batch.commit()), props: rest })
    }),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          const actions = []
          actions.push(deleteModal(props.modalId))

          if (props.refreshPage) {
            actions.push(go(0))
          }

          if (props.redirectAfterAction) {
            actions.push(push('/product'))
          }

          actions.push(addNotification('success', translations['productsPage']['product_unpublished']))
          return actions
        })
      )
    ),
    catchError(error => {
      return of({ type: 'ERROR', error: error }, addNotification('error', error))
    })
  )

const updateProductDiscountEpic = (
  action$: Observable<AnyAction>,
  $state: any,
  { translations }: any
): Observable<any> =>
  action$.pipe(
    ofType(UPDATE_PRODUCT_DISCOUNT),
    switchMap(({ productIds, discount, ...rest }: any) => {
      const db = app.firestore()
      // Get a new write batch
      const batch = db.batch()

      const getIds = (id: string[]) => {
        const arr: any[] = []
        id.forEach(i => {
          arr.push(db.collection('products').doc(i))
        })
        return arr
      }

      const productsCollectionRef = getIds(productIds)
      productsCollectionRef.forEach(async p => {
        await batch.set(p, { discount: discount }, { merge: true })
      })

      return of({ response: from(batch.commit()), props: rest })
    }),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          const actions = []
          actions.push(deleteModal(props.modalId))

          if (props.refreshPage) {
            actions.push(go(0))
          }

          actions.push(addNotification('success', translations['productsPage']['product_discount_updated']))
          return actions
        })
      )
    ),
    catchError(error => {
      return of({ type: 'ERROR', error: error.code })
    })
  )

export const productEpics = combineEpics(deleteProductEpic, unpublishProductEpic, updateProductDiscountEpic)
