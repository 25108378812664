import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Icon, Input, InputLabel } from 'components/atoms'

import { ReactComponent as Search } from '../../../resources/images/icons/search.svg'

interface SearchInputProps {
  name: string
  label: string
  placeholder?: string
  className?: string
  children?: React.ReactNode
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  value: string
}

// BASED ON DESIGN  w-40 should come from top component
const COMPONENT_CLASSES = 'flex flex-col relative'
const LABEL_CLASSES = 'pb-1'
const INPUT_CLASSES =
  'appearance-none rounded-md relative block w-full pl-2 pr-7 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ICON_CLASSES = 'absolute top-9 right-3 z-10 text-gray-300'

export const SearchInput = ({
  className,
  handleChange,
  handleBlur,
  value,
  name,
  label,
  placeholder,
}: SearchInputProps) => {
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(COMPONENT_CLASSES, classes.component, className)

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES} htmlFor={name}>
        {label}
      </InputLabel>
      <Input
        name={name}
        type="search"
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={value}
        className={INPUT_CLASSES}
        placeholder={placeholder}
      />
      <Icon className={ICON_CLASSES} svgIcon={<Search className={classes.icon} />} height={16} width={16} />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
