import React from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'

interface ReturnInfoProps {
  className?: string
  deliveryType?: string
  returnTitle: string
  returnDetails?: string
}

export const TRANSLATION_BLOCK = 'orderDetailsPage'

export const ReturnInfo = (props: ReturnInfoProps) => {
  const { className, deliveryType = 'courier', returnTitle, returnDetails } = props
  const classes = styles()
  const merged = cx('flex flex-col mr-5', classes.container, className)
  const { t } = useTranslation(TRANSLATION_BLOCK)
  return (
    <div className={merged}>
      <div className="font-bold text-lg">{t('returnType')}</div>
      <div className="mt-2">{t(`shipping:${deliveryType}`)}</div>
      <div className="font-bold text-lg mt-5">{t('returnReason')}</div>
      <div className="mt-2">{returnTitle}</div>
      <div className="mt-2 max-w-xs">{returnDetails}</div>
    </div>
  )
}

const styles = () => ({
  container: css``,
})
