import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Input } from 'components/atoms/Input'
import { TranslatedText, Label, SelectInput } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { IOption } from '../../util'
import { useFirestore } from '../../../hoc/FirebaseProvider'

interface AddNewAccFormProps {
  className?: string
  formik: any
  disabled?: boolean
}

const INPUT_STYLE =
  'px-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ERROR_MSG_STYLE = 'text-xs text-red-500'
const FORM_STYLE = 'mt-6 w-full'

export const AddNewAccForm = (props: AddNewAccFormProps) => {
  const { className, formik, disabled = false } = props

  const [countries, setCountries] = useState<IOption[]>([])
  const [agreementNumber, setAgreementNumber] = useState<string>('')
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(FORM_STYLE, classes.component, className)
  const db = useFirestore()
  const setFieldValueRef = useRef(formik.setFieldValue)
  setFieldValueRef.current = formik.setFieldValue

  const { t } = useTranslation(['newUserForm'])

  const placeholderMap: { [key: string]: string } = {
    agreementNumber: t('agreementNumber'),
    companyName: t('companyName'),
    VATCode: t('VATCode'),
    marginPercentage: t('marginPercentage'),
    companyCode: t('companyCode'),
    bankAccountNumber: t('bankAccountNumber'),
    email: t('email'),
  }

  const setFieldValue = (type: string, event: any) => {
    const value = event ? event : []
    formik.setFieldValue(type, value)
  }

  //FETCH COUNTRIES
  useEffect(() => {
    const fetchCountries = async () => {
      const docRef = db.collection('countries').doc('countries')
      const doc = await docRef.get()
      const countriesArr: IOption[] = []
      if (doc.exists) {
        const list = doc.data()
        if (list) {
          list.countries.forEach((color: string) => {
            countriesArr.push({ label: color, value: color })
          })
          setCountries(countriesArr)
        }
      }
    }
    fetchCountries()
  }, [db])

  //FETCH Agreement Number
  useEffect(() => {
    const fetchAgreementNumber = async () => {
      const docRef = db.collection('common').doc('agreementNumber')
      const doc = await docRef.get()
      if (doc.exists) {
        const list = doc.data()
        if (list) {
          const numberValue = parseInt(list.numberOfAgreements.replace(/\D/g, ''), 10)
          const incrementedValue = 'WPS' + (numberValue + 1)
          setAgreementNumber(incrementedValue)
        }
      }
    }
    fetchAgreementNumber()
  }, [db])

  return (
    <div className={merged}>
      <div className="h-20 relative">
        <label htmlFor="agreementNumber">
          <TranslatedText collection="newUserForm" id="agreementNumberLabel" />
        </label>
        <Input
          name="agreementNumber"
          type="text"
          disabled={true}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.agreementNumber || agreementNumber}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['agreementNumber']}
        />
        {formik.errors.agreementNumber && formik.touched.agreementNumber ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newUserForm" id={formik.errors.agreementNumber} />}
          />
        ) : null}
      </div>
      <div className="h-20 relative">
        <label htmlFor="companyName">
          <TranslatedText collection="newUserForm" id="companyNameLabel" />
        </label>
        <Input
          name="companyName"
          type="text"
          disabled={false}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.companyName}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['companyName']}
        />
        {formik.errors.companyName && formik.touched.companyName ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newUserForm" id={formik.errors.companyName} />}
          />
        ) : null}
      </div>
      <div className="h-20 relative">
        <label htmlFor="country">
          <TranslatedText collection="newUserForm" id="country" />
        </label>
        <SelectInput
          name="country"
          noOptionsMessage="No Options"
          disabled={false}
          value={formik.values.country}
          options={countries}
          isMulti={false}
          closeMenuOnSelect={true}
          handleChange={(e: any) => setFieldValue('country', e)}
          handleBlur={formik.handleBlur}
        />
        {formik.errors.country && formik.touched.country ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newUserForm" id="errorRequired" />}
          />
        ) : null}
      </div>
      <div className="h-20 relative flex justify-between">
        <div>
          <label htmlFor="VATCode">
            <TranslatedText collection="newUserForm" id="VATCodeLabel" />
          </label>
          <Input
            name="VATCode"
            type="text"
            disabled={false}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.VATCode}
            required
            className={INPUT_STYLE}
            placeholder={placeholderMap['VATCode']}
          />
          {formik.errors.VATCode && formik.touched.VATCode ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="newUserForm" id={formik.errors.VATCode} />}
            />
          ) : null}
        </div>
        <div>
          <label htmlFor="marginPercentage">
            <TranslatedText collection="newUserForm" id="marginPercentageLabel" />
          </label>
          <Input
            name="marginPercentage"
            type="text"
            disabled={disabled}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.marginPercentage}
            required
            className={INPUT_STYLE}
            placeholder={placeholderMap['marginPercentage']}
          />
          {formik.errors.marginPercentage && formik.touched.marginPercentage ? (
            <Label
              className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
              msg={<TranslatedText collection="newUserForm" id={formik.errors.marginPercentage} />}
            />
          ) : null}
        </div>
      </div>
      <div className="h-20 relative">
        <label htmlFor="companyCode">
          <TranslatedText collection="newUserForm" id="companyCodeLabal" />
        </label>
        <Input
          name="companyCode"
          type="text"
          disabled={false}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.companyCode}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['companyCode']}
        />
        {formik.errors.companyCode && formik.touched.companyCode ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newUserForm" id={formik.errors.companyCode} />}
          />
        ) : null}
      </div>
      <div className="h-20 relative">
        <label htmlFor="bankAccountNumber">
          <TranslatedText collection="newUserForm" id="bankAccountNumberLabel" />
        </label>
        <Input
          name="bankAccountNumber"
          type="text"
          disabled={false}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.bankAccountNumber}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['bankAccountNumber']}
        />
        {formik.errors.bankAccountNumber && formik.touched.bankAccountNumber ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newUserForm" id={formik.errors.bankAccountNumber} />}
          />
        ) : null}
      </div>
      <div className="h-20 relative">
        <label htmlFor="email">
          <TranslatedText collection="newUserForm" id="emailLabel" />
        </label>
        <Input
          name="email"
          type="email"
          disabled={disabled}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.email}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['email']}
        />
        {formik.errors.email && formik.touched.email ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="newUserForm" id={formik.errors.email} />}
          />
        ) : null}
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  errorMsg: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
})
