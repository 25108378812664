import { NotificationModel } from './actions'
import { store } from 'react-notifications-component'

export const addNotificationImp = (notification: NotificationModel) => {
  let componentType: 'success' | 'danger' | 'info' | 'default' | 'warning'

  switch (notification.type) {
    case 'info':
      componentType = 'info'
      break
    case 'error':
      componentType = 'danger'
      break
    case 'success':
      componentType = 'success'
      break

    default:
      componentType = 'info'
  }
  store.addNotification({
    message: notification.message,
    type: componentType,
    insert: 'bottom',
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: 500,

    dismiss: {
      duration: 3000,
      onScreen: false,
      pauseOnHover: true,
      showIcon: false,
    },
    slidingExit: {
      duration: 500,
      timingFunction: 'linear',
      delay: 0,
    },
    slidingEnter: {
      duration: 500,
      timingFunction: 'linear',
      delay: 0,
    },
  })
}
