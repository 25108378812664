import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { css, cx } from 'emotion'

import { SimpleTable } from 'components/molecules/Table/SimpleTable'
import { OrderProduct, OrderProductsDetailTable } from 'models/Order'
import { useOrderProductsColumn } from './useOrderProductsColumn'

interface OrderProductProps {
  className?: string
  products?: OrderProduct[]
  showStatus?: boolean
  push: typeof push
}

export const OrderProductsTableComponent = (props: OrderProductProps) => {
  const { className, products, showStatus = true, push } = props
  const classes = styles()
  const merged = cx('w-full', classes.container, className)
  const [data, setData] = useState<OrderProductsDetailTable[]>([])

  const columns = useOrderProductsColumn({ push })

  const initialState = useMemo(() => {
    const state = {
      hiddenColumns: ['docId', 'id', 'imageUrl'],
    }
    if (!showStatus) {
      state.hiddenColumns.push('status')
    }
    return state
  }, [showStatus])

  useEffect(() => {
    const tableRows: OrderProductsDetailTable[] = []
    products?.forEach(prod => {
      const imageUrl =
        prod.images && prod.images.length > 0 ? prod.images[0].thumbnail || prod.images[0].url : undefined

      tableRows.push({
        id: prod.productId,
        docId: prod.productDocId,
        imageUrl,
        title: prod.name,
        size: prod.size,
        price: prod.price,
        amount: prod.amount || 1,
        priceTotal: prod.totalPrice || prod.price * (prod.amount || 1),
        status: prod.status,
      })
    })

    setData(tableRows)
  }, [products])

  return <SimpleTable className={merged} initialState={initialState} columns={columns} data={data} />
}

const styles = () => ({
  container: css``,
})

export const OrderProductsTable = connect(undefined, { push })(OrderProductsTableComponent)
