import * as React from 'react'
import { css, cx } from 'emotion'
import Modal from 'react-modal'

const MODAL_STYLE = 'flex  w-full h-full justify-center items-center'

export const ModalSpinner = () => {
  const classes = styles()
  return (
    <Modal className={MODAL_STYLE} isOpen>
      <div className={cx(classes.spinner, 'animate-spin rounded-full border-8 h-40 w-40')} />
    </Modal>
  )
}

const styles = () => ({
  spinner: css`
    border-top-color: gray;
  `,
})
