import React from 'react'
import { cx, css } from 'emotion'

interface PaginationItemProps {
  className?: string
  text: string
  id: number
  activePage: number
  withBorders?: boolean
  handleClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const PAGINATION_ITEM_CLASSES =
  'cursor-pointer -ml-px relative inline-flex items-center px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'

export const PaginationItem = (props: PaginationItemProps) => {
  const { className, text, activePage, id, withBorders = false, handleClick = () => {} } = props
  const classes = styles(activePage, id)
  const merged = cx(
    PAGINATION_ITEM_CLASSES,
    { 'bg-gray-700 text-white': activePage === id },
    { 'border border-gray-300': withBorders },
    classes.component,
    className
  )

  return (
    <span onClick={handleClick} className={merged}>
      {text}
    </span>
  )
}

const styles = (activePage: number, id: number) => ({
  component: css`
    color: ${activePage === id ? 'white' : 'initial'};
  `,
})
