import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ReactComponent as Email } from '../../../resources/images/icons/email.svg'
import { TranslatedText, Label, Input, Icon } from '../../atoms'

interface SimpleEmailFormProps {
  className?: string
  children?: React.ReactNode
  isQueryFailed?: boolean
  collection: string
  sendResetEvent: (email: string) => void
  clearMessages: () => void
}

interface SimpleEmailModel {
  email: string
}

// Text is used as id for translation
const ForgotPassSchema = Yup.object({
  email: Yup.string().email('invalidEmail').required('required'),
})

const INPUT_CLASSES =
  'pl-8 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ICON_CLASSES = 'absolute top-3 left-2 z-10 text-gray-300'
const BUTTON_CLASSES =
  'outline-none align-baseline font-bold py-2 px-4 rounded text-sm bg-black text-white hover:text-gray-100 border border-black border-solid w-full h-10'
const BUTTON_DISABLED = 'opacity-50 cursor-not-allowed'

const emailIconWithStyles: JSX.Element = (
  <Email
    className={css`
      path {
        fill: currentColor;
      }
    `}
  />
)

export const SimpleEmailForm = (props: SimpleEmailFormProps) => {
  const { className, isQueryFailed = false, collection, sendResetEvent, clearMessages } = props
  const { t } = useTranslation([collection])
  const [disabled, setDisabled] = useState(false)

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  useEffect(() => {
    if (isQueryFailed) {
      setDisabled(false)
    }
  }, [isQueryFailed])

  const formik = useFormik<SimpleEmailModel>({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPassSchema,
    onSubmit: values => {
      clearMessages()
      if (!formik.isValidating && formik.isSubmitting) {
        setDisabled(true)
        sendResetEvent(values.email)
      }
    },
  })

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setDisabled(false)
    clearMessages()

    formik.handleChange(event)
  }

  return (
    <div className={merged}>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className={cx(classes.group, 'relative')}>
          <Input
            type="email"
            name="email"
            className={cx(INPUT_CLASSES, classes.input)}
            handleChange={handleOnChange}
            handleBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder={t('inputPlaceholder')}
          />
          <Icon className={ICON_CLASSES} svgIcon={emailIconWithStyles} height={16} width={20} />
          {formik.errors.email && formik.touched.email ? (
            <Label
              className={cx(classes.errorMsg, 'text-xs text-red-500 text-center')}
              msg={
                <TranslatedText
                  className={classes.errMSgTranslation}
                  collection={collection}
                  id={formik.errors.email}
                />
              }
            />
          ) : undefined}
        </div>
        <button
          disabled={disabled || !(formik.isValid && formik.dirty)}
          className={cx({ [BUTTON_DISABLED]: disabled || !(formik.isValid && formik.dirty) }, BUTTON_CLASSES)}
          type="submit">
          <TranslatedText collection={collection} id="buttonText" />
        </button>
      </form>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
  form: css``,
  input: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  group: css`
    min-height: 72px;
  `,
  errorMsg: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  errMSgTranslation: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
})
