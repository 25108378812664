import React, { useState, useCallback, useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useDispatch } from 'react-redux'
import * as _ from 'lodash'
import { TreeNode } from 'react-dropdown-tree-select'

import { CategoryFilterInput } from 'components/molecules/CategoryFilterInput'
import { setCategoryFilterField } from 'store/filtering'
import { Category } from '../../../models/Category'
import { useFirestore } from 'hoc/FirebaseProvider'

interface CategoryFilterInputContainerProps {
  className?: string
  id: string
}

// h*16 temporary
const CATEGORY_SELECT_CLASSES = 'w-40'

export const CategoryFilterInputContainer = (props: CategoryFilterInputContainerProps) => {
  const { className, id } = props
  const db = useFirestore()

  const [categories, setCategories] = useState<Category[]>([])

  const dispatcher = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CATEGORY_SELECT_CLASSES, classes.component, className)

  // FETCH categories
  useEffect(() => {
    const categoriesConverter = {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ) {
        const data = snapshot.data(options)
        const categories: TreeNode = {
          label: data.name,
          value: data.id,
          children: [],
        }

        if (data.children) {
          Object.values(data.children).forEach((child: any) => {
            if (child.children) {
              const values = [] as Category[]
              Object.values(child.children).forEach((ch: any) => {
                values.push(new Category(ch.name, ch.id, null, [data.id, child.id], [data.name, child.name]))
              })
              categories.children.push({ label: child.name, value: child.id, children: _.sortBy(values, 'label') })
            } else {
              categories.children.push(new Category(child.name, child.id, null, [data.id], [data.name]))
            }
          })
        }
        return categories
      },
    }

    async function fetchData(converter: any) {
      try {
        const docRef = db.collection('productCategories')
        const querySnapshot = await docRef.withConverter(converter as any).get()
        const categories: Category[] = []

        querySnapshot.forEach((doc: any) => {
          const category = doc.data()
          categories.push(category)
        })
        setCategories(categories)
      } catch (error) {
        console.error('Categories are not fetched', error)
      }
    }

    fetchData(categoriesConverter)
  }, [db])

  const updateSelect = useCallback(
    _.debounce((data: any) => {
      dispatcher(setCategoryFilterField(id, data || []))
    }, 100),
    []
  )

  const handleChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    const selected = selectedNodes.map((category: TreeNode) => {
      if (category._depth > 0) {
        return category.values
      } else {
        return category.value
      }
    })
    updateSelect(selected)
  }

  return <CategoryFilterInput className={merged} data={categories} handleChange={handleChange} />
}

const styles = (theme: any) => ({
  component: css`
    min-width: 10rem;
    width: auto;
    max-width: 30rem;
  `,
})
