import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface InputLabelProps {
  className?: string
  children?: React.ReactNode
  htmlFor?: string
}

const LABEL_CLASSES = 'text-gray-300 text-base leading-5 font-medium'

export const InputLabel = (props: InputLabelProps) => {
  const { className, children, htmlFor } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(LABEL_CLASSES, classes.component, className)

  return (
    <label htmlFor={htmlFor} className={merged}>
      {children}
    </label>
  )
}

const styles = (theme: any) => ({
  component: css`
    font-family: inherit;
  `,
})
