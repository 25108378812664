import React, { useEffect, useMemo, useState } from 'react'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'hoc/FirebaseProvider'
import moment from 'moment'
import { get } from 'lodash'

import { AppState } from 'store/reducers'
import { getPagination } from 'store/pagination'
import { getFilter, setFilter } from 'store/filtering'
import { DATE_FORMAT } from 'components/util'
import { LabelBadge } from 'components/atoms/LabelBadge'
import { Table } from 'components/molecules/Table'
import { Order, OrderProduct, OrderProductsTable, SellerOrder } from 'models/Order'
import { addNotification } from 'store/notification'

import { PAGE_SIZE } from '../PagerFilter'
import { OrderProductsActionMenu } from './OrderProductsActionMenu'
import { getCurrentUser } from 'store/user'

interface ListContainerProps {
  tableId: string
  orderType?: string
  userId?: string
}

export const OrderProductsListContainer = ({ tableId, orderType, userId }: ListContainerProps) => {
  const [products, setProducts] = useState<OrderProductsTable[]>([])

  const dispatch = useDispatch()
  const { t } = useTranslation(['ordersPage'])

  const db = useFirestore()
  const pagination = useSelector((state: AppState) => getPagination(state, tableId))
  const filter = useSelector((state: AppState) => getFilter(state, tableId))
  const user = useSelector((state: AppState) => getCurrentUser(state))
  const data = useMemo(() => products as OrderProductsTable[], [products])

  const isAdmin = useMemo(() => {
    return user.role === 'admin'
  }, [user])

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'disabled', desc: false }],
      pageIndex: get(pagination, 'initialPage', 0),
      pageSize: get(pagination, 'pageSize', PAGE_SIZE),
      hiddenColumns: ['productId', 'sellerOrderId', 'orderKey'],
    }),
    [pagination]
  )

  const filterByColumnID = useMemo(() => {
    const filterByColumnID = new Map<string, string>()
    filterByColumnID.set('statuses', 'status')
    filterByColumnID.set('date', 'returnDate')
    return filterByColumnID
  }, [])

  const filterByMonth = useMemo(() => {
    return (rows: Row[], id: string, filterValue: Date) => {
      return rows.filter(row => {
        if (!filterValue) {
          return true
        }

        const returned: Date = row.values['returnDate']

        if (!returned) {
          return false
        }

        const rowDate = moment(returned)
        const filterDate = moment(filterValue)

        return rowDate.month() === filterDate.month() && rowDate.year() === filterDate.year()
      })
    }
  }, [])

  const filterByStatus = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        if (filterValue.length === 0 || filterValue[0] === 'all') {
          return true
        }
        const value = row.values['status']
        return value === filterValue[0]
      })
    }
  }, [])

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'orderKey',
        },
        {
          accessor: 'productId',
        },
        {
          Header: t('orderId'),
          accessor: 'orderId',
          width: 80,
        },
        {
          Header: t('orderId'),
          accessor: 'sellerOrderId',
          width: 80,
        },
        {
          Header: t('productName'),
          accessor: 'productName',
          width: 100,
        },
        {
          Header: t('returnReason'),
          accessor: 'returnReason',
          width: 100,
        },
        {
          Header: t('productCount'),
          accessor: 'productCount',
          Cell: ({ row }) => {
            const value = row.values['productCount']
            return <div>{value}</div>
          },
          width: 100,
        },
        {
          Header: t('orderValue'),
          accessor: 'price',
          Cell: ({ row }) => {
            const value = row.values['price'] as number
            const dispValue = value !== undefined ? `${value.toFixed(2)} €` : ''
            return <div>{dispValue}</div>
          },
          width: 120,
        },
        {
          Header: t('returnDate'),
          accessor: 'returnDate',
          Cell: ({ row }) => {
            const value = row.values['returnDate']
            const strDate = value && moment(value).format(DATE_FORMAT)
            return <div>{strDate || 'N/D'}</div>
          },
          filter: filterByMonth,
          sortType: 'date',
          width: 120,
        },
        {
          Header: t('status'),
          accessor: 'status',
          width: 160,
          Cell: ({ row }) => {
            const statusCode = row.values['status']
            return <LabelBadge size="sm" text={t(`status:${statusCode}`)} />
          },
          filter: filterByStatus,
        },
        {
          Header: '',
          accessor: 'actions',
          width: 40,
          sticky: 'right',
          disableSortBy: true,
          Cell: ({ row }) => {
            const productId = row.values.productId
            const sellerOrderId = row.values.sellerOrderId
            const orderKey = row.values.orderKey
            const returnInfo = (row.original as any).return
            const status = row.values.status
            return (
              <OrderProductsActionMenu
                productId={productId}
                returnInfo={returnInfo}
                orderKey={orderKey}
                sellerOrderId={sellerOrderId}
                currentStatus={status}
              />
            )
          },
        },
      ] as Array<Column>,
    [t, filterByMonth, filterByStatus]
  )

  useEffect(() => {
    const sellerProductConverter: firebase.firestore.FirestoreDataConverter<OrderProductsTable[]> = {
      toFirestore(doc: OrderProductsTable[]): Order {
        throw new Error('Not implemented')
      },
      fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<Order>,
        options: firebase.firestore.SnapshotOptions
      ): OrderProductsTable[] {
        const orderData: Order = snapshot.data(options)
        const orderKey = snapshot.id
        const products: OrderProductsTable[] = []
        const addReturns = (sellerOrder: SellerOrder): any => {
          sellerOrder.productSnapshots?.forEach((prod: OrderProduct) => {
            const ret = prod.return
            if (ret && prod.status === orderType) {
              products.push({
                productId: prod.productId,
                sellerOrderId: sellerOrder.orderId,
                orderId: orderData.orderId,
                orderKey: orderKey,
                productName: prod.name,
                returnReason: ret.reason,
                price: Number(prod.totalPrice || prod.price * (prod.amount || 1)),
                productCount: prod.amount || 1,
                returnDate: ret.date?.toDate(),
                status: prod.status,
                return: ret,
              })
            }
          })
        }
        if (isAdmin && !userId) {
          orderData.sellerOrders?.forEach((sellerOrder: SellerOrder) => {
            addReturns(sellerOrder)
          })
        } else {
          const sellerOrder = orderData.sellerOrders?.find(
            (sellerOrder: SellerOrder) => sellerOrder.userId === userId || sellerOrder.userId === user.uid
          )

          if (sellerOrder) {
            addReturns(sellerOrder)
          }
        }
        return products
      },
    }

    async function fetchData(converter: firebase.firestore.FirestoreDataConverter<OrderProductsTable[]>) {
      try {
        const docRef = db.collection('orders')
        const querySnapshot = await docRef.withConverter(converter).get()
        const products: OrderProductsTable[] = []

        querySnapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<OrderProductsTable[]>) => {
          const orderProducts = doc.data()
          products.push(...orderProducts)
        })

        setProducts(products)
      } catch (error) {
        console.error('Orders are not fetched', error)
        dispatch(addNotification('error', t('fetch_error')))
      }
    }

    fetchData(sellerProductConverter)
  }, [orderType, db, dispatch, t, isAdmin, user, userId])

  useEffect(() => {
    dispatch(setFilter(tableId, undefined))
  }, [dispatch, tableId, orderType])

  return (
    <Table
      tableId={tableId}
      columns={columns}
      data={data}
      paginationOptions={pagination}
      filterOptions={filter}
      initialState={initialState}
      filterMapByColumnId={filterByColumnID}
    />
  )
}
