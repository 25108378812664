import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { SideNavigationLayout } from 'components/templates/SideNavigationLayout'
import { Button, TranslatedText } from 'components/atoms'
import { useDispatch } from 'react-redux'
import { setModal } from 'store/modal'
import { useTranslation } from 'react-i18next'
import { CommonPageLayout } from 'components/templates'

interface UsersProps {
  className?: string
}

export const UsersPage = (props: UsersProps) => {
  const { className } = props
  const { t } = useTranslation('usersPage')

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const dispatch = useDispatch()

  return (
    <SideNavigationLayout>
      <CommonPageLayout pageTitle={t('title')} className={merged}>
        <Button onClick={() => dispatch(setModal('AddNewUser'))}>
          <TranslatedText collection="usersPage" id="newUser" />
        </Button>
      </CommonPageLayout>
    </SideNavigationLayout>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
