import React, { ComponentType } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useSelector } from 'react-redux'
import { getAllModals } from 'store/modal'
import { ModalModel } from 'models'
import { createPortal } from 'react-dom'
import { ModalRegistry } from 'hoc/withModal/ModalRegistry'

interface ModalHolderProps {
  className?: string
}

const container = document.getElementById('modal') as HTMLElement

export const ModalHolder = (props: ModalHolderProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component)
  const list = useSelector(getAllModals)
  const child = (
    <div className={merged}>
      {list.map((modal: ModalModel) => {
        const Component = ModalRegistry[modal.modalType] as ComponentType<any>
        return <Component className={className} key={modal.id} id={modal.id} {...modal.props} />
      })}
    </div>
  )

  return createPortal(child, container)
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
