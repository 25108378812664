import React from 'react'
import { cx, css } from 'emotion'

interface IconProps {
  className?: string
  svgIcon: JSX.Element
  height: number
  width: number
  onClick?: Function
}

export const Icon = (props: IconProps) => {
  const { svgIcon, className, height = 80, width = 80, onClick = () => {} } = props
  const classes = styles()
  const mergedStyle = cx(
    classes.icon,
    css`
      height: ${height}px;
      width: ${width}px;
    `,
    className
  )
  return (
    <span className={mergedStyle} onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => onClick(event)}>
      {svgIcon}
    </span>
  )
}

const styles = () => ({
  icon: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
})
