import React from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { DateRangePickerFilterContainer } from '../DateRangePickerFilterContainer'
import { OrderTypeFilter } from '../OrderTypeFilter'

interface FilterContainerProps {
  id: string
  orderType?: string
  className?: string
}

export const OrderOrdersFilterContainer = ({ id, className, orderType }: FilterContainerProps) => {
  const { t } = useTranslation(['ordersPage'])
  const classes = styles()
  const merged = cx('flex flex-row w-full justify-start flex-wrap', className)
  return (
    <div className={merged}>
      <OrderTypeFilter orderType={orderType} id={id} />
      <div className="flex flex-row  flex-shrink-0 ml-5">
        <DateRangePickerFilterContainer className={cx(classes.filter)} id={id} label={t('date')} key={Math.random()} />
      </div>
    </div>
  )
}

const styles = () => ({
  filter: css`
    &:not(:last-child) {
      margin-right: 24px;
    }
  `,
})
