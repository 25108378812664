import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { ReactComponent as Dots } from '../../../resources/images/icons/dotts.svg'
import { Icon } from 'components/atoms'

interface ActionsLayoutProps {
  className?: string
  children?: React.ReactNode
  isTable?: boolean
  right?: boolean
}

export const ActionsLayout = (props: ActionsLayoutProps) => {
  const { className, children, isTable = false, right = false } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const actionsMenuClasses = right ? 'left-8' : 'right-1'

  const closeMenu = (event: React.FocusEvent<any>) => {
    event.currentTarget.parentElement?.classList.remove('opened')
    event.currentTarget.children.item(1)?.classList.add('hidden')
  }

  const toggleMenuState = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    event.currentTarget.parentElement?.classList.toggle('opened')
    const activeElement = event.currentTarget.children.item(1)
    if (activeElement) {
      activeElement.classList.toggle('hidden')
      if (isTable) {
        if (event.currentTarget.parentElement && event.currentTarget.parentElement.offsetTop > 200) {
          activeElement.classList.add('bottom-0')
        }
      }
    }
  }

  return (
    <div
      className={cx(merged, 'flex justify-center content-center outline-none cursor-pointer w-full h-full')}
      tabIndex={0}
      onClick={toggleMenuState}
      onMouseLeave={closeMenu}
      onBlur={closeMenu}>
      <Icon className={classes.icon} svgIcon={<Dots />} width={40} height={40} />

      <div
        className={cx(
          actionsMenuClasses,
          'hidden actions-container origin-top-right absolute my-2 w-56 rounded-md shadow-lg z-10'
        )}>
        <div className="rounded-md bg-white shadow-xs">
          <div className="py-1" aria-orientation="vertical" aria-labelledby="options-menu">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
