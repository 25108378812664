import { combineEpics, Epic, ofType } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { deleteModal } from 'store/modal'
import { push, go } from 'connected-react-router'
import { CANCEL } from './constants'
import { addNotification } from '../notification'
import { AnyAction } from 'redux'

// @Deprecated : use "store/modal" epic
const cancelProfileChanges: Epic<any, any> = (
  action$: Observable<AnyAction>,
  $state: any,
  { translations }: any
): Observable<any> =>
  action$.pipe(
    ofType(CANCEL),
    switchMap((props: any) => {
      const actions = []
      actions.push(deleteModal(props.modalId))

      if (props.redirectAfterAction) {
        actions.push(push(props.url))
      }

      if (props.reloadAfterAction) {
        actions.push(go(0))
      }

      if (props.formik) {
        props.formik.resetForm()
      }

      actions.push(addNotification('success', translations['profile']['changes_cancelled']))

      return actions
    }),
    catchError(error => {
      return of({ type: 'ERROR', error: error })
    })
  )

export const profileEpics = combineEpics(cancelProfileChanges)
