import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import lt from './locale/en.json'

export const DEFAULT_LANGUAGE = 'lt'

i18n.use(initReactI18next).init({
  resources: {
    lt,
  },
  lng: 'lt-LT',
  fallbackLng: DEFAULT_LANGUAGE,
  ns: [
    'login',
    'sidebar',
    'forgotForm',
    'globalEvents',
    'newUserForm',
    'productsPage',
    'usersPage',
    'balancePage',
    'ordersPage',
    'imageUpload',
    'changePassForm',
    'removeImage',
    'userDetails',
    'profile',
    'newProductForm',
    'invoicePage',
    'resendInvite',
    'error',
    'removeProductsDialog',
    'addDiscountDialog',
    'orderDetailsPage',
    'status',
    'faqPage',
  ],
  debug: process.env.NODE_ENV !== 'production',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
})

export default i18n
