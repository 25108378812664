export const SET_FREE_TEXT_SEARCH = 'SET_FREE_TEXT_SEARCH'
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER'
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER'
export const SET_BRAND_FILTER = 'SET_BRAND_FILTER'
export const SET_DATERANGE_FILTER = 'SET_DATERANGE_FILTER'
export const SET_GLOBAL_FILTER = 'SET_GLOBAL_FILTER'
export const SET_FILTERING = 'SET_FILTERING'
export const SET_DATE_FILTER = 'SET_DATE_FILTER'

// reducer
export const FILTERING_STATE_KEY = 'filtering'
