import React from 'react'
import { Redirect } from 'react-router-dom'
import { css, cx } from 'emotion'
import { SideNavigationLayout } from 'components/templates/SideNavigationLayout'
import { AdminsGeneralContainer } from 'components/organisms/UsersProfileCointainer'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'store/user'
import { useAuth } from 'hoc/FirebaseProvider'

interface UsersProfilePageProps {
  className?: string
  children?: React.ReactNode
}

export const AdminProfilePage = (props: UsersProfilePageProps) => {
  const { className } = props

  const classes = styles()
  const merged = cx(classes.component, className)
  const auth = useAuth()
  const user = useSelector(getCurrentUser)

  let redirect
  if (auth.currentUser) {
    if (user.role !== 'admin') {
      const redirectTo = user.uid ? `/user/${user.uid}` : `/product`
      redirect = <Redirect to={redirectTo} />
    }
  } else {
    redirect = <Redirect to="/admin" />
  }

  return (
    redirect || (
      <SideNavigationLayout className={merged}>
        <div className="p-6">
          <AdminsGeneralContainer className="max-w-xl abolute left-0 right-0 mx-auto my-0" />
        </div>
      </SideNavigationLayout>
    )
  )
}

const styles = () => ({
  component: css``,
})
