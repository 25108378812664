import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import * as _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'hoc/FirebaseProvider'

import { InputLabel } from 'components/atoms/InputLabel'
import { SelectInput, TranslatedText } from 'components/atoms'
import { getBrandsField, setBrandFilterField } from 'store/filtering'
import { AppState } from 'store/reducers'

interface BrandFilterContainerProps {
  className?: string
  children?: React.ReactNode
  id: string
  userIdSelected?: string
}

const CONTAINER_CLASSES = 'w-48'
const LABEL_CLASSES = 'pb-1'

export const BrandFilterContainer = (props: BrandFilterContainerProps) => {
  const { className, userIdSelected, id } = props
  const db = useFirestore()
  const [brands, setBrands] = useState<any[]>([])
  const dispatcher = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CONTAINER_CLASSES, classes.component, className)

  const updateSelect = useCallback(
    _.debounce((data: any) => {
      dispatcher(setBrandFilterField(id, data || []))
    }, 100),
    []
  )

  // FETCH categories
  useEffect(() => {
    const brandConverter = {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ) {
        const data = snapshot.data(options)
        return {
          label: data.brandName,
          value: data.uid,
        }
      },
    }

    async function fetchData(converter: any, userId?: string) {
      try {
        const docRef = db.collection('users')
        const querySnapshot = await docRef
          .withConverter(converter as any)
          .where('role', '==', 'seller')
          .get()
        const brands: any[] = []
        let idsSelected: string[] = []
        querySnapshot.forEach((doc: any) => {
          const brand = doc.data()
          if (brand.value) {
            if (userId && brand.value === userId) {
              idsSelected.push(brand.value)
            }
            brands.push(brand)
          }
        })
        setBrands(brands)
        if (idsSelected.length > 0) {
          updateSelect(idsSelected)
        }
      } catch (error) {
        console.error('Brands are not fetched', error)
      }
    }

    fetchData(brandConverter, userIdSelected)
  }, [db, id, updateSelect, userIdSelected])

  const handleChange = (selected: [{ label: string; value: string }]) => {
    if (selected) {
      const onlyValues = selected.map(item => item.value)
      updateSelect(onlyValues)
    } else {
      // On last chip removing, value is retured as null
      updateSelect([])
    }
  }
  const brandIds: string[] = useSelector((state: AppState) => getBrandsField(state, id))
  const brandsSelected = brandIds && brands.filter(brand => brandIds.find(id => id === brand.value))

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES} htmlFor="productSearch">
        <TranslatedText collection="productsPage" id="brandLabel" />
      </InputLabel>
      <SelectInput isMulti={true} options={brands} handleChange={handleChange} value={brandsSelected} />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
