import { v4 as uuidv4 } from 'uuid'
import { ReactNode } from 'react'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'

export type NotificationType = 'success' | 'warn' | 'info' | 'error'

export interface NotificationModel {
  id: string
  type: NotificationType
  message: string | ReactNode
  time: Date
}

export interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION
  notification: NotificationModel
}

export const addNotification = (type: NotificationType, message: string | ReactNode): AddNotificationAction => ({
  type: ADD_NOTIFICATION,
  notification: { id: uuidv4(), type: type, message: message, time: new Date() },
})
