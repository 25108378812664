import React from 'react'
import { css, cx } from 'emotion'

interface SummaryLineProps {
  className?: string
  title: string
  value: number
  type?: 'normal' | 'bold'
}

export const SummaryLine = (props: SummaryLineProps) => {
  const { title, value, className, type = 'normal' } = props
  const classes = styles()
  const merged = cx('flex w-full flex-row justify-end p-2', classes.container, className)
  const decorationStyle = type === 'bold' ? 'text-xl font-bold' : ''
  return (
    <div className={merged}>
      <div className={cx('p-2 text-left', decorationStyle, classes.title)}>{title}</div>
      <div className={cx('p-2 text-right', decorationStyle, classes.value)}>{(value * 1).toFixed(2)} €</div>
    </div>
  )
}

const styles = () => ({
  container: css``,
  title: css`
    width: 200px;
  `,
  value: css`
    width: 100px;
  `,
})
