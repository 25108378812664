import React, { useState, useEffect } from 'react'
import { cx, css } from 'emotion'
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd'
import { Thumbnail } from 'components/molecules'
import { IProductImage } from 'components/util/interfaces'

interface DraggableThumbnailContainerProps {
  className?: string
  children?: React.ReactNode
  images: Array<any>
  setSelectedImage: (image: string) => void
  onDelete: (image: IProductImage) => void
  onImageReorder: (images: IProductImage[]) => void
}

export const DraggableThumbnailContainer = (props: DraggableThumbnailContainerProps) => {
  const { className, images, setSelectedImage, onDelete, onImageReorder } = props
  const [list, setList] = useState<IProductImage[]>([])

  const reorder = (list: IProductImage[], startIndex: number, endIndex: number): IProductImage[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result
    // If dropped outside the list
    if (!destination) {
      return
    }

    // If drop location has not changed
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    const items = reorder(list, source.index, destination.index)

    setList(items)
    onImageReorder(items)
  }

  useEffect(() => {
    setList(images)
  }, [images])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={cx(
              className,
              [{ 'border border-dashed border-gray-300 ': snapshot.isDraggingOver }],
              'flex overflow-auto py-4 pr-2 border border-transparent overflow-hidden'
            )}>
            {list.map((item: IProductImage, index: number) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={cx(
                      'select-none bg-white',
                      css`
                        :not(:last-child) {
                          margin-right: 0.5rem;
                        }
                      `
                    )}>
                    <Thumbnail
                      img={item.url}
                      setSelectedImage={() => setSelectedImage(item.url)}
                      onDelete={() => onDelete(item)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
