import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { css, cx } from 'emotion'
import { Order, SellerOrder } from 'models/Order'
import { setModal } from 'store/modal'
import { GeneralInfo } from './GeneralInfo'
import { AddressInfo } from './AddressInfo'
import { Divider } from 'components/atoms/Divider'
import { Button, TranslatedText, Icon } from 'components/atoms'
import { ReactComponent as Download } from 'resources/images/icons/download.svg'
import { getCurrentUser } from 'store/user'
import { InputField } from '../../molecules'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addNotification } from '../../../store/notification'
import { addError } from '../../../store/error'
import { useFirestore } from '../../../hoc/FirebaseProvider'
import { push } from 'connected-react-router'

export interface OrderDetailsProps {
  className?: string
  order: Order
  sellerOrder?: SellerOrder
}

export const TRANSLATION_BLOCK = 'orderDetailsPage'

export const OrderInfo = (props: OrderDetailsProps) => {
  const { className, order, sellerOrder } = props
  const { t } = useTranslation([TRANSLATION_BLOCK])
  const classes = styles()
  const merged = cx('flex flex-col w-full m-5', classes.container, className)
  const dispatch = useDispatch()
  const db = useFirestore()

  const user = useSelector(getCurrentUser)

  const openDialog = () => {
    dispatch(
      setModal('ShippingDialog', {
        className: cx(order.deliveryType === 'terminal' ? 'max-w-5xl' : 'max-w-xl', 'py-5 px-2'),
        collection: 'orderDetailsPage',
        deliveryType: sellerOrder ? sellerOrder.deliveryType : order.deliveryType,
        sendLocally: order.shipping?.country === 'LT',
        orderId: order.orderId,
      })
    )
  }

  const getDeliveryInfo = () => {
    if (sellerOrder && sellerOrder.deliveryType) {
      const deliveryType = t(`shipping:${sellerOrder.deliveryType}`)
      const boxSize =
        sellerOrder.terminalBoxSize && `${t('shipping:boxSize', { size: sellerOrder.terminalBoxSize.toUpperCase() })}`

      const parcelWeight =
        sellerOrder.parcelWeight && `${t('shipping:parcelWeight', { weight: sellerOrder.parcelWeight.toFixed(3) })}`

      const params =
        boxSize || parcelWeight ? ` (${[boxSize, parcelWeight].filter(t => t !== undefined).join(': ')})` : ''
      return `${deliveryType}${params}`
    } else if (order.deliveryType) {
      return t(`shipping:${order.deliveryType}`)
    }
    return undefined
  }

  const deliveryInfo = getDeliveryInfo()

  const AdminGeneralSchema = Yup.object().shape({
    trackingNumber: Yup.string().required('errorRequired'),
    shippingProvider: Yup.string().required('errorRequired'),
  })

  const getInitialValues = (): any => {
    return {
      trackingNumber: sellerOrder!.parcelId || '',
      shippingProvider: sellerOrder!.shippingProvider || '',
    }
  }

  const handleSubmit = async (values: any) => {
    const docRef = await db.collection('orders').doc(order.orderId)
    sellerOrder!.shippingProvider = values.shippingProvider
    sellerOrder!.parcelId = values.trackingNumber
    sellerOrder!.status = 'sent'
    const sellerOrders = order.sellerOrders?.map(obj => order.sellerOrders?.find(o => o.userId === sellerOrder?.userId))
    const item = {
      sellerOrders,
    }

    docRef
      .set(item, { merge: true })
      .then(res => {
        dispatch(addNotification('success', t('order_sent')))
        push('/order')
      })
      .catch(error => {
        dispatch(addError(error, 'save_error'))
      })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: AdminGeneralSchema,
    onSubmit: handleSubmit,
  })

  return (
    <div className={merged}>
      <div className="text-2xl font-bold">{t('orderInfo')}</div>
      <Divider className="mt-5" />
      <div className={cx('flex flex-row w-full mt-5 justify-between', className)}>
        <GeneralInfo
          className="mr-5"
          paymentType={order.paymentType}
          status={order.status}
          orderDate={order.dateCreated?.toDate()}
          deliveryDate={order.dateDelivered?.toDate()}
        />
        <div />
        {user.role === 'admin' && (
          <>
            <AddressInfo className="mr-5" title={t('addressBilling')} address={order.billing} />
            <AddressInfo title={t('addressShipping')} address={order.shipping || order.billing} />
          </>
        )}
        {sellerOrder && sellerOrder.status === 'sold' && sellerOrder.deliveryType !== 'vendor' && (
          <div className="flex flex-col">
            {sellerOrder.deliveryType}
            {sellerOrder.labelUrl ? (
              <a className="flex align-center cursor-pointer" href={sellerOrder.labelUrl}>
                <TranslatedText collection="orderDetailsPage" id="getLabel" className="mr-2" />
                <Icon width={20} height={20} svgIcon={<Download />} />
              </a>
            ) : (
              <Button className="cursor-pointer" onClick={openDialog}>
                {t('generateLabel')}
              </Button>
            )}
          </div>
        )}
        {sellerOrder && sellerOrder.status === 'sold' && sellerOrder.deliveryType === 'vendor' && (
          <div className="flex flex-col">
            <form onSubmit={formik.handleSubmit}>
              <InputField name="trackingNumber" formik={formik} translationBlock={TRANSLATION_BLOCK} />
              <InputField name="shippingProvider" formik={formik} translationBlock={TRANSLATION_BLOCK} />
              <Button className="pt-5" type="submit">
                {t('markAsSent')}
              </Button>
            </form>
          </div>
        )}

        <div />
      </div>
      {deliveryInfo && (
        <>
          <Divider className="mt-5" />
          <div className="text-xl font-bold mt-5">{t('deliveryType')}</div>
          <div className="mt-3">{deliveryInfo}</div>
        </>
      )}
    </div>
  )
}

const styles = () => ({
  container: css``,
})
