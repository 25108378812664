import React, { useCallback, useEffect, useState } from 'react'
import { OrderProduct, SellerOrder } from 'models/Order'
import { Seller } from 'models'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notification'
import { addError } from 'store/error'
import { setModal } from 'store/modal'
import { CHANGE_ORDER_PRODUCT_STATUS } from 'store/order'
import { useFirestore } from 'hoc/FirebaseProvider'
import { OrderProductsTable } from './OrderProductsTable'
import { Divider, Label, LabelBadge, SummaryLine, TranslatedText, Icon } from 'components/atoms'
import { ActionsLayout } from 'components/molecules'
import { IOption } from 'components/util'
import { ReactComponent as Info } from '../../../resources/images/icons/info.svg'
import ReactTooltip from 'react-tooltip'

export interface SellerOrderProductsReturnProps {
  className?: string
  orderKey: string
  sellerOrder: SellerOrder
  product: OrderProduct
}

const TRANSLATION_BLOCK = ['orderDetailsPage', 'status']

export const SellerOrderProductsReturn = (props: SellerOrderProductsReturnProps) => {
  const { className, sellerOrder, product, orderKey } = props
  const classes = styles()
  const merged = cx('flex flex-col', classes.container, className)
  const { t } = useTranslation(TRANSLATION_BLOCK)

  const db = useFirestore()
  const dispatch = useDispatch()

  const [seller, setSeller] = useState<Seller>()

  const statusOptions: IOption[] = [
    { label: <TranslatedText collection="status" id="toBeReturned" />, value: 'toBeReturned' },
    { label: <TranslatedText collection="status" id="returned" />, value: 'returned' },
  ]

  useEffect(() => {
    async function fetchSeller(sellerKey: string) {
      try {
        const docRef = db.collection('users').doc(sellerKey)
        const querySnapshot = await docRef.get()
        const seller: Seller | undefined = querySnapshot.data() as Seller
        if (seller) {
          setSeller(seller)
        } else {
          dispatch(addNotification('error', 'orderNotFound'))
        }
      } catch (error) {
        dispatch(addError(error))
      }
    }

    if (sellerOrder?.userId) {
      fetchSeller(sellerOrder?.userId)
    }
  }, [db, dispatch, sellerOrder, t])

  const openDialog = useCallback(() => {
    dispatch(
      setModal('ChangeStatusDialog', {
        collection: ['orderDetailsPage'],
        options: statusOptions,
        defaultValue: statusOptions.find(o => o.value === product.status),
        action: {
          type: CHANGE_ORDER_PRODUCT_STATUS,
          orderKey,
          productId: product.productId,
          sellerOrderId: sellerOrder.orderId,
          refreshPage: true,
        },
      })
    )
  }, [dispatch, sellerOrder, statusOptions, product, orderKey])

  const returnDeliveryPrice = product.return?.deliveryPrice
  const returnPriceTotal = (product.totalPrice || (product.amount || 1) * product.price) + (returnDeliveryPrice || 0)
  const sellerName: string = seller && seller.brandName ? seller.brandName : sellerOrder.brand || t('brandDeleted')
  const tooltip = product.status === 'toBeReturned' ? t(`toBeReturnedTooltip`) : t(`returnedTooltip`)

  return (
    <div className={merged}>
      <ReactTooltip className="max-w-sm uppercase" effect="solid" />
      <div className="flex flex-row justify-between p-4">
        <div className="flex font-bold text-xl items-center">
          <Label className="pr-4" msg={sellerName} />
        </div>
        <div className="flex items-center text-gray-500 text-xs">
          <div className="flex items-center mx-6">
            <TranslatedText collection="orderDetailsPage" id="statusReturn" className="mr-2" />
          </div>

          {(product.status === 'toBeReturned' || product.status === 'returned') && (
            <div data-tip={tooltip}>
              <Icon svgIcon={<Info />} width={16} height={16} className={classes.icon} />
            </div>
          )}
          <LabelBadge type="default" text={t(`status:${product.status}`)} className="mx-8" />
          {product.status === 'toBeReturned' && (
            <ActionsLayout>
              <div className="cursor-pointer  p-4 hover:bg-gray-200" onClick={openDialog}>
                <TranslatedText collection="orderDetailsPage" id="changeStatus" />
              </div>
            </ActionsLayout>
          )}
        </div>
      </div>
      <div className="flex w-full flex-col">
        <OrderProductsTable products={[product]} showStatus={false} />
        <div className="h-5" />
        {returnDeliveryPrice && (
          <>
            <SummaryLine title={t('deliveryPrice')} value={returnDeliveryPrice} />
            <Divider />
          </>
        )}
        {returnPriceTotal && <SummaryLine type={'bold'} title={t('totalPrice')} value={returnPriceTotal} />}
      </div>
    </div>
  )
}

const styles = () => ({
  container: css``,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
