import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { css, cx } from 'emotion'
import { useTheme } from 'emotion-theming'

import { ActionsLayout } from 'components/molecules'

interface SellerActionMenuProps {
  id: string
  className?: string
  push: typeof push
  registered?: boolean
  loading?: boolean
  action: (id: string) => void
}

export const SellerActionMenuComponent = ({
  id,
  className,
  registered = false,
  loading = false,
  push,
  action,
}: SellerActionMenuProps) => {
  const { t } = useTranslation(['sellersPage'])
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className, 'cursor-pointer', 'hover:bg-gray-200', 'p-4')
  const dashboardUrl = `/seller-dashboard/${id}?tab=`

  const onClick = useCallback((id: string) => action(id), [action])

  return (
    <ActionsLayout isTable>
      {!registered && (
        <div
          className={cx(merged, { 'pointer-events-none bg-gray-200 text-white': loading })}
          onClick={() => onClick(id)}>
          {t('resendEmail')}
        </div>
      )}
      <div className={merged} onClick={() => push(dashboardUrl + 'orders')}>
        {t('orders')}
      </div>
      <div className={merged} onClick={() => push(dashboardUrl + 'products')}>
        {t('products')}
      </div>
      <div className={merged} onClick={() => push(dashboardUrl + 'profile')}>
        {t('profile')}
      </div>
    </ActionsLayout>
  )
}

const styles = (theme?: any) => ({
  component: css``,
})

export const SellerActionMenu = connect(undefined, { push })(SellerActionMenuComponent)
