import React, { useEffect, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Label, TranslatedText } from 'components/atoms'
import { ModalProps } from 'models'
import { keys, sumBy, add } from 'lodash'
import { numberToEuroLT, numberToStringLT } from 'number-to-lt'
import { UserDetailsCard } from '../UserDetailsCard'
import { Balance, IUser } from 'components/util'
import { useFirestore } from 'hoc/FirebaseProvider'
import { useDispatch } from 'react-redux'

interface InvoiceContainerProps extends ModalProps {
  className?: string
  children?: React.ReactNode
  userId: string
  invoice: string
}

const TITLE_CLASSES = 'block not-italic font-medium text-lg text-center leading-6 uppercase pt-10'
const DESC_CLASSES = 'block not-italic font-medium text-lg text-center leading-4 pt-3'
const COLUMN_STYLE = 'px-6 py-3 bg-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'
const ROW_STYLE = 'border border-gray-900 h-6'

const TRANSLATION_KEY = 'invoicePage'
const BALANCE = {
  profit: 0,
  vatExcluded: false,
  soldProducts: [] as any,
  canceledProducts: [] as any,
  shippingDetails: {} as any,
}

export const InvoiceContainer = (props: InvoiceContainerProps) => {
  const { className, userId, invoice } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const db = useFirestore()
  const [seller, setSeller] = useState<IUser>({} as IUser)
  const [balance, setBalance] = useState<any>(BALANCE)
  const [adminDetails, setAdminDetails] = useState<IUser>({} as IUser)
  const dispatch = useDispatch()

  //FETCH USER DETAILS
  useEffect(() => {
    const fetchUser = async () => {
      const docRef = db.collection('users').doc(userId)
      const doc = await docRef.get()
      if (doc.exists) {
        const data = doc.data()
        if (data) {
          setSeller({
            id: 'seller',
            brandName: data.companyName,
            address: `${data.senderAddressField1 || ''} ${data.senderAddressField2 || ''}, ${
              data.senderAddressZip || ''
            } ${data.senderAddressCity || ''}`,
            companyCode: data.companyCode,
            VATCode: data.VATCode,
            bankAccount: data.bankAccountNumber,
          })
        }
      }
    }
    if (userId) {
      fetchUser()
    }
  }, [db, userId])

  //FETCH USER BALANCE OF MONTH
  useEffect(() => {
    const fetchBalance = async () => {
      const selectedYear = parseInt(invoice.split('-')[0])
      const selectedMonth = parseInt(invoice.split('-')[1])
      const snapshotStats = await db
        .collection('balances')
        .where('userId', '==', userId)
        .where('year', '==', selectedYear)
        .where('month', '==', selectedMonth - 1)
        .get()

      snapshotStats.forEach(doc => {
        const data = doc.data() as Balance
        if (data) {
          setBalance(data)
        }
      })
    }
    if (invoice) {
      fetchBalance()
    }
  }, [db, invoice, userId])

  //Fetch admin details
  useEffect(() => {
    const fetchData = async () => {
      const docRef = db.collection('common').doc('adminInfo')
      const doc = await docRef.get()
      const data = doc.data()
      if (data) {
        setAdminDetails({
          id: 'buyer',
          brandName: data.companyName,
          companyCode: data.companyCode,
          VATCode: data.VATCode,
          bankAccount: data.bankAccount,
          address: `${data.addressStreet || ''} ${data.addressNumber || ''}, ${data.addressZip || ''} ${
            data.addressCity || ''
          }`,
        })
      }
    }
    fetchData()
  }, [db, dispatch])

  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const priceSuffix = '€'
  let totalNoVat = 0
  balance.soldProducts.forEach((product: any) => {
    totalNoVat = add(totalNoVat, parseFloat((product.total / 1.21).toFixed(2)))
  })
  if (balance.canceledProducts)
    balance.canceledProducts.forEach((product: any) => {
      totalNoVat = add(totalNoVat, parseFloat(product.total.toFixed(2)))
    })
  const grandTotal =
    seller.VATCode || balance.vatExcluded ? balance.profit.toFixed(2).split('.') : totalNoVat.toFixed(2).split('.')
  const positiveGrandTotal = parseInt(grandTotal[0]) > 0
  grandTotal[0] = positiveGrandTotal ? grandTotal[0] : grandTotal[0] * -1

  return (
    balance && (
      <div className={merged}>
        {seller.VATCode && <TranslatedText className={TITLE_CLASSES} collection={TRANSLATION_KEY} id="invoiceTitle" />}
        {!seller.VATCode && (
          <TranslatedText className={TITLE_CLASSES} collection={TRANSLATION_KEY} id="noVatInvoiceTitle" />
        )}
        <div className={DESC_CLASSES}>{balance.invoiceNumber}</div>
        {balance?.year && balance.month !== undefined && balance.month !== null && (
          <div className="text-center text-sm pt-3">
            {new Date(balance.year, balance.month + 1, 0).toLocaleDateString('lt-LT', dateOptions)}
          </div>
        )}
        <div className="flex flex-wrap justify-between mt-15 leading-7">
          <UserDetailsCard translation={TRANSLATION_KEY} user={adminDetails} />
          <UserDetailsCard translation={TRANSLATION_KEY} user={seller} />
        </div>
        <div className="pt-12" css={{ pageBreakBefore: 'always' }}>
          <Label className="font-bold pb-3" msg={<TranslatedText collection={TRANSLATION_KEY} id="products" />} />
          <table cellSpacing="0" cellPadding="0" className="min-w-full">
            <thead className="table-row-group">
              <tr className={ROW_STYLE}>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="code" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="name" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="amount" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="price" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="sum" />
                </th>
              </tr>
            </thead>
            <tbody>
              {/* !seller.VATCode is for legacy generated balances*/}
              {balance.soldProducts.map((product: any, index: number) => (
                <tr className={ROW_STYLE} key={index}>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {product.code}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {product.name}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {product.amount || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {((seller.VATCode || balance.vatExcluded) && product.price) ||
                      (!seller.VATCode && (product.price / 1.21).toFixed(2)) ||
                      '0.00'}{' '}
                    {priceSuffix}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {((seller.VATCode || balance.vatExcluded) && product.total) ||
                      (!seller.VATCode && (product.total / 1.21).toFixed(2)) ||
                      '0.00'}{' '}
                    {priceSuffix}
                  </td>
                </tr>
              ))}

              {balance.canceledProducts &&
                balance.canceledProducts.map((product: any, index: number) => (
                  <tr className={ROW_STYLE} key={index}>
                    <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                      {product.code}
                    </td>
                    <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                      <TranslatedText collection={TRANSLATION_KEY} id="canceled" /> {product.name}
                    </td>
                    <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                      {product.amount || 0}
                    </td>
                    <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                      {product.price || '0.00'} {priceSuffix}
                    </td>
                    <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                      {product.total || '0.00'} {priceSuffix}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot className={cx(classes.footer, 'font-bold text-sm table-row-group')}>
              {seller.VATCode && (
                <tr>
                  <td colSpan={2}>
                    <TranslatedText collection={TRANSLATION_KEY} id="total" />
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 break-word">
                    {sumBy(balance?.soldProducts, 'amount') || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 break-word">
                    {(sumBy(balance?.canceledProducts, 'price') + sumBy(balance?.soldProducts, 'price')).toFixed(2) ||
                      '0.00'}{' '}
                    {priceSuffix}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 break-word">
                    {balance?.profit || '0.00'} {priceSuffix}
                  </td>
                </tr>
              )}
              <tr className="py-2">
                <td colSpan={4}>{seller.VATCode && <TranslatedText collection={TRANSLATION_KEY} id="noVAT" />}</td>
                <td colSpan={1}>
                  {((seller.VATCode || balance.vatExcluded) && balance.totalwoVAT) ||
                    (!seller.VATCode && totalNoVat) ||
                    '0.00'}{' '}
                  {priceSuffix}
                </td>
              </tr>
              {seller.VATCode && (
                <tr className="py-2">
                  <td colSpan={4}>
                    <TranslatedText collection={TRANSLATION_KEY} id="VAT" />
                  </td>
                  <td colSpan={1}>
                    {balance.totalVAT || '0.00'} {priceSuffix}
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        </div>
        <div className="pt-5">
          <Label className="font-bold pb-3" msg={<TranslatedText collection={TRANSLATION_KEY} id="shippingLabel" />} />
          <table cellSpacing="0" cellPadding="0" className="min-w-full">
            <thead className="table-row-group">
              <tr className={ROW_STYLE}>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="shipping" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="size" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="amount" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="price" />
                </th>
                <th className={COLUMN_STYLE}>
                  <TranslatedText collection={TRANSLATION_KEY} id="sum" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={ROW_STYLE}>
                <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word" colSpan={5}>
                  <TranslatedText collection={TRANSLATION_KEY} id="terminal" />
                </td>
              </tr>
              {keys(balance.shippingDetails.terminal).map((key: string, index: number) => (
                <tr className={ROW_STYLE} key={index}>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 break-word"></td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word uppercase">
                    {key}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {balance.shippingDetails.terminal[key]?.amount || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {balance.shippingDetails.terminal[key]?.price || '0.00'} {priceSuffix}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {balance.shippingDetails.terminal[key]?.total || '0.00'} {priceSuffix}
                  </td>
                </tr>
              ))}
              {balance.shippingDetails.courrier && (
                <tr className={ROW_STYLE}>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word" colSpan={5}>
                    <TranslatedText collection={TRANSLATION_KEY} id="courier" />
                  </td>
                </tr>
              )}
              {balance.shippingDetails.courrier && (
                <tr className={ROW_STYLE}>
                  <td colSpan={2}></td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {balance.shippingDetails.courrier?.amount || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {balance.shippingDetails.courrier?.price || '0.00'} {priceSuffix}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm leading-5 text-gray-500 break-word">
                    {balance.shippingDetails.courrier?.total || '0.00'} {priceSuffix}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot className={cx(classes.footer, 'font-bold text-sm table-row-group')}>
              <tr className="py-2">
                <td colSpan={2}>
                  <TranslatedText collection={TRANSLATION_KEY} id="total" />
                </td>
                <td className="px-6 py-4 whitespace-normal text-sm leading-5 break-word">
                  {balance.shippingDetails.totalAmount || 0}
                </td>
                <td className="px-6 py-4 whitespace-normal text-sm leading-5 break-word">
                  {balance.shippingDetails.totalShippingPrice || '0.00'} {priceSuffix}
                </td>
                <td className="px-6 py-4 whitespace-normal text-sm leading-5 break-word">
                  {balance.shippingDetails.total || '0.00'} {priceSuffix}
                </td>
              </tr>
              <tr className="py-2">
                <td colSpan={2}>
                  <TranslatedText collection={TRANSLATION_KEY} id="noVAT" />
                </td>
                <td colSpan={2}></td>
                <td>
                  {balance.shippingDetails.totalwoVAT || '0.00'} {priceSuffix}
                </td>
              </tr>
              <tr className="py-2">
                <td colSpan={2}>
                  <TranslatedText collection={TRANSLATION_KEY} id="VAT" />
                </td>
                <td colSpan={2}></td>
                <td>
                  {balance.shippingDetails.totalVAT || '0.00'} {priceSuffix}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="pt-5 flex flex-col items-end font-bold">
          <div className="bg-gray-900 text-white flex flex-row leading-10 pl-12">
            <TranslatedText className="uppercase w-28" collection={TRANSLATION_KEY} id="total" />
            <div className="w-24">
              {((seller.VATCode || balance.vatExcluded) && balance.profit) || (!seller.VATCode && totalNoVat) || '0.00'}{' '}
              {priceSuffix}
            </div>
          </div>
          {seller.VATCode && (
            <div className="flex flex-row leading-10">
              <TranslatedText className="w-28" collection={TRANSLATION_KEY} id="noVAT" />
              <div className="w-24">
                {balance.totalwoVAT || '0.00'} {priceSuffix}
              </div>
            </div>
          )}
          {seller.VATCode && (
            <div className="flex flex-row leading-10">
              <TranslatedText className="w-28" collection={TRANSLATION_KEY} id="VAT" />
              <div className="w-24">
                {balance.totalVAT || '0.00'} {priceSuffix}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col pb-10">
          <TranslatedText collection={TRANSLATION_KEY} id="totalLong" />
          <div>
            {`${!positiveGrandTotal ? 'Minus ' : ''}`}
            {`${
              numberToStringLT(parseInt(grandTotal[0])).toLocaleString().charAt(0).toUpperCase() +
              numberToStringLT(parseInt(grandTotal[0])).toLocaleString().slice(1)
            }
          ${numberToEuroLT(parseInt(grandTotal[0]))} `}
            {grandTotal[1] && <span>{grandTotal[1]} ct</span>}
          </div>
          <TranslatedText collection={TRANSLATION_KEY} id="paymentTime" />
        </div>
      </div>
    )
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
    width: 40rem;
    margin: 0 auto;
    @media print {
      size: A4 portrait;
      -webkit-print-color-adjust: exact;
      @page {
        size: auto;
        margin: 2cm;
      }

      table {
        border-collapse: collapse;
        page-break-after: auto;
        tr {
          page-break-inside: avoid;
          page-break-after: auto;
        }
      }
    }
  `,
  footer: css`
    tr td {
      padding: 1rem 1rem 0 1rem;
    }
  `,
})
