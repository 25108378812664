import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Icon, TranslatedText } from '../../atoms'
import { useAuth } from '../../../hoc/FirebaseProvider'
import { ReactComponent as Settings } from '../../../resources/images/icons/gear.svg'
import { useDispatch, useSelector, connect } from 'react-redux'
import { setModal } from 'store/modal'
import { logout, getUser } from 'store/user'
import { push } from 'connected-react-router'
import * as _ from 'lodash'

interface SettingsMenuComponentProps {
  className?: string
  children?: React.ReactNode
  push: typeof push
}

type ItemType = 'dialog' | 'navigation' | 'logout'

interface DropDownMenuItem {
  type: ItemType
  i18nKey: string
  visibleFor: string[]
  to?: string // If type is navigation
  id?: boolean //if type is navigation and needs to navigate to page/:id
}

const dropDownMenuItems: DropDownMenuItem[] = [
  {
    type: 'navigation',
    i18nKey: 'adminInfo',
    to: '/admin',
    visibleFor: ['admin'],
  },
  {
    type: 'dialog',
    i18nKey: 'changePass',
    visibleFor: ['admin', 'seller'],
  },
  {
    type: 'logout',
    i18nKey: 'logout',
    visibleFor: ['admin', 'seller'],
  },
]

const WRAPPER_STYLE = 'relative outline-none'
const MENU_STYLE = 'flex justify-start items-center mr-4 cursor-pointer'
const ICON_STYLE = 'mr-4'
const DROPDOWN_LIST_STYLE = 'origin-bottom absolute left-0 mb-2 w-56 shadow-lg'
const DROPDOWN_ITEM_STYLE =
  'group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer'

export const SettingsMenuComponent = (props: SettingsMenuComponentProps) => {
  const { className } = props
  const [isOpen, setIsOpen] = useState(false)
  const auth = useAuth()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(WRAPPER_STYLE, classes.component, className)

  const settingsIcon = <Settings className={classes.icon} />
  const dispatch = useDispatch()

  const user = useSelector(getUser)

  const toggleMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    setIsOpen(!isOpen)
  }

  const closeMenu = (): void => {
    setIsOpen(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: DropDownMenuItem) => {
    event.preventDefault()
    closeMenu()

    switch (item.type) {
      case 'navigation': {
        handleNavigation(item)
        break
      }
      case 'dialog': {
        openDialog(item)
        break
      }
      case 'logout': {
        handleLogout()
        break
      }
      default:
        console.warn(`Unexpected action has been made (${item.type})`)
    }
  }

  const handleLogout = () => {
    auth.signOut()
    dispatch(logout())
    props.push('/login')
  }

  const handleNavigation = (item: DropDownMenuItem) => {
    if (item.to) {
      const url = item.id ? `/user/${user.uid}` : item.to
      props.push(url)
    } else {
      console.warn(`Navigation property is not defined`, item)
    }
  }

  const openDialog = (item: any) => {
    dispatch(setModal('ChangePassword'))
  }

  const getDropDownMenuItems = () => {
    return _.filter(dropDownMenuItems, item => _.some(item.visibleFor, v => v === user.role))
  }

  return (
    <div className={merged} tabIndex={-1} onBlur={closeMenu}>
      <div className={MENU_STYLE} onClick={toggleMenu}>
        <Icon className={ICON_STYLE} svgIcon={settingsIcon} width={18} height={18}></Icon>
        <TranslatedText collection="sidebar" id="settings"></TranslatedText>
      </div>

      <div className={cx(classes.dropdown, { hidden: !isOpen }, DROPDOWN_LIST_STYLE)}>
        <div
          className="rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu">
          {getDropDownMenuItems().map((item: DropDownMenuItem, index: number) => {
            return (
              <div key={index} className="py-1">
                <div
                  className={DROPDOWN_ITEM_STYLE}
                  role="menuitem"
                  onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleClick(event, item)}>
                  <TranslatedText collection="sidebar" id={item.i18nKey} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
  dropdown: css`
    bottom: 100%;
  `,
})

export const SettingsMenu = connect(undefined, { push })(SettingsMenuComponent)
