import { CHANGE_ORDER_PRODUCT_STATUS, CHANGE_ORDER_STATUS, CHANGE_SELLER_ORDER_STATUS } from './constants'
import { combineEpics, ofType } from 'redux-observable'
import { from, Observable, of } from 'rxjs'
import { go } from 'connected-react-router'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import { AnyAction } from 'redux'

import { app } from 'hoc/FirebaseProvider'
import { addNotification } from 'store/notification'
import { deleteModal } from 'store/modal'
import { Order } from 'models/Order'
import { addError } from '../error'

const changeOrderStatusEpic = (action$: Observable<AnyAction>, $state: any, { translations }: any): Observable<any> =>
  action$.pipe(
    ofType(CHANGE_ORDER_STATUS),
    switchMap(({ orderKey, status, ...rest }: any) => {
      const db = app.firestore()
      const orderRef = db.collection('orders').doc(`${orderKey}`)
      const batch = db.batch()
      batch.set(orderRef, { status: status }, { merge: true })

      return of({ response: from(batch.commit()), props: rest })
    }),
    switchMap(({ response, props }: any) =>
      response.pipe(
        mergeMap(data => {
          const actions = []
          actions.push(deleteModal(props.modalId))
          if (props.refreshPage) {
            actions.push(go(0))
          }
          actions.push(addNotification('success', translations['ordersPage']['orderStatusChanged']))
          return actions
        })
      )
    ),
    catchError(error => {
      return [addError(error, 'fetch_error')]
    })
  )

const changeOrderProductStatusEpic = (
  action$: Observable<AnyAction>,
  $state: any,
  { translations }: any
): Observable<any> =>
  action$.pipe(
    ofType(CHANGE_ORDER_PRODUCT_STATUS),
    switchMap(({ orderKey, sellerOrderId, productId, status, ...rest }: any) => {
      const db = app.firestore()
      const orderRef = db.collection('orders').doc(`${orderKey}`)
      return from(orderRef.get()).pipe(
        switchMap((data: any) => {
          const order: Order = data.data() as Order
          const db = app.firestore()
          order.sellerOrders?.forEach(sellerOrder => {
            if (!sellerOrderId || sellerOrder.orderId === sellerOrderId) {
              sellerOrder.productSnapshots?.forEach(product => {
                if (product.productId === productId) {
                  product.status = status
                }
              })
            }
          })
          const batch = db.batch()
          batch.set(orderRef, order, { merge: true })
          return of({ response: from(batch.commit()), props: rest }).pipe(
            switchMap(({ response, props }: any) =>
              response.pipe(
                mergeMap(data => {
                  const actions = []
                  actions.push(deleteModal(props.modalId))
                  if (props.refreshPage) {
                    actions.push(go(0))
                  }
                  actions.push(addNotification('success', translations['ordersPage']['orderProductStatusChanged']))
                  return actions
                })
              )
            ),
            catchError(error => {
              return [addError(error)]
            })
          )
        }),
        catchError(error => {
          return [addError(error)]
        })
      )
    }),
    catchError(error => {
      return [addError(error)]
    })
  )

const changeSellerOrderStatusEpic = (
  action$: Observable<AnyAction>,
  $state: any,
  { translations }: any
): Observable<any> =>
  action$.pipe(
    ofType(CHANGE_SELLER_ORDER_STATUS),
    switchMap(({ orderKey, sellerOrderId, status, ...rest }: any) => {
      const db = app.firestore()
      const orderRef = db.collection('orders').doc(`${orderKey}`)

      return from(orderRef.get()).pipe(
        switchMap((data: any) => {
          const order: Order = data.data() as Order
          order.sellerOrders?.forEach(sellerOrder => {
            if (sellerOrder.orderId === sellerOrderId) {
              sellerOrder.status = status
            }
          })
          const batch = db.batch()
          batch.set(orderRef, order, { merge: true })

          return of({ response: from(batch.commit()), props: rest }).pipe(
            switchMap(({ response, props }: any) =>
              response.pipe(
                mergeMap(data => {
                  const actions = []
                  actions.push(deleteModal(props.modalId))
                  if (props.refreshPage) {
                    actions.push(go(0))
                  }
                  actions.push(addNotification('success', translations['ordersPage']['orderStatusChanged']))
                  return actions
                })
              )
            ),
            catchError(error => {
              return of(addError(error))
            })
          )
        }),
        catchError(error => {
          return of(addError(error))
        })
      )
    }),
    catchError(error => {
      return of(addError(error))
    })
  )

export const orderEpics = combineEpics(changeOrderStatusEpic, changeOrderProductStatusEpic, changeSellerOrderStatusEpic)
