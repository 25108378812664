import React, { useState, useCallback } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { ChangePasswordForm } from 'components/organisms'
import { useAuth } from 'hoc/FirebaseProvider'
import { Notification, TranslatedText, LoadingBar, Divider, Button } from 'components/atoms'
import { auth } from 'firebase'
import { useSelector, useDispatch } from 'react-redux'
import { isActionLoading, setLoading } from 'store/loading'
import { CardLayout } from '../CardLayout'
import { withModal } from 'hoc/withModal/withModal'
import { FirebaseErrorResponse } from 'components/util/interfaces'
import { useFormik } from 'formik'
import * as Yup from 'yup'
interface ChangePasswordLayoutProps {
  className?: string
  children?: React.ReactNode
  id?: string
}

const ChangePasswordSchema = Yup.object({
  password: Yup.string().required('required'),
  newPassword: Yup.string().required('required').min(6, 'errorWeakPassword'),
  passwordConfirm: Yup.string()
    .required('required')
    .oneOf([Yup.ref('newPassword')], 'confirm'),
})

interface ForgotPassModel {
  password: string
  newPassword: string
  passwordConfirm: string
}

const TRANSLATION_COLLECTION_KEY = 'globalEvents'

const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3'
const CARD_STYLE = 'w-full pt-10'

export const ChangePasswordLayout = (props: ChangePasswordLayoutProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CARD_STYLE, classes.component, className)
  const authentication = useAuth()
  const formLoading = useSelector(isActionLoading('CHANGE_PASSWORD_FORM_REQUEST'))
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const formik = useFormik<ForgotPassModel>({
    initialValues: {
      password: '',
      newPassword: '',
      passwordConfirm: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: values => {
      updateUserPassword(values.password, values.newPassword)
    },
  })

  const updateUserPassword = useCallback(
    (oldValue: string, newValue: string) => {
      dispatch(setLoading('CHANGE_PASSWORD_FORM_REQUEST', true))
      setSuccess('')
      setError('')
      let user = authentication.currentUser
      if (user && user.email) {
        const credential = auth.EmailAuthProvider.credential(user.email, oldValue)
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            if (user) {
              user.updatePassword(newValue).then(
                () => {
                  setSuccess('success')
                  dispatch(setLoading('CHANGE_PASSWORD_FORM_REQUEST', false))
                  formik.resetForm()
                },
                (error: FirebaseErrorResponse) => {
                  setError(error.code)
                  dispatch(setLoading('CHANGE_PASSWORD_FORM_REQUEST', false))
                }
              )
            }
          })
          .catch((error: FirebaseErrorResponse) => {
            setError(error.code)
            dispatch(setLoading('CHANGE_PASSWORD_FORM_REQUEST', false))
          })
      }
    },
    [authentication.currentUser, dispatch, formik]
  )

  return (
    <CardLayout className={merged}>
      <h2>
        <TranslatedText className={cx(TITLE_CLASSES, classes.title)} collection="changePassForm" id="title" />
      </h2>
      <h4 className={cx(DESC_CLASSES, classes.desc)}>
        <TranslatedText collection="changePassForm" id="desc" />
      </h4>
      {formLoading ? (
        <LoadingBar className={'bg-gray-900'} />
      ) : !success && !error ? (
        <Divider />
      ) : success ? (
        <Notification
          className={'w-full'}
          type={'success'}
          text={<TranslatedText collection={TRANSLATION_COLLECTION_KEY} id={'changeSuccess'} />}
        />
      ) : (
        <Notification
          className={'w-full'}
          type={'error'}
          text={<TranslatedText collection={TRANSLATION_COLLECTION_KEY} id={error} />}
        />
      )}
      <form className="py-10 px-15" onSubmit={formik.handleSubmit}>
        <ChangePasswordForm formik={formik} />
        <div className="flex justify-end">
          <Button disabled={!(formik.isValid && formik.dirty)} type="submit">
            <TranslatedText collection="changePassForm" id="buttonText" />
          </Button>
        </div>
      </form>
    </CardLayout>
  )
}

const styles = (theme: any) => ({
  component: css``,
  title: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  desc: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
  notification: css`
    min-width: 100%;
    font-family: 'SourceSansPro', sans-serif;
  `,
})

export const ChangePasswordLayoutModal = withModal(ChangePasswordLayout)
