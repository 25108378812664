import React, { useRef, InputHTMLAttributes } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
}

const INPUT_STYLE =
  'px-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const INPUT_DISABLED = 'bg-gray-200 opacity-75'
export const Input = (props: InputProps) => {
  const { className, handleChange, handleBlur, ...rest } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(INPUT_STYLE, classes.component, className, { [INPUT_DISABLED]: props.disabled })

  const inputRef = useRef<any>(null)
  const resolvedRef = inputRef

  return <input className={merged} onChange={handleChange} onBlur={handleBlur} {...rest} ref={resolvedRef} />
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
