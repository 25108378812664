import React, { useMemo, useCallback } from 'react'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import { LabelBadge } from 'components/atoms/LabelBadge'
import { ActionsLayout } from 'components/molecules/ActionsLayout'
import { TranslatedText } from 'components/atoms/TranslatedText'
import { useDispatch } from 'react-redux'
import { setModal } from 'store/modal'
import { IOption } from './interfaces'
import { CHANGE_BALANCE_STATUS } from 'store/balance'
import { ModalType } from 'hoc/withModal/ModalRegistry'

interface useBalancesColumnProps {
  user: any
  push: any
}

const TRANSLATION_KEY = 'balancePage'

export function useBalancesColumn({ user, push }: useBalancesColumnProps) {
  const { t } = useTranslation([TRANSLATION_KEY])
  const dispatch = useDispatch()

  const statusOptions: IOption[] = [
    { label: <TranslatedText collection={TRANSLATION_KEY} id="paid" />, value: 'paid' },
    { label: <TranslatedText collection={TRANSLATION_KEY} id="notpaid" />, value: 'notpaid' },
  ]

  const filterByBrand = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        const rowValue = (row as any).original.userId
        if (filterValue.length === 0) {
          return true
        }
        return filterValue.indexOf(rowValue) !== -1
      })
    }
  }, [])

  const getBadgeType = (value: string) => {
    switch (value) {
      case 'paid':
        return 'success'
      case 'notpaid':
        return 'error'
      default:
        return 'default'
    }
  }

  const openDialog = useCallback(
    (row: any, modal: ModalType) => {
      switch (modal) {
        case 'ChangeStatusDialog': {
          dispatch(
            setModal(modal, {
              collection: TRANSLATION_KEY,
              options: statusOptions,
              defaultValue: statusOptions.find(o => o.value === row.original.status),
              action: {
                type: CHANGE_BALANCE_STATUS,
                id: row.original.docId,
                refreshPage: true,
              },
            })
          )
          break
        }
        default: {
          console.error('Modal does not exist')
        }
      }
    },
    [dispatch, statusOptions]
  )

  return useMemo(() => {
    let defaultColumns = [
      {
        Header: '',
        accessor: 'docId',
        width: 0,
      },
      {
        Header: '',
        accessor: 'date',
        width: 0,
      },
      {
        Header: t('balance'),
        accessor: 'totalSold',
        width: 140,
        Cell: ({ row }: any) => {
          return row.original.totalSold.toFixed(2) + ' €'
        },
      },
      {
        Header: t('profit'),
        accessor: 'profit',
        width: 140,
        Cell: ({ row }: any) => {
          return row.original.profit.toFixed(2) + ' €'
        },
      },
      {
        Header: t('margin'),
        accessor: 'margin',
        width: 140,
        Cell: ({ row }: any) => {
          return row.original.margin.toFixed(2) + ' €'
        },
      },
      {
        Header: t('statusColumn'),
        accessor: 'status',
        Cell: ({ row }: any) => {
          const label = (
            <LabelBadge
              className="mb-1"
              type={getBadgeType(row.original.status)}
              text={t(row.original.status)}
              size="sm"
            />
          )
          return label
        },
      },
      {
        Header: '',
        accessor: 'actions',
        width: 40,
        sticky: 'right',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const dashboardUrl = `/seller-dashboard/${row.original.userId}?tab=`
          const invoiceUrl = `/invoice/${row.original.userId}?invoice=${row.original.year}-${row.original.month + 1}`
          return (
            <>
              {row.original.userExists ? (
                <ActionsLayout isTable>
                  {user && user.role === 'admin' && (
                    <div
                      className="cursor-pointer  p-4 hover:bg-gray-200"
                      onClick={() => push(dashboardUrl + 'profile')}>
                      <TranslatedText collection="balancePage" id="profile" />
                    </div>
                  )}
                  {user && user.role === 'admin' && (
                    <div
                      className="cursor-pointer  p-4 hover:bg-gray-200"
                      onClick={() => openDialog(row, 'ChangeStatusDialog')}>
                      <TranslatedText collection="balancePage" id="changeStatus" />
                    </div>
                  )}
                  <div className="cursor-pointer  p-4 hover:bg-gray-200" onClick={() => push(invoiceUrl)}>
                    <TranslatedText collection="balancePage" id="viewInvoice" />
                  </div>
                </ActionsLayout>
              ) : (
                ''
              )}
            </>
          )
        },
      },
    ] as Array<Column>

    if (user.role === 'admin') {
      defaultColumns = [
        ...defaultColumns.slice(0, 1),
        // inserted item
        {
          Header: t('brandName'),
          accessor: 'brandName',
          filter: filterByBrand,
          width: 140,
          Cell: ({ row }: any) => {
            return row.original.brand
          },
        },
        // part of the array after the specified index
        ...defaultColumns.slice(2),
      ] as Array<Column>
    }

    return defaultColumns
  }, [user, push, openDialog, filterByBrand, t])
}
