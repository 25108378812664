import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'

interface TranslatedTextProps {
  className?: string
  collection: string
  id?: string
  properties?: any
}

export const TranslatedText = (props: TranslatedTextProps) => {
  const { className, collection, id, properties } = props
  const { t } = useTranslation([collection])

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  return <span className={merged}>{id && t(id, properties)}</span>
}

const styles = (theme: any) => ({
  component: css``,
})
