import { combineEpics, Epic, ofType } from 'redux-observable'
import { switchMap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'

import { ERROR_ACTION, ErrorAction } from './actions'
import { addNotification } from '../notification'

const errorEpic: Epic<any, any> = (action$: Observable<any>, $state: any, { translations }: any): Observable<any> =>
  action$.pipe(
    ofType(ERROR_ACTION),
    switchMap(({ error, errorKey }: ErrorAction) => {
      console.error('ERROR: ', error)
      const msg = errorKey ? translations?.['error']?.[errorKey] || errorKey : error?.toString()
      return of(addNotification('error', msg))
    })
  )

export const errorEpics = combineEpics(errorEpic)
