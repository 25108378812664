import React, { useEffect, useMemo, useState } from 'react'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'hoc/FirebaseProvider'
import moment from 'moment'

import { AppState } from 'store/reducers'
import { getPagination } from 'store/pagination'
import { getFilter, setDateRangeFilterField } from 'store/filtering'
import { DATE_FORMAT } from 'components/util'
import { Table } from 'components/molecules/Table'
import { PAGE_SIZE } from '../PagerFilter'
import { OrderOrdersActionMenu } from './OrderOrdersActionMenu'
import { Order, OrderOrdersTable, OrderSellerOrdersTable } from 'models/Order'
import { addNotification } from 'store/notification'
import { getCurrentUser } from 'store/user'

interface ListContainerProps {
  tableId: string
  orderType?: string
  userId?: string
}

export const OrderCanceledListContainer = ({ tableId, orderType = 'canceled', userId }: ListContainerProps) => {
  const [orders, setOrders] = useState<OrderOrdersTable[]>([])

  const { t } = useTranslation(['ordersPage'])
  const db = useFirestore()
  const pagination = useSelector((state: AppState) => getPagination(state, tableId))
  const filter = useSelector((state: AppState) => getFilter(state, tableId))
  const user = useSelector((state: AppState) => getCurrentUser(state))

  const data = useMemo(() => orders as OrderOrdersTable[], [orders])
  const dispatch = useDispatch()

  const isAdmin = useMemo(() => {
    return user.role === 'admin'
  }, [user])

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'disabled', desc: false }],
      pageIndex: get(pagination, 'initialPage', 0),
      pageSize: get(pagination, 'pageSize', PAGE_SIZE),
      hiddenColumns: ['sellerOrderId', 'orderKey'],
    }),
    [pagination]
  )

  const filterByColumnID = useMemo(() => {
    const filterByColumnID = new Map<string, string>()
    filterByColumnID.set('dateRange', 'orderDate')
    return filterByColumnID
  }, [])

  const filterByDateRange = useMemo(() => {
    return (rows: Row[], id: string, filterValue: string[]) => {
      return rows.filter(row => {
        if (filterValue.length < 1) {
          return true
        }
        const created = row.values['orderDate']

        if (!created) {
          return false
        }

        const momentDate = moment(created)
        const from = moment(filterValue[0])
        const to = moment(filterValue[1])

        return momentDate.isBetween(from, to)
      })
    }
  }, [])

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'orderKey',
        },
        {
          Header: t('orderId'),
          accessor: 'orderId',
          Cell: ({ row }) => {
            const orderKey = row.values['orderKey']
            const link = '/order/' + orderKey + '/details'
            return <a href={link}>{orderKey}</a>
          },
          width: 80,
        },
        {
          Header: t('brand'),
          accessor: 'orderBrand',
          width: 80,
        },
        {
          Header: 'ID',
          accessor: 'sellerOrderId',
          width: 80,
        },
        {
          Header: t('orderDate'),
          accessor: 'orderDate',
          Cell: ({ row }) => {
            const value = row.values['orderDate']
            const strDate = value && moment(value).format(DATE_FORMAT)
            return <div>{strDate || 'N/D'}</div>
          },
          filter: filterByDateRange,
          sortType: 'date',
          width: 120,
        },
        {
          Header: t('productCount'),
          accessor: 'productCount',
          Cell: ({ row }) => {
            const value = row.values['productCount']
            return <div>{value}</div>
          },
          width: 100,
        },
        {
          Header: t('orderValue'),
          accessor: 'price',
          Cell: ({ row }) => {
            const value = row.values['price'] as number
            const dispValue = value !== undefined ? `${value.toFixed(2)} €` : ''
            return <div>{dispValue}</div>
          },
          width: 120,
        },
        {
          Header: '',
          accessor: 'actions',
          disableSortBy: true,
          width: 40,
          sticky: 'right',
          Cell: ({ row }) => {
            const orderKey = row.values.orderKey
            const sellerOrderId = row.values.sellerOrderId
            const status = row.values.status
            return isAdmin && !userId ? (
              <OrderOrdersActionMenu orderKey={orderKey} currentStatus={status} />
            ) : (
              <OrderOrdersActionMenu orderKey={orderKey} sellerOrderId={sellerOrderId} currentStatus={status} />
            )
          },
        },
      ] as Array<Column>,
    [t, filterByDateRange, isAdmin, userId]
  )

  useEffect(() => {
    dispatch(setDateRangeFilterField(tableId, undefined))
  }, [dispatch, tableId, orderType])

  useEffect(() => {
    const orderConverter: firebase.firestore.FirestoreDataConverter<OrderSellerOrdersTable[] | undefined> = {
      toFirestore(doc: OrderSellerOrdersTable[]): Order {
        throw new Error('Not implemented')
      },
      fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<Order>,
        options: firebase.firestore.SnapshotOptions
      ): OrderSellerOrdersTable[] | undefined {
        const orderData: Order = snapshot.data(options)
        const orderKey = snapshot.id
        if (isAdmin && !userId) {
          let result: OrderSellerOrdersTable[] = []
          for (const seller of orderData.sellerOrders!) {
            if (orderData && orderData.orderId && 'canceled' === seller.status) {
              result.push({
                orderKey: orderKey,
                orderId: orderData.orderId,
                productCount: orderData.productCount || 1,
                orderDate: orderData.dateCreated?.toDate(),
                price: seller.price,
                deliveryType: orderData.deliveryType || 'N/D',
                orderBrand: seller.brand,
                status: orderData.status,
              })
            }
          }
          return result
        }
      },
    }

    async function fetchData(
      converter: firebase.firestore.FirestoreDataConverter<OrderSellerOrdersTable[] | undefined>
    ) {
      try {
        const docRef = db.collection('orders')
        const querySnapshot = await docRef.withConverter(converter).get()
        const orders: OrderSellerOrdersTable[] = []
        querySnapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<OrderSellerOrdersTable[] | undefined>) => {
          const order = doc.data()
          if (order) {
            order.forEach(o => {
              orders.push(o)
            })
          }
        })
        setOrders(orders)
      } catch (error) {
        dispatch(addNotification('error', t('fetch_error')))
        console.error('Orders are not fetched', error)
      }
    }

    fetchData(orderConverter)
  }, [orderType, db, dispatch, t, user, isAdmin, userId])

  return (
    <Table
      tableId={tableId}
      columns={columns}
      data={data}
      paginationOptions={pagination}
      filterOptions={filter}
      initialState={initialState}
      filterMapByColumnId={filterByColumnID}
    />
  )
}
