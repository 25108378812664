import React, { useEffect, useState } from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'store/user'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'

import { CommonPageLayout, SideNavigationLayout } from 'components/templates'
import { SellerOrderProductsReturn } from 'components/organisms/SellerOrderProducts'
import { useFirestore } from 'hoc/FirebaseProvider'
import { Order, OrderProduct, SellerOrder } from 'models/Order'
import { addNotification } from 'store/notification'
import { addError } from 'store/error'
import { OrderReturnInfo } from 'components/organisms/OrderInfo'

interface PageProps {
  className?: string
  children?: React.ReactNode
}

export const TRANSLATION_BLOCK = 'orderDetailsPage'
const HEADER_CONTAINER_STYLE = 'flex flex-row justify-top bg-white'

export const OrderReturnPage = (props: PageProps) => {
  const { className } = props
  const { t } = useTranslation([TRANSLATION_BLOCK])

  const classes = styles()
  const merged = cx(classes.page, className)
  const user = useSelector(getUser)
  const orderKey = get(props, 'match.params.id')
  const sellerId = get(props, 'match.params.sellerId')
  const returnId = get(props, 'match.params.returnId')
  const returnProducId = get(props, 'match.params.returnProductId')

  const db = useFirestore()
  const dispatch = useDispatch()

  const [order, setOrder] = useState<Order>()
  const [product, setProduct] = useState<OrderProduct>()
  const [seller, setSeller] = useState<SellerOrder>()

  useEffect(() => {
    async function fetchOrder(orderKey: string) {
      try {
        const docRef = db.collection('orders').doc(orderKey)
        const querySnapshot = await docRef.get()
        const order: Order = querySnapshot.data() as Order
        if (order) {
          setOrder(order)
          const seller = order.sellerOrders?.find(sellerOrder => {
            return sellerOrder.orderId === sellerId
          })

          if (seller) {
            setSeller(seller)
            const product = seller.productSnapshots?.find(prod => {
              return (
                prod?.return?.woocommerceRefundId === parseInt(returnId) &&
                prod?.return?.woocommerceRefundItemId === parseInt(returnProducId)
              )
            })
            if (product) {
              setProduct(product)
            } else {
              dispatch(addNotification('error', 'productNotFound'))
            }
          } else {
            dispatch(addNotification('error', 'sellerNotFound'))
          }
        } else {
          dispatch(addNotification('error', 'orderNotFound'))
        }
      } catch (error) {
        dispatch(addError(error))
      }
    }

    fetchOrder(orderKey)
  }, [db, dispatch, orderKey, returnId, returnProducId, sellerId, t])

  return (
    <div>
      {user.role === 'seller' && user.disabled ? (
        <Redirect to={`/seller/${user.uid}`} />
      ) : (
        <SideNavigationLayout className={merged}>
          <CommonPageLayout
            className={cx('overflow-x-auto', classes.component)}
            pageTitle={`${t('titleDetails', { orderId: order?.orderId || '' })}`}>
            <div className={HEADER_CONTAINER_STYLE}>
              {order && <OrderReturnInfo order={order} returnInfo={product?.return} />}
            </div>
            <div className="h-5" />
            {seller && product && (
              <SellerOrderProductsReturn
                orderKey={orderKey}
                sellerOrder={seller}
                product={product}
                className={HEADER_CONTAINER_STYLE}
              />
            )}
          </CommonPageLayout>
        </SideNavigationLayout>
      )}
    </div>
  )
}

const styles = () => ({
  component: css`
    min-width: 700px;
  `,
  page: css``,
})
