import React, { useCallback, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { withModal } from 'hoc/withModal/withModal'
import { ModalProps } from 'models'
import { TranslatedText, Button, Label, Input } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteModal } from 'store/modal'

interface DiscountDialogProps extends ModalProps {
  className?: string
  children?: React.ReactNode
  collection?: string
}

const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5'
const DESC_CLASSES = 'block not-italic font-medium text-sm text-center leading-4 pb-5 pt-3'
const BUTTON_WRAPPER_CLASSES = 'mt-5 p-5 flex justify-center items-center'

export const DiscountDialog = (props: DiscountDialogProps) => {
  const { className, collection = '', id, action } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx('pt-5', classes.component, className)
  const [discount, setDiscount] = useState<number>()

  const { t } = useTranslation([collection])
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (value: number) => {
      setDiscount(value)
    },
    [setDiscount]
  )

  const confirmAction = () => {
    if (action) {
      dispatch({ ...action, modalId: id, discount: discount })
    } else {
      console.warn(`No action provided for this confirm dialog`, props)
    }
  }
  const cancelAction = () => {
    dispatch(deleteModal(id))
  }

  return (
    <div className={merged}>
      <TranslatedText className={TITLE_CLASSES} collection={collection} id="confirmTitle" />
      <TranslatedText className={DESC_CLASSES} collection={collection} id="confirmDesc" />
      <div className="flex text-sm justify-center items-center w-64 my-0 mx-auto">
        <Label className="w-40" msg={<TranslatedText collection={collection} id="confirmLabel" />} />
        <Input
          type="number"
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(parseInt(e.target.value))}
          placeholder={t('discountPlaceholder')}
        />
      </div>
      <div className={BUTTON_WRAPPER_CLASSES}>
        <Button className="mr-2" onClick={confirmAction}>
          <TranslatedText collection={collection} id="okText" />
        </Button>
        <Button color="secondary" onClick={cancelAction}>
          <TranslatedText collection={collection} id="cancelText" />
        </Button>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})

export const DiscountDialogModal = withModal(DiscountDialog)
