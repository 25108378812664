export const ERROR_ACTION = 'ERROR'

export interface ErrorAction {
  type: typeof ERROR_ACTION
  error: any
  errorKey?: string
}

export const addError = (error: any, errorKey?: string): ErrorAction => ({
  type: ERROR_ACTION,
  error,
  errorKey,
})
