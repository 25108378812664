import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useFirestore } from 'hoc/FirebaseProvider'
import { connect, useDispatch, useSelector } from 'react-redux'
import { get, map, pick } from 'lodash'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import { Product, ProductsTable } from 'models'
import { Table } from 'components/molecules'

import { getPagination } from 'store/pagination'
import { getFilter } from 'store/filtering'
import { addNotification } from 'store/notification'
import { getCurrentUser } from 'store/user'
import { AppState } from 'store/reducers'
import { useUserId } from 'components/util/useUserId'
import { useProductsColumn } from 'components/util/useProductsColumn'
import { PAGE_SIZE } from 'components/organisms/PagerFilter'
import { setProductIds } from 'store/product'

interface ProductListContainerComponentProps {
  tableId: string
  sellerColumn?: boolean
}

export const ProductListContainerComponent = (props: ProductListContainerComponentProps & { push: typeof push }) => {
  const { tableId, sellerColumn = true } = props
  const [products, setProducts] = useState<Product[]>([])
  const [productStatus, setProductStatus] = useState<any[]>([])

  const dispatch = useDispatch()
  const { t } = useTranslation(['productsPage'])
  const db = useFirestore()
  const pagination = useSelector((state: AppState) => getPagination(state, tableId))
  const filter = useSelector((state: AppState) => getFilter(state, tableId))
  const user = useSelector(getCurrentUser)

  const userId = useUserId()
  const columns = useProductsColumn({ productStatus, user, sellerColumn })

  // key - filter value, value - column id / accessor
  const filterByColumnID = new Map<string, string>()
  filterByColumnID.set('freeText', 'name')
  filterByColumnID.set('categories', 'categoryNames')
  filterByColumnID.set('statuses', 'status')
  filterByColumnID.set('brands', 'brand')
  filterByColumnID.set('dateRange', 'created')

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'id' }],
      pageIndex: get(pagination, 'initialPage', 0),
      pageSize: get(pagination, 'pageSize', PAGE_SIZE),
    }),
    [pagination]
  )

  useEffect(() => {
    const productsConverter = {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ) {
        const data = snapshot.data(options)

        const convertedToStringStatus = []
        if (data.status.deleted) {
          convertedToStringStatus.push('deleted')
        }
        if (data.status.published) {
          convertedToStringStatus.push('published')
        } else {
          convertedToStringStatus.push('unpublished')
        }
        if (data.status.soldOut) {
          convertedToStringStatus.push('soldOut')
        }
        if (data.status.lowStock) {
          convertedToStringStatus.push('lowStock')
        }

        return new Product({ ...data, created: data.created?.toMillis(), convertedStatus: convertedToStringStatus })
      },
    }

    let unsubscribe: { (): void | undefined; (): void }

    if (user.email !== '') {
      let docRef: any = db.collection('products')

      if (user.role === 'seller') {
        const chooseID = userId || user.uid
        docRef = docRef.where('userId', '==', chooseID).where('status.deleted', '==', false)
      }

      if (user.role === 'admin') {
        if (userId) {
          docRef = docRef.where('userId', '==', userId)
        }
      }

      unsubscribe = docRef.withConverter(productsConverter as any).onSnapshot(
        (querySnapshot: any) => {
          const products: Product[] = []
          querySnapshot.forEach(function (doc: any) {
            const product: Product = doc.data()
            product.docId = doc.id
            products.push(product)
          })

          setProducts(products)
        },
        (error: any) => {
          dispatch(addNotification('error', t('fetch_error')))
          console.error('Products are not fetched', error)
        }
      )
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [db, user.email, user.brandName, user.role, userId, user.uid, t, dispatch])

  // FETCH product statuses
  useEffect(() => {
    const statusConverter = {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ) {
        const data = snapshot.data(options)
        return { label: data.label, value: data.value }
      },
    }

    async function fetchData(converter: any) {
      try {
        const docRef = db.collection('productStatus')
        const querySnapshot = await docRef.withConverter(converter).get()
        const statuses: any[] = []

        querySnapshot.forEach((doc: any) => {
          const status = doc.data()
          statuses.push(status)
        })
        setProductStatus(statuses)
      } catch (error) {
        dispatch(addNotification('error', t('fetch_error')))
        console.error('Statuses are not fetched', error)
      }
    }

    fetchData(statusConverter)
  }, [db, t, dispatch])

  const data = useMemo(() => products as ProductsTable[], [products])

  const onRowSelect = useCallback(
    (rows: Product[]) => {
      const items = map(rows, row => pick(row.original, 'docId', 'name'))
      dispatch(setProductIds(items))
    },

    [dispatch]
  )

  return (
    <Table
      tableId={tableId}
      columns={columns}
      data={data}
      paginationOptions={pagination}
      filterOptions={filter}
      initialState={initialState}
      filterMapByColumnId={filterByColumnID}
      isRowSelectable
      onRowSelect={onRowSelect}
      getCellProps={() => ({
        style: {},
      })}
    />
  )
}

export const ProductListContainer = connect(undefined, { push })(ProductListContainerComponent)
