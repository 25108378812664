import { css, cx } from 'emotion'
import React from 'react'
import { theme } from 'theme'

interface LoadingBarProps {
  className?: string
}

export const LoadingBar = (props: LoadingBarProps) => {
  const { className } = props
  const classes = styles()
  return (
    <div className={classes.loadingBar}>
      <span className={cx(classes.loadingBarInner, className)}></span>
    </div>
  )
}

const styles = () => ({
  loadingBar: css`
    height: 2px;
    display: block;
    width: 100%;
    background: ${theme.colors.grey[200]};
  `,
  loadingBarInner: css`
    height: 2px;
    display: block;
    animation: loadingBar 2s ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    @keyframes loadingBar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  `,
  text: css`
    color: ${theme.colors.grey[600]};
  `,
})
