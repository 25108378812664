import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { SideNavigationLayout, CardLayout, CommonPageLayout } from 'components/templates'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getUser } from 'store/user'
import { Redirect } from 'react-router-dom'
import { EditProductWrapperContainer } from 'components/organisms/EditProductWrapperContainer'

interface AddProductPageProps {
  className?: string
}

const PAGE_STYLE = 'flex flex-col justify-between p-10'
export const AddProductPage = (props: AddProductPageProps) => {
  const { className } = props
  const { t } = useTranslation(['newProductForm'])
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const user = useSelector(getUser)

  return (
    <div>
      {user.role === 'seller' && user.disabled ? (
        <Redirect to={`/seller/${user.uid}`} />
      ) : (
        <SideNavigationLayout className={merged}>
          <CommonPageLayout pageTitle={t('title')}>
            <CardLayout className={PAGE_STYLE}>
              <EditProductWrapperContainer />
            </CardLayout>
          </CommonPageLayout>
        </SideNavigationLayout>
      )}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
