import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import UserPlaceholder from '../../../resources/images/user-placeholder.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getUser } from '../../../store/user'

interface UserCardProps {
  className?: string
  children?: React.ReactNode
  username: string | JSX.Element
  photoUrl?: string
  vacation?: boolean
}

const CARD_LAYOUT_CLASSES = 'p-3 flex flex-col items-center justify-between'
const PROFILE_PHOTO_CLASSES = 'box-content rounded-full object-cover w-full h-full'
const USERNAME_CLASSES = 'non-italic font-normal text-base leading-5'
const VACATION_CLASSES = 'non-italic font-normal text-base leading-5 text-red-600'

export const UserCard = (props: UserCardProps) => {
  const { className, username, photoUrl = UserPlaceholder, vacation } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CARD_LAYOUT_CLASSES, classes.component, className)
  const user = useSelector(getUser)
  const { t } = useTranslation(['setVacation'])

  return (
    <div className={merged}>
      <picture className={cx(classes.image, 'h-24 w-24 block rounded-full w-full relative overflow-hidden')}>
        <img className={cx(PROFILE_PHOTO_CLASSES)} src={photoUrl} alt="User" />
      </picture>
      <div className={cx(USERNAME_CLASSES, 'userName')}>{username}</div>
      {vacation && (
        <div className={cx(VACATION_CLASSES, 'vacation')}>
          <a href={`/user/${user.uid}`}>{t('vacationON')}</a>
        </div>
      )}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  image: css`
    height: 6rem;
    width: 6rem;
  `,
})
