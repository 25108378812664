import React, { useRef, useEffect, forwardRef, InputHTMLAttributes } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Ref } from 'yup'

interface IndeterminateCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  indeterminate?: boolean
}

const WRAPPER_CLASSES = 'select-none inline-block relative cursor-pointer'
const INPUT_CLASSES = 'relative h-5 w-5 z-10 opacity-0'
const CHECKBOX_PLACEHOLDER_CLASSES =
  'inline-block h-5 w-5 bg-white absolute left-0 top-0 border border-solid border-gray-400'

export const IndeterminateCheckbox = (
  { className, indeterminate, ...otherProps }: IndeterminateCheckboxProps,
  ref: any
) => {
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(WRAPPER_CLASSES, classes.component, className)

  const checkRef = useRef<any>(null)
  const resolvedRef = ref || checkRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <div className={merged}>
      <input className={INPUT_CLASSES} type="checkbox" ref={resolvedRef} {...otherProps} />
      <span className={cx(CHECKBOX_PLACEHOLDER_CLASSES, 'checkmark')} aria-checked role="checkbox"></span>
    </div>
  )
}

export const IndeterminateCheckboxWithForwardRef = forwardRef<Ref, IndeterminateCheckboxProps>(IndeterminateCheckbox)

const styles = (theme: any) => ({
  component: css`
    input:checked + .checkmark {
      background-color: #333333;
      border: 1px solid #333333;
    }

    input:indeterminate + .checkmark {
      background-color: #333333;
      border: 1px solid #333333;
    }

    input:checked + .checkmark:after {
      content: '';
      position: absolute;
      height: 6px;
      width: 11px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    input:disabled + .checkmark {
      border: 1px solid grey;
    }

    input:checked:disabled + .checkmark {
      border: 1px solid grey;
      background-color: grey;
    }

    input:indeterminate + .checkmark:after {
      content: '';
      position: absolute;
      height: 0px;
      width: 11px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  `,
})
