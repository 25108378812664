import * as React from 'react'
import { TranslatedText, Input, Label } from 'components/atoms'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'

interface InputFieldProps {
  className?: string
  name: string
  type?: string
  translationBlock: string
  formik: any
  labelKey?: string
  placeholderKey?: string
}

const INPUT_STYLE =
  'px-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ERROR_MSG_STYLE = 'text-xs text-red-500'

export const InputField = (props: InputFieldProps) => {
  const { className, type = 'text', name, translationBlock, formik, labelKey, placeholderKey } = props
  const classes = styles()
  const merged = cx('h-20 relative mt-1', className)
  const { t } = useTranslation([translationBlock])

  return (
    <div className={merged}>
      <label htmlFor={name}>
        <TranslatedText collection={translationBlock} id={labelKey || `${name}Label`} />
      </label>
      <Input
        className={INPUT_STYLE}
        name={name}
        type={type}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        value={formik.values[name]}
        placeholder={t(placeholderKey || `${name}`, '')}
      />
      {formik.errors[name] && formik.touched[name] ? (
        <Label
          className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
          msg={<TranslatedText collection={translationBlock} id={formik.errors[name]} />}
        />
      ) : null}
    </div>
  )
}

const styles = () => ({
  component: css``,
  errorMsg: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
})
