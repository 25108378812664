import React, { useEffect, useState } from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { CommonPageLayout, SideNavigationLayout } from 'components/templates'
import { getUser } from 'store/user'
import { OrderInfo } from 'components/organisms/OrderInfo'
import { SellerOrderProducts } from 'components/organisms/SellerOrderProducts'
import { Order, SellerOrder } from 'models/Order'
import { addNotification } from 'store/notification'
import { addError } from 'store/error'
import { useFirestore } from 'hoc/FirebaseProvider'
import { OrderSummary } from 'components/molecules/OrderSummary/OrderSummary'

interface PageProps {
  className?: string
  children?: React.ReactNode
}

export const OrderDetailsPage = (props: PageProps) => {
  const { className } = props
  const { t } = useTranslation(['orderDetailsPage'])

  const classes = styles()
  const merged = cx(classes.page, className)
  const user = useSelector(getUser)
  const orderKey = get(props, 'match.params.id')

  const db = useFirestore()
  const dispatch = useDispatch()

  const [order, setOrder] = useState<Order>({} as Order)
  const [sellerOrder, setSellerOrder] = useState<SellerOrder>({} as SellerOrder)

  const HEADER_CONTAINER_STYLE = 'flex flex-row justify-top bg-white'
  useEffect(() => {
    async function fetchOrder(orderKey: string) {
      try {
        const docRef = db.collection('orders').doc(orderKey)
        const querySnapshot = await docRef.get()
        const order: Order = querySnapshot.data() as Order
        if (order) {
          setOrder(order)
          if (user.role === 'seller') {
            const sellerOrder = order.sellerOrders?.find(o => o.userId === user.uid)
            if (sellerOrder) {
              setSellerOrder(sellerOrder)
            }
          }
        } else {
          dispatch(addNotification('error', 'orderNotFound'))
        }
      } catch (error) {
        dispatch(addError(error))
      }
    }

    fetchOrder(orderKey)
  }, [db, dispatch, orderKey, user, t])

  const getTotals = (key: string) => {
    return user.role === 'admin' ? order[key] : sellerOrder[key]
  }

  return (
    <div>
      {user.role === 'seller' && user.disabled ? (
        <Redirect to={`/seller/${user.uid}`} />
      ) : (
        <SideNavigationLayout className={merged}>
          <CommonPageLayout
            className={cx('overflow-x-auto', classes.component)}
            pageTitle={`${t('titleDetails', { orderId: order?.orderId || '' })}`}>
            <div className={HEADER_CONTAINER_STYLE}>
              {order && <OrderInfo order={order} sellerOrder={sellerOrder} />}
            </div>
            <div className="h-5" />
            {order?.sellerOrders?.map((sellerOrder, index) => {
              if (user.role === 'admin' || user.uid === sellerOrder.userId) {
                return (
                  <SellerOrderProducts
                    key={index}
                    orderKey={orderKey}
                    sellerOrder={sellerOrder}
                    className={HEADER_CONTAINER_STYLE}
                  />
                )
              }
            })}
            <div className="h-5" />
            {order && (
              <OrderSummary
                price={getTotals('price')}
                deliveryPrice={getTotals('deliveryPrice')}
                priceWithDelivery={getTotals('priceWithDelivery')}
                totalPrice={getTotals('totalPrice')}
              />
            )}
          </CommonPageLayout>
        </SideNavigationLayout>
      )}
    </div>
  )
}

const styles = () => ({
  component: css`
    min-width: 700px;
  `,
  page: css``,
})
