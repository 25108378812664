import React, { useCallback, useState } from 'react'
import { css, cx } from 'emotion'
import { useTheme } from 'emotion-theming'
import { useDispatch, useSelector } from 'react-redux'
import * as _ from 'lodash'

import { SearchInput } from 'components/molecules/SearchInput'
import { setFreeTextSearchField, getFreeTextField } from 'store/filtering'
import { AppState } from 'store/reducers'

interface SearchInputContainerProps {
  name: string
  label: string
  placeholder?: string
  className?: string
  id: string
}

// BASED ON DESIGN  w-40 should come from top component
const SEARCH_INPUT_CLASSES = 'w-40 min-w-0 max-w-screen-md'

export const SearchInputContainer = ({ className, id, name, label, placeholder }: SearchInputContainerProps) => {
  const search = useSelector((state: AppState) => getFreeTextField(state, id)) || ''

  const [value, setValue] = useState(search)
  const dispatcher = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  const updateInput = useCallback(
    _.debounce((searchString: string) => {
      dispatcher(setFreeTextSearchField(id, searchString))
    }, 500),
    []
  )

  // This synthetic event is reused for performance reasons
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // to signal React not to nullify the event object
    event.persist()
    setValue(event.currentTarget.value)
    updateInput(event.currentTarget.value)
  }

  return (
    <div className={merged}>
      <SearchInput
        className={SEARCH_INPUT_CLASSES}
        value={value}
        handleChange={handleChange}
        name={name}
        label={label}
        placeholder={placeholder}
      />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
})
