import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select'
import { InputLabel } from 'components/atoms/InputLabel'
import { TranslatedText } from 'components/atoms/TranslatedText'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface CategoryFilterInputProps {
  className?: string
  data: TreeNode[]
  handleChange: (currentNode: TreeNode, selectedNodes: TreeNode[]) => void
}

const COMPONENT_CLASSES = 'flex flex-col relative'
const LABEL_CLASSES = 'pb-1'
const DROPDOWN_CLASSES =
  'appearance-none rounded-md relative block w-full px-2 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5 box-border'

export const CategoryFilterInput = (props: CategoryFilterInputProps) => {
  const { className, data = [], handleChange } = props
  const { t } = useTranslation(['productsPage'])

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(COMPONENT_CLASSES, classes.component, className)

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES} htmlFor="productSearch">
        <TranslatedText collection="productsPage" id="categoryLabel" />
      </InputLabel>
      <DropdownTreeSelect
        mode="multiSelect"
        data={data}
        onChange={handleChange}
        inlineSearchInput={true}
        texts={{
          placeholder: t('all'),
        }}
        className={cx(DROPDOWN_CLASSES, classes.dropdown)}
      />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  dropdown: css``,
})
