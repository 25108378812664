import {
  SET_FREE_TEXT_SEARCH,
  SET_FILTERING,
  SET_CATEGORY_FILTER,
  SET_STATUS_FILTER,
  SET_BRAND_FILTER,
  SET_DATERANGE_FILTER,
  SET_DATE_FILTER,
} from './constants'
import { Filter } from './reducer'

export interface SetFreeTextSearchAction {
  type: typeof SET_FREE_TEXT_SEARCH
  table: string
  freeText: string
}

export const setFreeTextSearchField = (table: string, freeText: string = ''): SetFreeTextSearchAction => ({
  type: SET_FREE_TEXT_SEARCH,
  table,
  freeText,
})

export interface SetCategoryFilterAction {
  type: typeof SET_CATEGORY_FILTER
  table: string
  categories: string[]
}

export const setCategoryFilterField = (table: string, categories: string[] = []): SetCategoryFilterAction => ({
  type: SET_CATEGORY_FILTER,
  table,
  categories,
})

export interface SetStatusFilterAction {
  type: typeof SET_STATUS_FILTER
  table: string
  statuses: string[]
}

export const setStatusFilterField = (table: string, statuses: string[] = []): SetStatusFilterAction => ({
  type: SET_STATUS_FILTER,
  table,
  statuses,
})
export interface SetBrandFilterAction {
  type: typeof SET_BRAND_FILTER
  table: string
  brands: string[]
}

export const setBrandFilterField = (table: string, brands: string[] = []): SetBrandFilterAction => ({
  type: SET_BRAND_FILTER,
  table,
  brands,
})

export interface SetDateRangeFilterAction {
  type: typeof SET_DATERANGE_FILTER
  table: string
  dateRange: Date[]
}

export const setDateRangeFilterField = (table: string, dateRange: Date[] = []): SetDateRangeFilterAction => ({
  type: SET_DATERANGE_FILTER,
  table,
  dateRange,
})

export interface SetDateFilterAction {
  type: typeof SET_DATE_FILTER
  table: string
  date: Date
}

export const setDateFilterField = (table: string, date: Date): SetDateFilterAction => ({
  type: SET_DATE_FILTER,
  table,
  date,
})

export interface SetFilteringAction {
  type: typeof SET_FILTERING
  table: string
  filter: any
}

export const setFilter = (table: string, filter: Filter = {}): SetFilteringAction => ({
  type: SET_FILTERING,
  table,
  filter,
})

export type FilteringActions =
  | SetFreeTextSearchAction
  | SetFilteringAction
  | SetCategoryFilterAction
  | SetStatusFilterAction
  | SetBrandFilterAction
  | SetDateRangeFilterAction
