import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'

interface DividerProps {
  className?: string
}

const DIVIDER_STYLE = 'block border-b-2 border-gray-300 w-full'

export const Divider = (props: DividerProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(DIVIDER_STYLE, classes.component, className)

  return <div className={merged} />
}

const styles = (theme: any) => ({
  component: css``,
})
