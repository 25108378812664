import React, { ButtonHTMLAttributes } from 'react'
import { cx, css } from 'emotion'
import { useTheme } from 'emotion-theming'

type ColorTypes = 'primary' | 'secondary' | undefined

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  className?: string
  color?: ColorTypes
}

const BUTTON_STYLE =
  'group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md transition duration-150 ease-in-out focus:outline-none'
const PRIMARY_BUTTON_STYLE = 'text-white bg-gray-900'
const SECONDARY_BUTTON_STYLE = 'text-gray-900 bg-white border-gray-900'

export const Button = (props: ButtonProps) => {
  const { children, className, type = 'button', color = 'primary', disabled = false, ...rest } = props
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)
  const BUTTON_TYPE = color === 'primary' ? PRIMARY_BUTTON_STYLE : SECONDARY_BUTTON_STYLE

  return (
    <span className={merged}>
      <button
        type={type}
        disabled={disabled}
        className={cx([{ 'opacity-50 cursor-not-allowed': disabled }], BUTTON_STYLE, BUTTON_TYPE)}
        {...rest}>
        {children}
      </button>
    </span>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
    width: fit-content;
  `,
})
