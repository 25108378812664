import React from 'react'
import { css, cx } from 'emotion'
import { useTranslation } from 'react-i18next'
import { Order, ReturnInfo as Return } from 'models/Order'
import { GeneralInfo } from './GeneralInfo'
import { AddressInfo } from './AddressInfo'
import { ReturnInfo } from './ReturnInfo'
import { Divider } from 'components/atoms/Divider'

interface OrderDetailsProps {
  className?: string
  order: Order
  returnInfo?: Return
}

const TRANSLATION_BLOCK = 'orderDetailsPage'

export const OrderReturnInfo = (props: OrderDetailsProps) => {
  const { className, order, returnInfo } = props
  const { t } = useTranslation([TRANSLATION_BLOCK])
  const classes = styles()
  const merged = cx('flex flex-col w-full m-5', classes.container, className)

  return (
    <div className={merged}>
      <div className="text-2xl font-bold mb-4">{t('returnInfo')}</div>
      <Divider />
      <div className={cx('flex flex-row justify-between  w-full mt-4', className)}>
        <GeneralInfo className="mr-5" paymentType={order.paymentType} deliveryDate={returnInfo?.date?.toDate()} />
        <div />
        {returnInfo && (
          <ReturnInfo
            returnTitle={returnInfo.reason}
            returnDetails={returnInfo.reasonDetails}
            deliveryType={returnInfo.deliveryType}
          />
        )}
        <AddressInfo
          className="mr-5"
          title={t('addressBilling')}
          address={order.billing}
          accountTitle={t('accountNumber')}
        />
        <div />
      </div>
    </div>
  )
}

const styles = () => ({
  container: css``,
})
