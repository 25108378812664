interface Config {
  [key: string]: string | FirebaseConfig
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyCdejxykCW1io85VGmVP6BbKomxZAgwiMc',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'popup-market-dev.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL || 'https://popup-market-dev.firebaseio.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'popup-market-dev',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'popup-market-dev.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '976586078992',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:976586078992:web:4b9b357e20a6a907f54395',
}

type FirebaseConfig = typeof firebaseConfig

export const config: Config = {
  APP_URL: process.env.REACT_APP_APP_URL || 'http://localhost:3000',
  APP_MAIL_URL: process.env.REACT_APP_APP_URL || 'http://localhost:3000',
  API_URL: process.env.REACT_APP_API_URL || 'https://cat-fact.herokuapp.com/',
  BASE_URL: process.env.REACT_APP_BASE_URL || '/',
  FIREBASE_CONFIG: firebaseConfig,
}

export const getEnvName = () => {
  return isProd() ? 'Production' : 'Development'
}

export const isProd = () => {
  const hostUrl = config.APP_URL as string
  return hostUrl.includes('.popup.lt') || hostUrl.includes('-prod.')
}

export const isLocal = () => {
  return window.location.hostname === 'localhost'
}

export const FUNCTIONS_REGION = isLocal() ? 'us-central1' : 'europe-west3'
