import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import moment from 'moment'

interface DateFormatterProps {
  className?: string
  date: string | Date | number
  format?: string
}

export const DateFormatter = (props: DateFormatterProps) => {
  const { className, date, format = 'YYYY-MM-DD' } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  let formattedDate = moment(date).format(format)

  return <span className={merged}>{formattedDate}</span>
}

const styles = (theme: any) => ({
  component: css`
    display: inline-block;
  `,
})
