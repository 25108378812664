import React, { useCallback } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import * as _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { InputLabel, SelectInput, TranslatedText } from 'components/atoms'
import { setStatusFilterField, getStatusField } from 'store/filtering'
import { AppState } from 'store/reducers'

interface FilterProps {
  className?: string
  id: string
}

const CONTAINER_CLASSES = 'w-48'
const LABEL_CLASSES = 'pb-1'

const options = [
  { value: 'all', label: <TranslatedText collection="sellersPage" id="all" /> },
  { value: 'active', label: <TranslatedText collection="sellersPage" id="actives" /> },
  { value: 'passive', label: <TranslatedText collection="sellersPage" id="passives" /> },
  { value: 'unregistered', label: <TranslatedText collection="sellersPage" id="unregistered" /> },
]

export const SellerStatusFilter = ({ id, className }: FilterProps) => {
  const dispatcher = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(CONTAINER_CLASSES, classes.component, className)

  const updateSelect = useCallback(
    _.debounce((data: any) => {
      dispatcher(setStatusFilterField(id, data || []))
    }, 100),
    []
  )
  const handleChange = (selected: { label: string; value: string }) => {
    if (selected) {
      updateSelect([selected.value])
    } else {
      updateSelect([])
    }
  }

  const statusIds: string[] = useSelector((state: AppState) => getStatusField(state, id))
  const statusSelected = statusIds && options.filter(s => statusIds.find(id => id === s.value))

  return (
    <div className={merged}>
      <InputLabel className={LABEL_CLASSES}>
        <TranslatedText collection="sellersPage" id="statusLabel" />
      </InputLabel>
      <SelectInput isMulti={false} options={options} handleChange={handleChange} value={statusSelected} />
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
