import React, { useEffect, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isActionLoading } from 'store/loading'
import firebase from 'firebase'
import ReactTooltip from 'react-tooltip'
import { isEmpty, map, some, sumBy, uniq, uniqBy, min } from 'lodash'

import { AddProductForm } from 'components/organisms/AddProductForm'
import { Button, Divider, LoadingBar, TranslatedText } from 'components/atoms'
import { useFirestore } from 'hoc/FirebaseProvider'
import { RemoveProduct } from 'components/templates/RemoveProduct'
import { ProductAttributesForm } from 'components/organisms/ProductAttributesForm'
import { Category, Product, ProductStatuses } from 'models'
import { getCurrentUser } from 'store/user'
import { DIALOG_CANCEL, setModal } from 'store/modal'
import { useTranslation } from 'react-i18next'
import { UnpublishProduct } from '../UnpublishProduct'
import { IOption } from 'components/util'
import { addNotification } from '../../../store/notification'

interface AddProductFormContainerProps {
  className?: string
  productId: string
  url: string
  product: Product
  categories: Category[]
  selectedCategories: Category[]
  onCategoriesSelect: (value: any) => void
  onSubmit: (value: Product, isDraft: boolean) => void
}

const publishShape = Yup.object().shape({
  name: Yup.string().required('errorRequired'),
  categoryIds: Yup.array()
    .required('errorRequired')
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
  color: Yup.string(),
  price: Yup.number().required('errorRequiredPublish').max(1000000, 'maxPrice').typeError('errorTypo'),
  discount: Yup.number().min(0, 'minDiscount').max(100, 'maxDiscount').typeError('errorTypo'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }),
        totalAmount: Yup.string().required('errorRequiredPublish').typeError('errorTypo'),
        lowStock: Yup.string().required('errorRequiredPublish').typeError('errorTypo'),
      })
    )
    .required('errorRequiredPublish'),
  eco: Yup.boolean(),
})
const TRANSLATION_BLOCK = 'newProductForm'

export const AddProductFormContainer = (props: AddProductFormContainerProps) => {
  const { className, productId, url, product, categories, selectedCategories, onSubmit, onCategoriesSelect } = props

  const theme = useTheme<any>()
  const classes = styles(theme)

  const merged = cx(classes.component, className)

  const [sizes, setSizesList] = useState<any[]>([])
  const [options, setOptions] = useState<any[]>([])
  const [colors, setColors] = useState<IOption[]>([])
  const [country, setCountry] = useState('')
  const history = useHistory()

  const db = useFirestore()
  const brand = useSelector(getCurrentUser)
  const isLoading = useSelector(isActionLoading('SAVE_PRODUCT'))
  const { t } = useTranslation([TRANSLATION_BLOCK])
  const dispatch = useDispatch()
  const authUser = useSelector(getCurrentUser)

  //Fetch country
  useEffect(() => {
    let unsubscribe: any
    function fetchData() {
      const docRef = db.collection('users').doc(authUser.uid)
      unsubscribe = docRef.onSnapshot(
        (querySnapshot: any) => {
          const data = querySnapshot.data()
          if (data) {
            setCountry(data.country.value)
          }
        },
        error => {
          dispatch(addNotification('error', t('fetch_error')))
          console.error('User details are not fetched', error)
        }
      )
    }

    if (authUser && authUser.uid) {
      fetchData()
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [db, authUser.currentUser, dispatch, t, authUser])

  const mapCategoryIds = (cat: Category[]) => {
    const selectedCategoryIds = map(cat, (c: any) => c.value)
    cat.forEach((c: Category) => {
      if (c.parentIds) {
        c.parentIds.forEach(parentId => selectedCategoryIds.push(parentId))
      }
    })
    return uniq(selectedCategoryIds)
  }

  const mapCategoryNames = (cat: Category[]) => {
    const selectedCategoryNames = map(cat, (c: any) => c.label)
    cat.forEach((c: Category) => {
      if (c.parentNames) {
        c.parentNames.forEach(parentName => selectedCategoryNames.push(parentName))
      }
    })
    return uniq(selectedCategoryNames)
  }

  const mapCategoryReturnTime = (cat: Category[]) => {
    const returnTimeInDays = map(cat, c => c.returnTimeInDays)
    return min(returnTimeInDays) || 0
  }

  //FETCH COLORS
  useEffect(() => {
    const fetchColors = async () => {
      const docRef = db.collection('productColors').doc('colors')
      const doc = await docRef.get()
      const colorsArr: IOption[] = []
      if (doc.exists) {
        const list = doc.data()
        if (list) {
          list.colors.forEach((color: string) => {
            colorsArr.push({ label: color, value: color })
          })
          setColors(colorsArr)
        }
      }
    }
    fetchColors()
  }, [db])

  //FETCH CATEGORY SIZES
  useEffect(() => {
    async function fetchCategorySizes() {
      try {
        const docRef = db.collection('productSizes')
        const sizes = await docRef.get()

        const sizesByCategory: any[] = []
        sizes.forEach((doc: any) => {
          const list = doc.data()
          sizesByCategory.push(list)
        })
        setSizesList(sizesByCategory)
      } catch (error) {
        console.error(error)
      }
    }

    fetchCategorySizes()
  }, [db])

  const validateName = (value: string) => {
    let error: string = ''
    if (!value) {
      error = 'errorRequired'
    }
    return error
  }

  useEffect(() => {
    const sizesList = map(
      sizes.filter(size =>
        some(
          selectedCategories,
          sc => some(sc.parentIds, id => size.categories.indexOf(id) > -1) || size.categories.indexOf(sc.value) > -1
        )
      ),
      'values'
    )

    const options: any[] = []
    sizesList.forEach(sizes => {
      sizes.forEach((s: string | number) => {
        options.push({ label: s, value: s })
      })
    })
    setOptions(uniqBy(options, 'value'))
  }, [selectedCategories, sizes])

  const prepareProductData = (product: any, status: ProductStatuses, brand: any) => {
    const item = {
      ...product,
      categoryIds: mapCategoryIds(product.categoryIds),
      categoryNames: mapCategoryNames(product.categoryIds),
      totalAmount: sumBy(product.items, 'totalAmount'),
      status,
      returnTimeInDays: mapCategoryReturnTime(product.categoryIds),
    }

    if (!item.brand) {
      item.brand = brand.brandName
    }

    if (!item.userId) {
      item.userId = brand.uid
    }

    if (!item.created) {
      item.created = firebase.firestore.FieldValue.serverTimestamp()
    }

    return item
  }

  const saveDraft = (values: any) => {
    const item = prepareProductData(values, { published: false, deleted: false }, brand)

    const error = validateName(values.name) || values.categoryIds.length === 0
    if (!error) {
      history.replace({ state: {} })
      onSubmit(item, true)
    }
  }

  const handleCancel = () => {
    dispatch(
      setModal('ConfirmDialog', {
        collection: TRANSLATION_BLOCK,
        action: {
          type: DIALOG_CANCEL,
          redirectUrl: '/product',
          successNote: t('changes_cancelled'),
        },
      })
    )
  }
  const shippingShape = Yup.object()
    .shape(
      {
        shippingLpTerminal: Yup.boolean().when('shippingLpCourier', {
          is: lpTerminal => {
            if (authUser.role === 'seller') return lpTerminal === false
          },
          then: Yup.boolean().oneOf([true], 'errorShipping'),
        }),
        shippingLpCourier: Yup.boolean().when('shippingLpTerminal', {
          is: lpCourier => {
            if (authUser.role === 'seller') return lpCourier === false
          },
          then: Yup.boolean().oneOf([true], 'errorShipping'),
        }),
      },
      [['shippingLpTerminal', 'shippingLpCourier']]
    )
    .required()
  const publishSchema = publishShape.concat(shippingShape)
  return (
    <Formik
      enableReinitialize={true}
      initialValues={product}
      validationSchema={publishSchema}
      validateOnMount={true}
      onSubmit={values => {
        const item = prepareProductData(values, { published: true, deleted: false }, brand)
        onSubmit(item, false)
      }}>
      {(formik: FormikProps<Product>) => {
        return (
          <form className={merged} onSubmit={formik.handleSubmit} noValidate>
            <ReactTooltip className="max-w-sm uppercase" effect="solid" />
            <AddProductForm
              className={classes.formWrapper}
              categories={selectedCategories.length >= 2 ? [] : categories}
              categoriesDisabled={product?.status?.published}
              country={country}
              maxCategoriesMessage={selectedCategories.length >= 2 ? 'max' : ''}
              colors={colors}
              formik={formik}
              setCategories={onCategoriesSelect}
            />
            <ProductAttributesForm formik={formik} options={options} />
            {isLoading ? <LoadingBar className={'bg-gray-900'} /> : <Divider />}
            <div className="mt-6 flex justify-between">
              <div className="flex justify-start">
                <Button color="secondary" className="mr-4" onClick={handleCancel}>
                  <TranslatedText collection={TRANSLATION_BLOCK} id="backBtnText" />
                </Button>
                {!isEmpty(product.status) && (
                  <RemoveProduct id={productId} redirectAfterAction={true}>
                    <Button type="button" color="secondary" disabled={productId !== url}>
                      <TranslatedText collection={TRANSLATION_BLOCK} id="remove" />
                    </Button>
                  </RemoveProduct>
                )}
              </div>
              <div className="flex justify-end">
                {product.status?.published && (
                  <UnpublishProduct id={productId} redirectAfterAction={false} refreshPage={true}>
                    <Button color="secondary" className="ml-4">
                      <TranslatedText collection={TRANSLATION_BLOCK} id="unpublishProduct" />
                    </Button>
                  </UnpublishProduct>
                )}
                {!product.status?.published && (
                  <Button
                    type="button"
                    className="ml-4"
                    disabled={!(formik.values.name && formik.values.categoryIds.length > 0)}
                    onClick={() => saveDraft(formik.values)}>
                    <TranslatedText collection={TRANSLATION_BLOCK} id="save" />
                  </Button>
                )}
                {!product.status?.published && (
                  <Button type="submit" disabled={!isEmpty(formik.errors)} className="ml-4">
                    <TranslatedText collection={TRANSLATION_BLOCK} id="publish" />
                  </Button>
                )}
                {product.status?.published && (
                  <Button type="submit" color="primary" className="ml-4">
                    <TranslatedText collection={TRANSLATION_BLOCK} id="updateProduct" />
                  </Button>
                )}
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
  formWrapper: css`
    @media (min-width: 1280px) {
      padding-left: 32rem;
    }
  `,
  smallInputWrapper: css`
    > div,
    > span {
      flex-basis: 25%;
    }
  `,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
