import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { useDispatch } from 'react-redux'
import { setModal } from 'store/modal'
import { UNPUBLISH_PRODUCT } from 'store/product'

interface UnpublishProductProps {
  className?: string
  children?: React.ReactNode
  id: string | string[] // Product ids
  redirectAfterAction?: boolean
  refreshPage?: boolean
}

export const UnpublishProduct = (props: UnpublishProductProps) => {
  // Keep in mind that if you add and redirect, and page refresh - all actions will be done by UNPUBLISH_PRODUCT epic order
  const { className, children, id, redirectAfterAction = false, refreshPage = false } = props
  const dispatch = useDispatch()

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className)

  return (
    <div
      className={merged}
      onClick={() => {
        dispatch(
          setModal('ConfirmDialog', {
            collection: 'unpublishProduct',
            action: {
              type: UNPUBLISH_PRODUCT,
              productIds: typeof id === 'string' ? [id] : id,
              redirectAfterAction: redirectAfterAction,
              refreshPage: refreshPage,
            },
          })
        )
      }}>
      {children}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})
