import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { Input } from '../../atoms/Input'
import { Icon } from '../../atoms/Icon'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ReactComponent as Email } from '../../../resources/images/icons/email.svg'
import { ReactComponent as Lock } from '../../../resources/images/icons/lock.svg'
import { TranslatedText, Label } from '../../atoms'
import { useTranslation } from 'react-i18next'

interface LoginFormProps {
  className?: string
  handleSubmit: (values: any) => void
}

const initialValues = { email: '', password: '' }
const LoginSchema = Yup.object().shape({
  email: Yup.string().email('errorInvalidEmail').required('errorRequired'),
  password: Yup.string().required('errorRequired'),
})

const INPUT_STYLE =
  'px-6 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5'
const ICON_STYLE = 'absolute top-9 left-2 z-10 text-gray-300'
const BUTTON_CLASSES =
  'outline-none align-baseline font-bold py-2 px-4 rounded text-sm bg-black text-white hover:text-gray-100 border border-black border-solid w-full h-10'
const BUTTON_DISABLED = 'opacity-50 cursor-not-allowed'
const ERROR_MSG_STYLE = 'text-xs text-red-500'
const FORM_STYLE = 'mt-10 px-16'

export const LoginForm = (props: LoginFormProps) => {
  const { className, handleSubmit } = props
  const { t } = useTranslation('login')
  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(FORM_STYLE, classes.component, className)

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values: typeof initialValues) => {
      handleSubmit(values)
    },
  })

  const componentMap: { [key: string]: JSX.Element } = {
    user: (
      <Email
        className={css`
          path {
            fill: currentColor;
          }
        `}
      />
    ),
    warn: (
      <Lock
        className={css`
          path {
            fill: currentColor;
          }
        `}
      />
    ),
  }

  const placeholderMap: { [key: string]: string } = {
    email: t('email'),
    password: t('password'),
  }

  return (
    <form className={merged} onSubmit={formik.handleSubmit} noValidate>
      <div className="h-24 relative">
        <label htmlFor="email">
          <TranslatedText collection="login" id="emailLabel" />
        </label>
        <Input
          name="email"
          type="email"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.email}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['email']}
        />
        <Icon className={ICON_STYLE} svgIcon={componentMap['user']} height={12} width={12} />
        {formik.errors.email && formik.touched.email ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="login" id={formik.errors.email} />}
          />
        ) : null}
      </div>
      <div className="h-24 relative">
        <label htmlFor="password">
          <TranslatedText collection="login" id="passwordLabel" />
        </label>
        <Input
          name="password"
          type="password"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          value={formik.values.password}
          required
          className={INPUT_STYLE}
          placeholder={placeholderMap['password']}
        />
        <Icon className={ICON_STYLE} svgIcon={componentMap['warn']} height={12} width={12} />
        {formik.errors.password && formik.touched.password ? (
          <Label
            className={cx(ERROR_MSG_STYLE, classes.errorMsg)}
            msg={<TranslatedText collection="login" id={formik.errors.password} />}
          />
        ) : null}
      </div>
      <div className="mb-6">
        <button
          type="submit"
          disabled={!(formik.isValid && formik.dirty)}
          className={cx({ [BUTTON_DISABLED]: !(formik.isValid && formik.dirty) }, BUTTON_CLASSES)}>
          <TranslatedText collection="login" id="loginBtn" />
        </button>
      </div>
    </form>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
  errorMsg: css`
    &::before {
      content: '*';
      color: red;
      padding-right: 4px;
    }
  `,
})
