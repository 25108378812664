import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { NavLink } from 'react-router-dom'
import { Icon, TranslatedText } from 'components/atoms'

interface MenuItem {
  icon: JSX.Element
  url: string
  category: string
}

interface NavListProps {
  className?: string
  menuItems: Array<MenuItem>
}

const NAVLIST_STYLE = 'w-full bg-gray-900 h-full text-gray-300 flex flex-col mt-2'
const LINK_WRAPPER_STYLE = 'flex justify-start text-base leading-5 pb-5 ml-16'
const LINK_STYLE = 'flex justify-start items-center mr-4'
const ACTIVE_LINK_STYLE = 'text-white'
const ICON_STYLE = 'mr-4'

export const NavList = (props: NavListProps) => {
  const { className, menuItems } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(NAVLIST_STYLE, classes.component, className)

  return (
    <div className={merged}>
      {menuItems.map((item, index) => {
        return (
          <div key={index} className={LINK_WRAPPER_STYLE}>
            <NavLink className={LINK_STYLE} activeClassName={ACTIVE_LINK_STYLE} exact to={item.url}>
              <Icon className={ICON_STYLE} svgIcon={item.icon} width={18} height={18}></Icon>
              <TranslatedText collection="sidebar" id={item.category} />
            </NavLink>
          </div>
        )
      })}
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  settings: css`
    margin-top: auto;
  `,
})
