import React from 'react'
import { css, cx } from 'emotion'
import { InputField } from 'components/molecules'

interface DetailsFormProps {
  className?: string
  children?: React.ReactNode
  formik: any
}

const TRANSLATION_BLOCK = 'adminDetails'
const FORM_STYLE = 'w-full '

export const AdminDetailsForm = (props: DetailsFormProps) => {
  const { className, formik } = props
  const classes = styles()
  const merged = cx(FORM_STYLE, classes.component, className)

  return (
    <div className={merged}>
      <InputField name="companyName" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      <InputField name="companyCode" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      <InputField name="VATCode" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      <InputField name="bankAccount" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      <InputField name="addressCity" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      <InputField name="addressStreet" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      <div className={cx('flex justify-between h-24', classes.smallInputWrapper)}>
        <InputField name="addressNumber" translationBlock={TRANSLATION_BLOCK} formik={formik} />
        <InputField name="addressZip" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      </div>
      <InputField name="telNumber" translationBlock={TRANSLATION_BLOCK} formik={formik} />
      <InputField name="email" translationBlock={TRANSLATION_BLOCK} formik={formik} />
    </div>
  )
}

const styles = () => ({
  component: css``,
  smallInputWrapper: css`
    > div {
      flex-basis: 40%;
    }
  `,
})
