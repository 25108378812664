import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { withModal } from 'hoc/withModal/withModal'
import { TranslatedText, Button, Label, SelectInput } from 'components/atoms'
import { ModalProps } from 'models'
import { useDispatch } from 'react-redux'
import { deleteModal } from 'store/modal'
import { IOption } from 'components/util'

interface ChangeStatusDialogProps extends ModalProps {
  className?: string
  collection?: string
  options?: IOption[]
  defaultValue?: IOption
}

const TITLE_CLASSES = 'block not-italic font-medium text-xl text-center leading-6 pb-5 pt-10'
const BUTTON_WRAPPER_CLASSES = 'mt-5 p-5 flex justify-center items-center'

export const ChangeStatusDialog = (props: ChangeStatusDialogProps) => {
  const { className, collection = '', options, defaultValue, id, action } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx('pb-10', classes.component, className)
  const [value, setValue] = useState(defaultValue)

  const dispatch = useDispatch()

  const confirmAction = () => {
    if (action) {
      dispatch({ ...action, status: value?.value, modalId: id })
    } else {
      console.warn(`No action provided for this confirm dialog`, props)
    }
  }
  const cancelAction = () => {
    dispatch(deleteModal(id))
  }

  const handleChange = (e: any) => {
    setValue(e)
  }

  return (
    <div className={merged}>
      <TranslatedText className={TITLE_CLASSES} collection={collection} id="confirmTitle" />
      <div className="w-60 my-0 mx-auto">
        <Label msg={<TranslatedText collection={collection} id="confirmLabel" />} />
        <SelectInput isMulti={false} options={options} value={value} handleChange={handleChange} />
      </div>
      <div className={BUTTON_WRAPPER_CLASSES}>
        <Button className="mr-2" onClick={confirmAction} disabled={value === defaultValue}>
          <TranslatedText collection={collection} id="okText" />
        </Button>
        <Button color="secondary" onClick={cancelAction}>
          <TranslatedText collection={collection} id="cancelText" />
        </Button>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
})

export const ChangeStatusDialogModal = withModal(ChangeStatusDialog)
