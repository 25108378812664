import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { AddNewAccForm } from 'components/organisms'
import { useFunctions } from 'hoc/FirebaseProvider'
import { setLoading, isActionLoading } from 'store/loading'
import { useDispatch, useSelector } from 'react-redux'
import { Notification, TranslatedText, LoadingBar, Divider } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '../CardLayout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { withModal } from 'hoc/withModal/withModal'
import { FUNCTIONS_REGION } from 'config'

interface NewUserAccLayoutProps {
  className?: string
  children?: React.ReactNode
}

const initialValues = {
  agreementNumber: '',
  companyName: '',
  VATCode: '',
  marginPercentage: '',
  country: [],
  companyCode: '',
  bankAccountNumber: '',
  email: '',
}

const AddNewAccSchema = Yup.object().shape({
  agreementNumber: Yup.string().required('errorRequired'),
  companyName: Yup.string().required('errorRequired'),
  VATCode: Yup.string(),
  country: Yup.object().required('errorRequired'),
  marginPercentage: Yup.string().required('errorRequired'),
  companyCode: Yup.string().required('errorRequired'),
  bankAccountNumber: Yup.string().required('errorRequired'),
  email: Yup.string().email('errorInvalidEmail').required('errorRequired'),
})

const TITLE_CLASSES = 'block mt-10 not-italic font-medium text-xl text-center leading-6 pb-5'
const TRANSLATED_TEXT_STYLE = 'mb-5 px-16 text-center flex items-center justify-center text-gray-900 text-sm'
const BUTTON_STYLE =
  'group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-900 transition duration-150 ease-in-out'

export const NewUserAccLayout = (props: NewUserAccLayoutProps) => {
  const { className } = props

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx(classes.component, className, 'pb-1')

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const formLoading = useSelector(isActionLoading('NEW_USER_FORM_REQUEST'))
  const dispatch = useDispatch()
  const functions = useFunctions(FUNCTIONS_REGION)

  const { t } = useTranslation(['newUserForm'])
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: AddNewAccSchema,
    onSubmit: (values: typeof initialValues) => {
      onSubmit(values)
    },
  })

  const onSubmit = (values: any) => {
    setSuccess('')
    setError('')
    dispatch(setLoading('NEW_USER_FORM_REQUEST', true))
    functions
      .httpsCallable('createSeller')(values)
      .then(response => {
        dispatch(setLoading('NEW_USER_FORM_REQUEST', false))
        if (response.data.status === 200) {
          setSuccess('success')
          formik.resetForm()
        } else {
          const error = response.data.error
          setError(error)
        }
      })
      .catch(error => {
        dispatch(setLoading('NEW_USER_FORM_REQUEST', false))
      })
  }

  return (
    <CardLayout className={merged}>
      <h2 className={cx(TITLE_CLASSES, classes.title)}>
        <TranslatedText collection="newUserForm" id="title" />
      </h2>
      <TranslatedText className={TRANSLATED_TEXT_STYLE} collection="newUserForm" id="subtitle" />
      {formLoading && <LoadingBar className={'bg-gray-900'} />}
      {!formLoading && success && <Notification className={'w-full'} type={'success'} text={t(success)} />}
      {!formLoading && error && <Notification className={'w-full'} type={'error'} text={t(error)} />}
      {!formLoading && !success && !error && <Divider />}
      <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
        <AddNewAccForm formik={formik}></AddNewAccForm>
        <div className="mb-6">
          <button type="submit" className={BUTTON_STYLE}>
            <TranslatedText collection="newUserForm" id="invite" />
          </button>
        </div>
      </form>
    </CardLayout>
  )
}

const styles = (theme: any) => ({
  component: css`
    display: block;
  `,
  form: css`
    padding: 0 60px;
  `,
  title: css`
    font-family: 'SourceSansPro', sans-serif;
  `,
})

export const NewUserAccLayoutModal = withModal(NewUserAccLayout)
