import React from 'react'
import { useTheme } from 'emotion-theming'
import { css, cx } from 'emotion'
import { TranslatedText, Icon } from 'components/atoms'
import Dropzone, { FileRejection } from 'react-dropzone'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as Delete } from '../../../resources/images/icons/close.svg'
import { ReactComponent as Info } from '../../../resources/images/icons/info.svg'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addNotification } from 'store/notification'
import { FileErrResponse } from 'components/util'

interface UserProfileImageProps {
  className?: string
  children?: React.ReactNode
  photoUrl?: string
  onDrop?: (accepted: []) => void
  onDelete?: () => void
  photoName?: string
}

const PROFILE_PHOTO_CONTAINER_CLASSES = 'border border-gray-900 border-dashed focus:outline-none'
const PROFILE_PHOTO_CLASSES = 'w-72 h-48 rounded flex justify-center items-center focus:outline-none object-cover'
const DELETE_ICON_STYLE =
  'hidden bg-gray-800 text-white justify-center items-center h-6 w-6 rounded absolute top-0 right-0 -mr-1 mt-2 z-50 cursor-pointer'

const maxSize = 3145728

export const UserProfileImage = (props: UserProfileImageProps) => {
  const { className, photoUrl, onDrop, onDelete, photoName } = props
  const { t } = useTranslation('imageUpload')

  const theme = useTheme<any>()
  const classes = styles(theme)
  const merged = cx('flex justify-center', classes.component, className)

  const dispatch = useDispatch()

  function dropRejected(reject: FileRejection[]) {
    reject.forEach(r => {
      r.errors.forEach((err: FileErrResponse) => {
        const msg = (
          <div>
            <div>{r.file.name}</div>
            <div>
              <TranslatedText
                collection="imageUpload"
                id={err.code}
                properties={{ maxSize: `${maxSize / 1024 / 1024} MB` }}
              />
            </div>
          </div>
        )
        dispatch(addNotification('error', msg))
      })
    })
  }

  return (
    <div>
      <ReactTooltip className="max-w-sm uppercase" effect="solid" />
      <div data-tip={t(`${photoName}Tooltip`)} className={merged}>
        <TranslatedText collection="imageUpload" id={photoName} />
        <Icon svgIcon={<Info />} width={16} height={16} className={classes.icon} />
      </div>
      <div className={merged}>
        <div className={PROFILE_PHOTO_CLASSES}>
          {!photoUrl && (
            <Dropzone
              multiple={false}
              accept={'image/jpeg, image/png'}
              maxSize={maxSize}
              onDrop={onDrop}
              onDropRejected={dropRejected}>
              {({ getRootProps, getInputProps }) => (
                <div className={cx(PROFILE_PHOTO_CLASSES, PROFILE_PHOTO_CONTAINER_CLASSES)}>
                  <input {...getInputProps()} />
                  <div
                    {...getRootProps({
                      className: cx(PROFILE_PHOTO_CLASSES, 'dropzone w-full h-full flex-col cursor-pointer'),
                    })}>
                    <TranslatedText className="text-lg font-bold" collection="profile" id="photoPlaceholderMain" />
                    <TranslatedText className="px-8" collection="profile" id="photoPlaceholderSub" />
                  </div>
                </div>
              )}
            </Dropzone>
          )}
          {photoUrl && (
            <div className={cx(classes.imageWrapper, 'relative w-full h-full block')}>
              <picture className={cx(classes.image, 'block rounded w-full relative overflow-hidden')}>
                <img className={PROFILE_PHOTO_CLASSES} src={photoUrl} alt="User" />
              </picture>
              <div className={DELETE_ICON_STYLE} onClick={onDelete}>
                <Icon
                  svgIcon={
                    <Delete
                      className={css`
                        path {
                          fill: currentColor;
                        }
                      `}
                    />
                  }
                  width={12}
                  height={12}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const styles = (theme: any) => ({
  component: css``,
  image: css`
    height: 200px;
  `,
  imageWrapper: css`
    &:hover {
      .hidden {
        display: flex;
      }
    }
  `,
  icon: css`
    path {
      fill: currentColor;
    }
  `,
})
