import { combineEpics, Epic, ofType } from 'redux-observable'
import { catchError, switchMap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { deleteModal } from 'store/modal'
import { go, push } from 'connected-react-router'
import { DIALOG_CANCEL } from './constants'
import { addNotification } from '../notification'
import { AnyAction } from 'redux'

const cancelChanges: Epic<any, any> = (action$: Observable<AnyAction>): Observable<any> =>
  action$.pipe(
    ofType(DIALOG_CANCEL),
    switchMap((props: any) => {
      const actions = []
      actions.push(deleteModal(props.modalId))

      if (props.redirectUrl) {
        actions.push(push(props.redirectUrl))
      }

      if (props.reloadAfterAction) {
        actions.push(go(0))
      }

      if (props.formik) {
        props.formik.resetForm()
      }

      if (props.successNote) {
        actions.push(addNotification('success', props.successNote))
      }

      return actions
    }),
    catchError(error => {
      return of({ type: 'ERROR', error: error })
    })
  )

export const modalEpics = combineEpics(cancelChanges)
