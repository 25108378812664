import React from 'react'
import { useTheme } from 'emotion-theming'
import { css } from 'emotion'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { SearchInputContainer } from '../SearchInputContainer'
import { BrandFilterContainer } from '../BrandFilterContainer'
import { StatusFilterInputContainer } from '../StatusFilterInputContainer'
import { DateRangePickerFilterContainer } from '../DateRangePickerFilterContainer'
import { CategoryFilterInputContainer } from '../CategoryFilterInputContainer'
import { getCurrentUser } from 'store/user'

interface ProductsFilterContainerProps {
  id: string
  filterUserId?: string
  sellerFilter?: boolean
}

export const ProductsFilterContainer = ({ id, filterUserId, sellerFilter = true }: ProductsFilterContainerProps) => {
  const { t } = useTranslation(['productsPage'])
  const theme = useTheme<any>()
  const classes = styles(theme)

  const user = useSelector(getCurrentUser)

  return (
    <>
      <SearchInputContainer
        className={classes.filter}
        id={id}
        name={'productSearch'}
        label={t('searchLabel')}
        placeholder={t('searchPlaceholder')}
      />
      {user.role === 'admin' && sellerFilter && (
        <BrandFilterContainer className={classes.filter} id={id} userIdSelected={filterUserId} />
      )}
      <CategoryFilterInputContainer className={classes.filter} id={id} />
      <StatusFilterInputContainer className={classes.filter} id={id} />
      <DateRangePickerFilterContainer className={classes.filter} id={id} />
    </>
  )
}

const styles = (theme: any) => ({
  filter: css`
    &:not(:last-child) {
      margin-right: 24px;
    }
  `,
})
